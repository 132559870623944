import { MetricName } from '@givematch/common'

import referralsPrize from '../assets/leaderboard/matched-prize.svg'
import donorsPrize from '../assets/leaderboard/donors-prize.svg'
import raisedPrize from '../assets/leaderboard/raised-prize.svg'

const matchedPrize = referralsPrize

export const getPrizeImg = (metric: MetricName): string => {
  switch (metric) {
    case MetricName.countReferral:
      return referralsPrize
    case MetricName.countMatched:
      return matchedPrize
    case MetricName.countDonor:
      return donorsPrize
    case MetricName.totalRaised:
      return raisedPrize
  }
}
