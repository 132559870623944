import type { FC } from 'react'

import { ContainerLayout } from '~/components/ContainerLayout'

const LineSpacer: FC = () => {
  return (
    <ContainerLayout className="px-40 py-20">
      <hr className="h-px text-gm-silver-50" />
    </ContainerLayout>
  )
}

export default LineSpacer
