import { callService } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'
export interface CreateAccountFormData {
  first_name: string
  last_name: string
  email: string
  token: string
  fundraiser_tips_opt_in: boolean
}

export interface CreateAccountResponse {
  status: boolean | string
  message: string
}

export const createAccount = async (createAccountFormData: CreateAccountFormData): Promise<CreateAccountResponse> => {
  return await callService('/api/donor/signup_login', HttpRequestMethod.POST, createAccountFormData)
}
