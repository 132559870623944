import { type FC } from 'react'
import { type Cause } from '../state/charity'
import { type GivematchCauseCategory } from '@lib/services'
import '../css/cause.css'
import { GivematchTestId, isCause } from '@givematch/common'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { useDonationPlan } from '~/hooks/useDonationPlan'

interface causeCardProps {
  id: string
  category: GivematchCauseCategory
  name: string
  description: string
  matchPotID: string
}

const CauseCard: FC<causeCardProps> = ({ id, name, category, description }) => {
  // const { donation, setDonation } = useDonationPlan()
  const navigate = useNavigate()
  const fallbackImage = '/assets/causes/charity.jpg'
  const onCauseSelected = (): void => {
    // setDonation({ ...donation, causeID: id })
    navigate(Navigation.explainer())
  }
  return <div
    data-test-id={`${GivematchTestId.causeCard_}${id}`}
    key={id} className="justify-center my-8 w-100 overflow-x-hidden card rounded-xl sm:mx-4 md:mx-8 cursor-pointer max-w-xs flex flex-col shadow hover:shadow-2xl"
    onClick={onCauseSelected}
    >
    <img className="h-60 w-full object-cover" onError={(e) => (e.currentTarget.srcset = fallbackImage)} src={`/assets/causes/${category.toLocaleLowerCase()}.jpg`} alt={name.toLocaleLowerCase() + ' donation'}/>
    <div className="cardBody p-4">
      <h3 className="font-semibold hover:cursor-move text-black">{name}</h3>
      <p className="flex-1 text-bluegray-500 text-xs">{description}</p>
    </div>
  </div>
}

const CauseCards: FC = () => {
  const { donation } = useDonationPlan()
  const items = donation.charity.causes.filter(cause => isCause(cause.causeType)).map((cause: Cause) =>
    <CauseCard
      key={cause.causeID}
      id={cause.causeID}
      name={cause.name}
      description={cause.description}
      matchPotID={cause.matchPotID}
      category={cause.category}
    />
  )
  return <div className="flex flex-wrap justify-center mx-auto max-w-7xl">
    {items}
  </div>
}

export default CauseCards
