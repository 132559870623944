export enum CauseType {
  CAUSE = 'CAUSE',
  EVENT = 'EVENT',
  FUNDRAISER_CHARITY = 'FUNDRAISER_CHARITY',
  CHARITY = 'CHARITY'
}

export const isCause = (causeType: string): boolean => (causeType === '') || (causeType === CauseType.CAUSE)

export const isEvent = (causeType: string): boolean => causeType === CauseType.EVENT

export const isFundraiserCharity = (causeType: string): boolean => causeType === CauseType.FUNDRAISER_CHARITY
