import { Button, InputWithRef, Variant } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type FC, useState } from 'react'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { displayTidyCurrency, getCurrencySymbol, penniesToPound } from '@lib/services'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { GivematchFundraiserTestIds } from '@lib/testing'
import rightArrow from '~/assets/right-arrow-green.svg'
import '~/css/fundraiser.css'
import { Tracking } from '~/service/tracking'
import { getNextStep } from '~/utils/fundraiserSteps'

export const EnterTarget: FC = () => {
  const { fundraiser, setFundraiser } = useFundraiserPlan()
  const [targetAmt, setTargetAmt] = useState(fundraiser.target_amount)
  const navigate = useNavigate()
  const proceedToNextPage = (): void => {
    setFundraiser({ ...fundraiser, target_amount: targetAmt })
    Tracking.fundraisingGoal(fundraiser, targetAmt)
    navigate(getNextStep(Navigation.fundraiserEnterTarget()))
  }

  const onValueChange = (value?: string): void => {
    const targetAmount = parseFloat(value ?? '0') * 100
    setTargetAmt(targetAmount)
  }

  const TargetMatchBox: FC = () => {
    return <div className={`flex flex-col border-2 border-gm-green rounded-xl mt-10 px-2 py-2 shadow-xl ${!targetAmt ? 'hidden' : ''}`}>
      <div className='flex justify-center gap-x-4 flex-wrap'>
        <span className='text-3xl font-bold text-gm-green'>{displayTidyCurrency(targetAmt, fundraiser.charity.currency)}</span>
        <img className='w-9' src={rightArrow} alt='right arrow'/>
        <span className='text-3xl font-bold black-border-text'>{displayTidyCurrency(targetAmt * 2, fundraiser.charity.currency)}</span>
      </div>
      <div className='mt-4 font-semibold'>
        <p>When you’re all done, don’t forget to share your fundraiser!</p>
        <p className='mt-4'>Try and get as many people as you can to donate and share your fundraiser, so you can raise as much as you can and make as much impact as possible!</p>
      </div>
    </div>
  }

  return <FundraiserLayout title="Your starting fundraising goal">
    <div className='relative'>
      <span className='absolute text-5xl font-bold text-gm-green top-3 left-3'>{getCurrencySymbol(fundraiser.charity.currency)}</span>
      <InputWithRef
        id="target_amount"
        className={`${getCurrencySymbol(fundraiser.charity.currency).length === 1 ? 'px-14' : 'px-32'} text-4xl font-bold text-gm-green w-full`}
        data-test-id={GivematchFundraiserTestIds.targetAmount}
        type="number"
        variant={Variant.gm}
        size={1}
        defaultValue={penniesToPound(targetAmt) ?? ''}
        step={0.01}
        onChange={(event) => onValueChange(event.target.value)}
        placeholder="Starting Goal" />
      <span className='absolute text-lg font-semibold text-gm-green top-5 right-3'>{fundraiser.charity.currency.toUpperCase()}</span>
    </div>

    <span className="mt-2 text-md">You can always change your goal as you go.</span>

    <Button
      data-test-id={GivematchFundraiserTestIds.continueButton}
      className="w-full mt-5 shadow-2xl"
      variant="gm-primary"
      onClick={() => proceedToNextPage()}>
      <span>Continue</span>
    </Button>

    <TargetMatchBox />

    <div className="mt-10 px-5 py-4 text-sm bg-bluegray-100">
      <p className="mb-4">Donations will be granted to your chosen charity so you don’t have to worry about withdrawing the money.</p>
      <p>Transaction fees are deducted from each donation.</p>
    </div>
  </FundraiserLayout>
}
