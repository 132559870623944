import { callService, type Currency, GivematchCauseCategory } from '@lib/services'
import { activeCampaign } from '@givematch/common'
import { withAuth } from '@lib/hooks'
import { HttpRequestMethod } from 'http-core-constants'
import { getStripeToken } from './payment'
import { loadStripe, type Stripe } from '@stripe/stripe-js'
import type Fundraiser from '~/state/fundraiser'
import { type EventBase } from '~/state/fundraiser'

export interface Cause {
  causeID: string
  name: string
  description: string
  matchPotID: string
  category: GivematchCauseCategory
  causeType: string
}

export interface CharityList {
  charityID: string
  name: string
  currency: Currency
  country: string
  causes: Record<string, Cause>
  weight: number
  charityApi: string
  coverImage: string
  logoImage: string
  status: string
}

interface APICause {
  cause_id: string
  active: boolean
  title: string
  description: string
  match_pot_id: string
  category: keyof typeof GivematchCauseCategory
  cause_type: string
  target_amount: number
}

export interface EventDonation {
  created_time: Date
  amount_paid: number
  first_name: string
  last_name: string
  currency: string
  anonymous: boolean
  share_code: string
  tip: number
  total_fee: number
  cause_donations: Record<string, number>
  gift_aid: boolean
}
export interface CharityEvent extends EventBase {
  event_id: string
  match_pot_id: string
  category: keyof typeof GivematchCauseCategory
  cause_type: string
}

interface APICharityList {
  charity_id: string
  name: string
  currency: Currency
  campaign: string
  country: string
  causes: Record<string, APICause>
  weight: number
  charity_api: string
  cover_image: string
  logo_image: string
  status: string
}
export interface LandingPageTO {
  landing_page_id: string
  charity_id: string
  alias: string
  key: string
  logo_url: string
  banner_url: string
  title: string
  description: string
  fundraisers: Fundraiser[]
  active: boolean
  created_time: string
}
export interface LandingPageGetResponse {
  landing_pages: LandingPageTO[]
}
const convertCauses = (causes: Record<string, APICause>): Record<string, Cause> => {
  return Object.entries(causes).reduce<Record<string, Cause>>((acc, [k, v]) => {
    acc[k] = {
      causeID: v.cause_id,
      name: v.title,
      description: v.description,
      matchPotID: v.match_pot_id,
      category: GivematchCauseCategory[v.category],
      causeType: v.cause_type
    }
    return acc
  }, {})
}

const getCharities = async (country: string, onlyWithWeight: boolean, searchTerm: string): Promise<CharityList[]> => {
  const res = await fetch(`/api/campaigns/${activeCampaign}/charities?${new URLSearchParams({
    country,
    only_with_weight: onlyWithWeight.toString(),
    search_term: searchTerm
}).toString()}`)

  if (res.status !== 200) {
    throw new Error('Error listing charities')
  }

  const charities: APICharityList[] = await res.json() ?? []

  return charities.map(c => {
    return {
      charityID: c.charity_id,
      name: c.name,
      currency: c.currency,
      country: c.country,
      causes: convertCauses(c.causes),
      weight: c.weight,
      charityApi: c.charity_api,
      coverImage: c.cover_image,
      logoImage: c.logo_image,
      status: c.status
    }
  })
}

export interface Charity {
  charity: string
  charity_api: string
  status: string
  campaign: string
  country: string
  name: string
  about: string
  causes: Cause[]
  gift_aid: boolean
  tax_receipt: boolean
  currency: Currency
  logo_image: string
  fundraisers?: Fundraiser[]
}

const getCharity = async (charityID: string): Promise<Charity> => {
  const res = await fetch(`/api/campaigns/${activeCampaign}/charity/${charityID.toLowerCase()}`)

  if (res.status !== 200) {
    throw new Error('Error getting charity')
  }

  const charity = await res.json()

  return {
    ...charity,
    causes: getCauses(charity.causes)
  }
}
const getCharityLandingPages = async (charityID: string): Promise<LandingPageGetResponse> => {
  const res = await fetch(`/api/landing-pages/charity/${charityID.toLowerCase()}`)

  if (res.status !== 200) {
    throw new Error('Error getting charity')
  }

  const landingPages = await res.json()

  return {
    ...landingPages
  }
}
const getStripe = async (charityId: string): Promise<Stripe | null> => {
  const token = await getStripeToken()
  const stripeAccount = await getCharityStripeAccount(charityId)
  return await loadStripe(token, {
    stripeAccount
  })
}

const getCharityStripeAccount = withAuth(async (charityId: string): Promise<string> => {
  const account: { account_id: string } = await callService(`/api/charity/${charityId}/stripe`, HttpRequestMethod.GET)
  return account.account_id
})

// Gets the causes from a charity object
const getCauses = (causes: Record<string, APICause>): Cause[] => {
  return Object.values(causes).map((v) => {
    return convertCauseFromApiCall(v)
  })
}

const convertCauseFromApiCall = (apiCause: APICause): Cause => {
  return {
    causeID: apiCause.cause_id,
    name: apiCause.title,
    description: apiCause.description,
    category: GivematchCauseCategory[apiCause.category],
    matchPotID: apiCause.match_pot_id,
    causeType: apiCause.cause_type
  }
}

const getCauseName = (causes: Cause[], causeID: string): string => {
  const c = causes.find((c) => c.causeID === causeID)
  return c?.name ?? ''
}

export {
  getCharities,
  getCharity,
  getCauseName,
  getCharityStripeAccount,
  getStripe, getCharityLandingPages
}
