import type { FC } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  type LeaderboardDetails,
  MetricName,
  rankSuffix,
  formatNumber,
  LOCALE_EN_GB
} from '@givematch/common'

import { ContainerLayout } from '~/components/ContainerLayout'
import { type Currency } from '@lib/services'

interface DailyPrizesSectionProps {
  imgSrc: string
  leaderboard: LeaderboardDetails
}

const DailyPrizesSection: FC<DailyPrizesSectionProps> = ({ imgSrc, leaderboard }) => {
  // https://tailwindcss.com/docs/responsive-design
  const isSM = useMediaQuery({ query: '(min-width: 640px)' })
  const isMD = useMediaQuery({ query: '(min-width: 768px)' })
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })
  // const isXL = useMediaQuery({ query: '(min-width: 1280px)' })
  // const is2XL = useMediaQuery({ query: '(min-width: 1536px)' })

  const { prizes } = leaderboard.info
  const topPrizes = prizes.filter(p => p.metric === MetricName.totalRaised && [1, 2, 3].includes(p.rank))
  const findPrize = prizes.filter(p => p.metric === MetricName.totalRaised && p.rank === 4)
  const fourthPrize = findPrize.length > 0 ? findPrize[0] : undefined

  return (
    <ContainerLayout className="lg:px-40 gap-6 lg:gap-4 flex flex-col xl:flex-row items-center">

      {/* Picture */}
      <div
        style={{
          width: isSM ? '28.8125rem' : '100%',
          height: isSM ? '26rem' : '100%'
        }}
      >
        <img src={imgSrc} loading="lazy" alt="Guy With Confetti"/>
      </div>

      {/* Prizes */}
      <div
        className="flex flex-col gap-6 md:gap-12"
        style={{ width: isSM ? (isLG ? '100%' : '43rem') : '100%' }}
      >

        {/* Title */}
        <h3
          className="text-center text-gm-newgreen font-bold"
          style={{
            fontSize: isMD ? '1.875rem' : '1.5rem',
            lineHeight: '110%',
            letterSpacing: isMD ? '-0.0375rem' : '-0.03rem'
          }}
        >
          Daily Prizes
        </h3>

        {/* Prizes List */}
        <div
          className="flex flex-row justify-around lg:justify-between mx-auto text-gm-black-100"
          style={{ width: isSM ? '43rem' : '100%' }}
        >
          {topPrizes.map((prize, pId) => (
            <div
              key={`${pId + 1}-${prize.metric}`}
              className="flex flex-col gap-2 justify-center text-center rounded-full bg-gm-gray-10"
              style={{
                width: isMD ? '13.625rem' : '6.9375rem',
                height: isMD ? '13.625rem' : '6.9375rem'
              }}
            >
              {/* Rank */}
              <div
                className="font-bold"
                style={{
                  fontSize: isMD ? '2.5rem' : '1rem',
                  lineHeight: isMD ? '110%' : '1rem',
                  letterSpacing: isMD ? '-0.05rem' : undefined
                }}
              >
                {prize.rank}{rankSuffix(prize.rank)}
              </div>

              {/* Description */}
              <div className="font-normal " style={{ fontSize: '1rem', lineHeight: isMD ? '140%' : '1rem' }}>place</div>

              {/* Amount & Unit */}
              <div
                className="font-bold"
                style={{
                  fontSize: isMD ? '1.5rem' : '1.25rem',
                  lineHeight: isMD ? '158%' : '1.5rem'
                }}
              >
                {formatNumber(LOCALE_EN_GB, prize.amount, 0, true, prize.unit as Currency)}
              </div>
            </div>
          ))}
        </div>

        {/* Disclaimer */}
        {fourthPrize && <h3
          className="px-16 text-center text-gm-black-100"
          style={{
            fontSize: '1rem',
            lineHeight: isMD ? '140%' : '150%'
          }}
        >
          Plus, <strong>4th to 10th place </strong>
          charities each win <strong>{
          formatNumber(LOCALE_EN_GB, fourthPrize.amount, 0, true, fourthPrize.unit as Currency)
        }!</strong>
        </h3>
        }
      </div>

    </ContainerLayout>
  )
}

export default DailyPrizesSection
