import { getStripe } from '~/state/charity'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { type FC, useMemo } from 'react'
import { PaymentRequestProvider } from '~/hooks/usePaymentRequest'
import { Elements } from '@stripe/react-stripe-js'
import { type StripeElementsOptions } from '@stripe/stripe-js'
import { calculateCauseTotal, isCause } from '@givematch/common'

export const PaymentContainer: FC = ({ children }) => {
  const { donation } = useDonationPlan()
  const options: StripeElementsOptions = {
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap' }],
    mode: 'payment',
    amount: isCause(donation.causeType) ? calculateCauseTotal(donation.causeAmounts) : donation.amount,
    currency: donation.donationCurrency,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#02A47B',
        colorPrimaryText: '#02A47B',
        colorText: '#000000'

      },
      rules: {
        '.Input': {
          borderColor: '#02A47B'
        }
      }
    }
  }
  const stripePromise = useMemo(async () => getStripe(donation.charity.charity), [donation.charity.charity])
  return <Elements options={options} stripe={stripePromise}>
    <PaymentRequestProvider label="Givematch total amount">
      {children}
    </PaymentRequestProvider>
  </Elements>
}
