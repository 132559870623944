export class SessionStorage {
  static readonly backLink: string = 'back_link'
  static readonly fundraiser_id_published: string = 'fundraiser_id_published'
  static readonly fundraiser_draft_id_created: string = 'fundraiser_draft_id_created'
  static readonly fundraiser_draft_md5: string = 'fundraiser_draft_md5'
  static readonly eventId: string = 'event_id'
  static readonly referrerCharityKey: string = 'referrer_charity'
  static readonly partnerCharityKey: string = 'partner_charity'
  static readonly campaignName: string = 'campaign_name'
  static readonly currentFundraiser: string = 'current_fundraiser'
  static readonly fundraiserData: string = 'fundraiser_data'
  static readonly editFundraiserData: string = 'edit_fundraiser_data'
  static readonly donationData: string = 'donation_data'
  static readonly draftCoverPhotoMD5: string = 'draft_cover_photo_md5'
}
