import { type FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Spinner } from '@lib/components'

import { getCharity } from '~/state/charity'
import Footer from '~/components/Footer'
import Header from '~/components/Header'
import '~/index'
import DonationHero from '~/components/DonationHero'
import StepsExplainer from '~/components/StepsExplainer'
import { Navigation } from '~/service/navigation'
import { SessionStorage } from '~/service/sessionStorage'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { LandingPageType } from '@givematch/common'
import { isNonApprovedAPICharity } from '~/utils/charityAPI'

const CharityLanding: FC = () => {
  const { setCharity } = useDonationPlan()
  const { charityID } = useParams() as { charityID: string }

  const { isLoading, isError, data: charity } = useQuery(['charity', charityID], async () => getCharity(charityID), {
    onSuccess: (charity) => {
      setCharity(charity)
    }
  })

  useEffect(() => {
    sessionStorage.setItem(SessionStorage.backLink, Navigation.charityHome(charityID))
  })

  const acceptingDonations = charity?.charity !== undefined && (charity?.causes ?? []).length > 0

  return <div className="flex flex-col justify-around sm:text-center items-center">
        <Header />
        {isLoading && <Spinner className="m-auto" />}
        {isError && <p className="px-4 mt-4 text-gm-red"> We were unable to find this charity </p>}
        {!isLoading && !isError && !acceptingDonations && <p className="mt-4 text-gm-red"> Sorry, but we&apos;re no longer accepting donations to this charity. </p>}
        {!isLoading && !isError && acceptingDonations && (
          <DonationHero
            charityName={charity.name}
            charityId={charity.charity}
            charityCurrency={charity.currency}
            logoImage={charity.logo_image}
            landingPage={LandingPageType.Charity}
            isNotApprovedCharityAPI={isNonApprovedAPICharity(charity)}
          />
        )}
        <StepsExplainer />
    <Footer />
    </div>
}

export default CharityLanding
