import { Button, CountrySelection, InputWithRef, Variant } from '@lib/components'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { type Country, localeCountries } from '@lib/services'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type FC, useState } from 'react'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { Tracking } from '~/service/tracking'
import { getNextStep } from '~/utils/fundraiserSteps'

export const EnterLocation: FC = () => {
  const { fundraiser, setFundraiser } = useFundraiserPlan()
  const navigate = useNavigate()
  const [country, setCountry] = useState(fundraiser.country)
  const [postcode, setPostcode] = useState(fundraiser.postcode)

  const invalidEntries = (): boolean => postcode === ''

  const onContinue = (): void => {
    setFundraiser({ ...fundraiser, country, postcode })
    Tracking.locationSelection(country, postcode)
    navigate(getNextStep(Navigation.fundraiserEnterLocation()))
  }

  return <FundraiserLayout title="Let’s start fundraising!">
    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Your Location</span>

    <CountrySelection
      className="mt-5 flex-1 block w-full p-4 bg-white border rounded border-gm-green"
      countries={Object.values(localeCountries)}
      value={country}
      onCountryChange={(v: Country) => {
        setCountry(v)
      }}
      variant="gm"
    />

    <InputWithRef
      id="postcode"
      className="mt-2"
      data-test-id={GivematchFundraiserTestIds.postCode}
      autoComplete="postal-code"
      type="text"
      variant={Variant.gm}
      size={1}
      defaultValue={postcode}
      onChange={(event) => setPostcode(event.target.value)}
      placeholder="Postcode" />

    <Button
      className="w-full mt-10 shadow-2xl"
      data-test-id={GivematchFundraiserTestIds.continueButton}
      variant="gm-primary"
      onClick={onContinue}
      disabled={invalidEntries()}>
      <span className="">Continue</span>
    </Button>
  </FundraiserLayout>
}
