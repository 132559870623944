import { type FC, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { type LeaderboardDetails, getEndAtDate } from '@givematch/common'

import girlPicDesktop from '../assets/leaderboard/girl-desktop.png'
import girlPicMobile from '../assets/leaderboard/girl-mobile.png'
import guyWithConfettiPicDesktop from '../assets/leaderboard/guy-with-confetti-desktop.png'
import guyWithConfettiPicMobile from '../assets/leaderboard/guy-with-confetti-mobile.png'

import LineSpacer from '~/components/leaderboard/LineSpacer'
import HeroSection from '~/components/leaderboard/HeroSection'
import OurLeaderboardsSection from '~/components/leaderboard/OurLeaderboardsSection'
import DailyPrizesSection from '~/components/leaderboard/DailyPrizesSection'
import Rankings from '~/components/leaderboard/Rankings'
import VerticalGradient from '~/components/leaderboard/VerticalGradient'

interface LeaderboardProps {
  leaderboard: LeaderboardDetails
}

const Leaderboard: FC<LeaderboardProps> = ({ leaderboard }) => {
  // https://tailwindcss.com/docs/responsive-design
  const isSM = useMediaQuery({ query: '(min-width: 640px)' })
  const isMD = useMediaQuery({ query: '(min-width: 768px)' })
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })

  // Tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const now = new Date()
  const startAtDate = new Date(leaderboard.info.startAt)
  const endAtDate = getEndAtDate(leaderboard.info)
  const leaderboardStarted = startAtDate < now
  const leaderboardEnded =
    leaderboard.info.activeBoardId === leaderboard.info.rootBoardId && leaderboard.info.childBoardNum !== 0

  return leaderboardStarted
    ? (leaderboardEnded
        ? (
          // Leaderboard ended already
          <div className="pt-20 md:pt-40 flex justify-center">
            <h1 className="text-2xl font-normal text-center">
              <strong className="text-3xl">{leaderboard.info.name} leaderboard</strong>
              <br/><br/>
              ended already
              {endAtDate !== null &&
                <strong> on {endAtDate.toLocaleDateString()} at {endAtDate.toLocaleTimeString()}</strong>}
            </h1>
          </div>
          ) : (
          // All good, display leaderboard
          <>
            {/* Top Section */}
            <div className="flex flex-col items-center" style={{
              paddingBottom: isLG ? '5rem' : '3rem',
              gap: isLG ? '0' : '3rem'
            }}>
              <HeroSection imgSrc={isSM ? girlPicDesktop : girlPicMobile}/>
              {isLG && <LineSpacer/>}
              <OurLeaderboardsSection leaderboard={leaderboard} setSelectedTab={setSelectedTab}/>
              {isLG && <LineSpacer/>}
              <DailyPrizesSection
                imgSrc={isSM ? guyWithConfettiPicDesktop : guyWithConfettiPicMobile}
                leaderboard={leaderboard}
              />
            </div>

            {/* Leaderboard Section */}
            <div className="pb-24 flex flex-col" style={{ backgroundColor: '#F8F8F8' }}>

              {/* Gradient Rectangle */}
              {isMD && <VerticalGradient/>}

              {/* Rankings  - Prizes & Tables */}
              <Rankings leaderboard={leaderboard} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
            </div>
          </>
          )
      )
    : (
      // Leaderboard not started yet
      <div className="pt-20 md:pt-40 flex justify-center">
        <h1 className="text-2xl font-normal text-center">
          <strong className="text-3xl">{leaderboard.info.name} leaderboard</strong>
          <br/><br/>
          will start on <strong>{startAtDate.toLocaleDateString()} at {startAtDate.toLocaleTimeString()}</strong>
          {endAtDate !== null && (
            <>
              <br/>
              and end on <strong>{endAtDate.toLocaleDateString()} at {endAtDate.toLocaleTimeString()}</strong>
            </>
          )}
        </h1>
      </div>
      )
}

export default Leaderboard
