import { type FC, useEffect, useState } from 'react'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { deleteTeamMember, type FundraiserTeamResponse, getFundraiserTeam, inviteTeamMember, type TeamMember } from '~/state/fundraiser'
import { useParams } from 'react-router-dom'
import { Button, EmailInput, InputWithRef, Modal, Spinner, Validate, Variant } from '@lib/components'
import trashIcon from '~/assets/trash_black.svg'
import { useForm } from 'react-hook-form'
import { type TeamMemberForm } from '~/pages/fundraiser/TeamManagement'
import { PaymentFormTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'

export const Team: FC = () => {
  const { user } = useAuth()
  const { fundraiserID } = useParams() as { fundraiserID: string }
  const [showTeam, setShowTeam] = useState(false)
  const [fundraiserLoading, setFundraiserLoading] = useState(false)
  const [fundraiserError, setFundraiserError] = useState(false)
  const [fundraiserTeam, setFundraiserTeam] = useState<FundraiserTeamResponse>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [teamMemberToBeDeleted, setTeamMemberToBeDeleted] = useState<TeamMember>()

  const loadTeamMembers = (): void => {
    setFundraiserLoading(true)
    getFundraiserTeam(fundraiserID).then((data) => {
      setFundraiserTeam(data)
    }).catch(() => {
      setFundraiserError(true)
    }).finally(() => {
      setFundraiserLoading(false)
    })
  }

  useEffect(() => {
    loadTeamMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const form = useForm<TeamMemberForm>()

  const { register, handleSubmit, formState: { errors } } = form

  const handleClose = (): void => {
    setShowTeam(false)
    form.reset()
    loadTeamMembers()
  }

  const submit = async (data: TeamMemberForm): Promise<void> => {
    await inviteTeamMember(fundraiserID, data)
    setShowTeam(false)
    form.reset()
    loadTeamMembers()
  }

  const deleteInvite = async (data: TeamMember): Promise<void> => {
    await deleteTeamMember(fundraiserID, data.user_id)
    setShowTeam(false)
    form.reset()
    loadTeamMembers()
    setShowDeleteConfirmation(false)
  }

  const onDeleteClick = (data: TeamMember): void => {
    setTeamMemberToBeDeleted(data)
    setShowDeleteConfirmation(true)
  }

  const onDeleteConfirmCloseClick = (): void => {
    setTeamMemberToBeDeleted(undefined)
    setShowDeleteConfirmation(false)
  }

  const MemberTable: FC<{ teamMembers: TeamMember[] }> = ({ teamMembers }) => {
    return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left">
        <thead className="text-xs text-gm-green-900 uppercase bg-gm-gray-300">
        <tr>
          <th scope="col" className="px-6 py-3">
            Name
          </th>
          <th scope="col" className="px-6 py-3">
            Email
          </th>
          <th scope="col" className="px-6 py-3">
            <span className="sr-only">Action</span>
          </th>
        </tr>
        </thead>
        <tbody>
        {getTeamMemberRow(teamMembers)}
        </tbody>
      </table>
    </div>
  }

  const getTeamMemberRow = (members: TeamMember[]): JSX.Element[] => {
    return members.map(item =>
      <tr className="bg-white border-b bg-gm-gray-50">
        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
          <div>
            {`${item.firstName} ${item.lastName}`}
            {user && item.user_id === user.userId && <span className="font-thin text-xs">(You)</span>}
            {!item.accepted && <span className='bg-gm-gray-300 text-xs px-2 py-1 border-1 rounded ml-2'>Invited</span>}
        </div>
      </th>
      <td className="px-6 py-4">
        {item.email}
      </td>
      <td className="px-2">
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        {!(item.role.toLowerCase() === 'owner' || item.user_id === user!.userId) && <img className="h-6 cursor-pointer" src={trashIcon} onClick={async () => onDeleteClick(item)} alt="Remove" />}
      </td>
    </tr>)
  }

  return <FundraiserLayout title="Team Members">
    <div className='w-full relative'>
      <button
        className="rounded border-2 px-3 py-1 bg-gm-gray-300 absolute -top-10 right-0"
        onClick={() => setShowTeam(true)}>
        <span> + Invite member</span>
      </button>
    </div>

    {(fundraiserLoading) && <Spinner className="m-auto" />}
    {(fundraiserError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch fundraiser or charity data </p>}
    {!fundraiserLoading && !fundraiserError && fundraiserTeam && <div className='mt-5'>
      <MemberTable teamMembers={fundraiserTeam.team_members} />
    </div>
        }

    {showTeam && <Modal isOpen={showTeam} onClose={handleClose}>
      <div className='p-5'>
        <p className="text-gm-green text-xl font-bold w-96">Add team member</p>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(async (data) => submit(data))}>
          <div className="flex flex-col mt-4 w-full">
            <InputWithRef
              id="first_name"
              data-test-id={PaymentFormTestId.firstName}
              type="text"
              variant={Variant.gm}
              size={1}
              {...register('firstName', {
                required: 'Please provide first name',
                validate: Validate.validateFirstName
              })}
              placeholder="First name *" />
            {errors?.firstName?.message && <p
              data-test-id={PaymentFormTestId.firstNameError}
              className="mt-1 text-xs text-right text-gm-red min-h-xs">{errors?.firstName?.message ?? ''}</p>}
            <InputWithRef
              className="mt-4 mb-4"
              id="last_name"
              data-test-id={PaymentFormTestId.lastName}
              type="text"
              variant={Variant.gm}
              size={1}
              {...register('lastName', {
                validate: Validate.validateLastName
              })}
              placeholder="Last name" />
            <EmailInput
              data-test-id={PaymentFormTestId.email}
              variant={Variant.gm} register={register}
              errors={errors.email}
            />
            {errors.email?.message && <p data-test-id={PaymentFormTestId.emailError}
                                         className="mt-1 text-xs text-right text-gm-red min-h-xs">{errors.email?.message ?? ''}</p>}

            <Button variant="gm-primary-md"
                    className="mt-5 text-sm w-3/5 mx-auto rounded-lg font-light">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
    }
    {showDeleteConfirmation && teamMemberToBeDeleted && <Modal onClose={onDeleteConfirmCloseClick} isOpen={showDeleteConfirmation}>
      <h3 className="m-8 font-mono text-lg font-bold text-sz-gray-700">Remove Team Member</h3>
      <div className="mx-8 my-8 text">{`Are you sure you want to remove `}<span className='font-semibold'>{`${teamMemberToBeDeleted.firstName} ${teamMemberToBeDeleted.lastName} (${teamMemberToBeDeleted.email})`}</span> ?</div>
      <div className="p-4 mt-4 text-right bg-bluegray-50">
        <span className="p-2 mr-12 cursor-pointer text-bluegray-600 hover:text-bluegray-900" onClick={() => onDeleteConfirmCloseClick()}>CANCEL</span>
        {/* eslint-disable @typescript-eslint/no-misused-promises */}
        <Button onClick={async () => deleteInvite(teamMemberToBeDeleted)} variant="sz-danger"
                className="mx-2">
          REMOVE
        </Button>
      </div>
    </Modal>}
  </FundraiserLayout>
}
