import { createContext, type FC, useCallback, useContext } from 'react'
import { useSessionStorage, StorageEvents } from '@lib/hooks'
import { type Country, Currency, getUserCountry, StageName, getGivematchEnvironmentFromURL } from '@lib/services'
import { Domains } from '@givematch/common'
import { type Organiser } from '~/state/fundraiser'
import type Fundraiser from '~/state/fundraiser'
import { type CharityList } from '~/state/charity'
import { SessionStorage } from '~/service/sessionStorage'
import { handleFundraiserDataStorageUpdate } from '~/utils/fundraiserDraft'

export interface TeamMember {
  firstName: string
  lastName: string
  email: string
}

export interface FundraiserPlan {
  country: Country
  postcode: string
  charity: CharityList
  target_amount: number
  title: string | undefined
  story: string | undefined
  youtubeUrl: string
  coverPhoto: string
  new_image_upload: boolean
  charity_opt_in: boolean
  teamMembers: TeamMember[]
  draft_updated_time: string
  is_campaign?: boolean
}

interface FundraiserPlanResponse {
  fundraiser: FundraiserPlan
  setFundraiser: (fundraiserPlan: FundraiserPlan) => void
  resetFundraiser: () => void
}
interface EditFundRaiserPlan {
  editFundraiser: Fundraiser
  setEditFundraiser: (fundraiser: Fundraiser) => void
  resetEditFundraiser: () => void
}

export const getDefaultFundraiserPlan = (): FundraiserPlan => {
  return {
    country: getUserCountry(),
    postcode: '',
    charity: {
      charityID: '',
      name: '',
      currency: Currency.USD,
      causes: {},
      country: '',
      weight: 0,
      charityApi: '',
      coverImage: '',
      logoImage: '',
      status: ''
    },
    target_amount: 100000,
    title: undefined,
    story: undefined,
    youtubeUrl: '',
    coverPhoto: '',
    new_image_upload: false,
    charity_opt_in: false,
    teamMembers: [],
    draft_updated_time: ''
  }
}

const getDefaultEditFundraiser = (): Fundraiser => {
  const sampleOrganizer: Organiser = {
    donor_id: '',
    first_name: '',
    last_name: '',
    email: ''
  }
  return {
    fundraiser_id: '',
    charity_id: '',
    charity_name: '',
    uploadUrl: '',
    target_amount: 0,
    target_currency: Currency.USD,
    title: '',
    story: '',
    created_time: '',
    active: false,
    approved: false,
    organiser: sampleOrganizer,
    donations: [],
    raised_amount: 0,
    donation_count: 0,
    referral_donation_amount: 0,
    non_referral_donation_amount: 0,
    matched_amount: 0,
    image_path: '',
    youtube_url: '',
    deleted: false,
    donor_id: ''
  }
}

const fundraiserPlanContext = createContext<FundraiserPlanResponse>({
  fundraiser: getDefaultFundraiserPlan(),
  setFundraiser: (fundraiserPlan: FundraiserPlan) => console.log(fundraiserPlan),
  resetFundraiser: () => { }
})

const editFundRaiserContext = createContext<EditFundRaiserPlan>({
  editFundraiser: getDefaultEditFundraiser(),
  setEditFundraiser: (editedFundRaiser: Fundraiser) => console.log(editedFundRaiser),
  resetEditFundraiser () {}
})

export const FundraiserPlanProvider: FC = ({ children }) => {
  const [
    fundraiser, setFundraiser, _, // eslint-disable-line @typescript-eslint/no-unused-vars
    addEventListener
  ] = useSessionStorage(SessionStorage.fundraiserData, getDefaultFundraiserPlan())

  // Watch for writes to SessionStorage
  const environment: StageName | undefined = getGivematchEnvironmentFromURL(Domains.givematchDomain.bind(Domains))
  const listenerId: string = 'FundraiserPlanProvider'
  const debugPrint = environment !== StageName.prod
  addEventListener({
    event: StorageEvents.SET,
    key: SessionStorage.fundraiserData,
    listenerId, // cache key, so we add the listener just once
    listener: handleFundraiserDataStorageUpdate(listenerId, debugPrint)
  })

  const resetFundraiser = useCallback((): void => {
    setFundraiser(getDefaultFundraiserPlan())
  }, [setFundraiser])

  return (
      <fundraiserPlanContext.Provider value={{ fundraiser, setFundraiser, resetFundraiser }}>
        {children}
      </fundraiserPlanContext.Provider>
  )
}

export const EditFundRaiserProvider: FC = ({ children }) => {
  const [editFundraiser, setEditFundraiser] = useSessionStorage(SessionStorage.editFundraiserData, getDefaultEditFundraiser())

  const resetEditFundraiser = useCallback((): void => {
    setEditFundraiser(getDefaultEditFundraiser())
  }, [setEditFundraiser])

  const value = {
    editFundraiser,
    setEditFundraiser,
    resetEditFundraiser
  }
  return <editFundRaiserContext.Provider value={value} >{children}</editFundRaiserContext.Provider>
}

export const useFundraiserPlan = (): FundraiserPlanResponse => {
  return useContext(fundraiserPlanContext)
}

export const useEditFundraiserPlan = (): EditFundRaiserPlan => {
  return useContext(editFundRaiserContext)
}
