import { Component } from 'react'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.handler = props.handler
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  componentDidCatch (error, info) {
    this.setState({ hasError: true })

    if (typeof (this.handler) === 'function') {
      this.handler(error, info)
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render () {
    if (this.state.hasError) {
      return this.props.component
    }
    return this.props.children
  }
}

export default ErrorBoundary
