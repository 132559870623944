export class ShareCodeError extends Error {
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const shareDetails = async (shareCode) => {
  const res = await fetch(`/api/donation/${shareCode}`)

  if (res.status === 404) {
    throw new ShareCodeError()
  }

  if (res.status !== 200) {
    throw new Error('Something has gone wrong, please try again later')
  }

  return await res.json()
}
