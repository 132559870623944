import { Button } from '@lib/components'
import { type FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { FundRaiserImage } from '~/components/FundRaiser/FundRaiserCard'
import { type Donation } from '~/state/donation'
import { displayTidyCurrency, GetCurrencyByString } from '@lib/services'
import moment from 'moment'
import { DonationCharityImageDisplay } from './DonationDetails'
import { Tracking } from '~/service/tracking'

const GenerateDonorCardContent: FC<props> = ({ donation }) => {
  const donationAmount = Object.values(donation?.cause_donations ?? {}).reduce((acc, value) => acc + value, 0)
  return <div className="p-4">
        <p className="py-2">{donation.fundraiser_title}</p>
        <div className="px-2 text-xs flex flex-col gap-1">
            <p> • {displayTidyCurrency(donationAmount, GetCurrencyByString(donation.currency))} donated {moment(donation.created_time).fromNow()}</p>
        </div>
        <div className='py-2'>
            <Button
                className="w-full mt-5 rounded-lg shadow-md"
                variant='gm-share'
            >
                <div className='flex items-center justify-center'>
                    <span className='text-lg'>
                        Manage
                    </span>
                </div>

            </Button>
        </div>
    </div>
}

interface props {
  donation: Donation
}
export const FundraiserImageDisplay: FC<{ donation: Donation }> = ({ donation }) => {
  return <>
       <FundRaiserImage classname='rounded-xl w-full' image={donation.image_path} fundraiserId={donation.fundraiser_id} />
    </>
}

const DonorDonationCard: FC<props> = ({ donation }) => {
  const [logoExist, setLogoExist] = useState(true)
  const navigate = useNavigate()
  const manageDonations = (): void => {
    Tracking.manageDonation(donation.email)
    navigate(Navigation.donorFundraiserDetails(donation.share_code))
  }
  return <section className="border rounded border-gm-green-300 shadow" onClick={() => manageDonations()}>
        <div className="relative">
            {
                donation.fundraiser_id !== ''
                  ? <FundraiserImageDisplay donation={donation} />

                  : <DonationCharityImageDisplay logoExist={logoExist} charityID={donation.charity} setLogoExist={setLogoExist} name={donation.charity_name} coverImage={donation.charity_cover_image}/>
            }

        </div>
        <GenerateDonorCardContent donation={donation} />
    </section>
}
export default DonorDonationCard
