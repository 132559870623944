import { type FC, useState } from 'react'
import ShareTracker from '~/components/ShareTracker'
import Clipboard from 'react-clipboard.js'
import Copy from '~/assets/icons/copy.svg'
import { GivematchTestId } from '@givematch/common'

interface Props {
  charityID: string
  shareUrl: string
  shareMessage: string
}
export const ShareSocialMediaLinks: FC<Props> = ({ charityID, shareUrl, shareMessage }) => {
  interface ShareRowProps {
    label: string
    link: string
    image: string
    shareUrl: string
    className?: string
  }

  const ShareRow: FC<ShareRowProps> = ({ label, link, shareUrl, image, className }) => {
    return <div
      className={`p-3 ${className ?? ''}`}
    >
      <ShareTracker type={label} referralLink={shareUrl} charityId={charityID}>
        <a href={link}
          className="no-underline text-gm-green-900"
          target="_blank"
          rel="noopener noreferrer">
          <div className="flex items-center">
            <img className="w-8 h-8 rounded-lg" src={`/img/share_buttons/${image}`} alt={label} />
            <div className="flex-1 ml-4 text-base">
              {label}
            </div>
          </div>
        </a>
      </ShareTracker>
    </div>
  }

  const ClipBoard: FC = () => {
    const [tooltipClass, setTooltipClass] = useState('hidden')

    return <Clipboard
      data-test-id={GivematchTestId.shareLinkCopy}
      component="a"
      className="z-10 block cursor-pointer"
      data-clipboard-text={shareUrl}
      onSuccess={() => setTooltipClass('')}>
      <div className="p-3">
        <ShareTracker type="Copy" referralLink={shareUrl} charityId={charityID}>
          <div
            className="flex items-center"
            onMouseOut={() => { setTooltipClass('hidden') }}
            onTouchEnd={() => setTimeout(() => { setTooltipClass('hidden') }, 3000)}>
            <img
              src={Copy}
              alt="copy"
              className="w-8 h-8"
            />
            <div className={tooltipClass}>
              <div className="absolute block mt-4 text-sm">
                <div className="px-2 py-1 text-center text-white rounded-lg bg-gm-green-900">Copied!</div>
              </div>
            </div>
            <div className="flex-1 ml-4 text-base">
              Copy
            </div>
          </div>
        </ShareTracker>
      </div>
    </Clipboard>
  }

  return <>
    <div className="p-4 border-2 border-gm-green rounded-xl bg-gm-gray-50 ">
      <div className="bg-white border-2 border-solid rounded-lg border-gm-green">
        <ShareRow
          label={'WhatsApp'}
          link={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`}
          shareUrl={shareUrl}
          image={'whatsapp.svg'} />
      </div>
      <div className="mt-4 bg-white border-2 border-solid rounded-lg border-gm-green">
        <ClipBoard />
        <ShareRow
          className="border-t-2 border-b-2 border-l-0 border-r-0 border-solid border-gm-green"
          label={'Telegram'}
          link={`https://telegram.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareMessage)}`}
          shareUrl={shareUrl}
          image={'telegram.svg'} />
        <ShareRow
          label={'SMS'}
          shareUrl={shareUrl}
          link={`sms://;?&body=${encodeURIComponent(shareMessage)}`}
          image={'sms.jpg'} />
      </div>

      <div className="mt-4 bg-white border-2 border-solid rounded-lg border-gm-green">
        <ShareRow
          className="border-t-0 border-b-2 border-l-0 border-r-0 border-solid border-gm-green "
          label={'Facebook'}
          link={`https://www.facebook.com/share.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareMessage)}`}
          shareUrl={shareUrl}
          image={'facebook.svg'} />
        <ShareRow
          label={'Twitter'}
          shareUrl={shareUrl}
          link={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&hashtags=Givematch,Charity,Donation`}
          image={'twitter.svg'} />
      </div>

      <div className="mt-4 bg-white border-2 border-solid rounded-lg border-gm-green">
        <ShareRow
          label={'Email'}
          link={`mailto:?subject=Giving counts more on Givematch&body=${encodeURIComponent(shareMessage)}`}
          shareUrl={shareUrl}
          image={'email.svg'} />
      </div>
    </div>
  </>
}
