import { type FC, type ReactElement } from 'react'

export const ProgressSteps: FC<{ stepToActivate: number, numberOfSteps: number }> = ({ stepToActivate, numberOfSteps }) => {
  const getSteps = (): ReactElement[] => {
    const steps = []
    for (let i = 1; i <= numberOfSteps; i++) {
      steps.push(<div className={`${i <= stepToActivate ? 'bg-gm-green' : 'bg-gm-green-200'} rounded-full w-5 h-5 flex justify-center items-center col-start-1 sm:col-start-1 text-xs text-white`} key={i}><span>{i}</span></div>)
    }
    return steps
  }
  return <div className='flex justify-between w-full py-2'>
    {getSteps()}
  </div>
}
