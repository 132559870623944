import { type FC, useState } from 'react'
import { GetCurrencyByString, displayTidyCurrency, displayTidyWithoutCurrency, getCurrencySymbol, penniesToPound } from '@lib/services'
import CurrencyInput from 'react-currency-input-field'
import { Button } from '@lib/components'
import rightArrow from '../assets/icons/rightArrow.svg'
import { GivematchTestId } from '@givematch/common'
import { maxDonationByCurrency } from '~/service/maximumDonationAmounts'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import logoIcon from '../assets/logo_icon_dark.svg'
import { useNavigate, Link } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { getWalkForPlan } from '~/utils/fundraiserSteps'

interface props {
  onButtonClick: (amount: number) => void
  donateButton: boolean
  charityId?: string
  charityName?: string
  logoImage?: string
  showFundraiserButton?: boolean
  notAcceptingDonation?: boolean // this attribute will deactivate the button if it is in preview or the fundraiser is not accepting donations
}

const DonationCard: FC<props> = ({ onButtonClick, donateButton, charityId, charityName, logoImage, notAcceptingDonation, showFundraiserButton = true }) => {
  const navigate = useNavigate()
  const { donation, setDonation } = useDonationPlan()
  const { fundraiser } = useFundraiserPlan()
  const [logoExists, setLogoExists] = useState(true)
  const maxDonationMatch = maxDonationByCurrency(donation.donationCurrency)
  const onValueChange = (value?: string): void => {
    const donationAmount = parseFloat(value ?? '0') * 100
    if (donationAmount <= maxDonationMatch) {
      setDonation({ ...donation, amount: donationAmount, matchedAmount: donationAmount })
    } else {
      setDonation({ ...donation, amount: donationAmount, matchedAmount: maxDonationMatch })
    }
  }

  const startFundraiser = (): void => {
    Tracking.startYourFundraiser()
    // We can't just push the step with incomplete data into the history, as the back button will redirect
    // to the previous location in the history, which is not the previous step.
    // We need to push all the steps leading to the one with incomplete data.
    getWalkForPlan(fundraiser).forEach((step: string) => navigate(step))
  }
  const disableButton = donation.amount < 100 || notAcceptingDonation

  return <div><div className={`relative flex flex-col ${!charityName ? 'gap-y-5' : ''} p-6 max-w-sm font-bold text-left rounded-xl bg-white border-4 border-gm-green donationCard shadow-md`}>
    {donateButton && <div className='flex mb-2 text-gm-green text-2xl tracking-tighter justify-between'>
      YOU GIVE, WE MATCH
    </div>}
    {!donateButton && charityName && charityId && <div className="flex justify-end">
    {logoExists && (
          <img onError={() => setLogoExists(false)} src={logoImage ? `/imageuploads/charity/${charityId}/${logoImage}` : ''} className="absolute bg-white -top-14 rounded-lg align-middle w-24 h-24 object-contain" alt={`${charityName} logo`} />
    )}
    {!logoExists && (
      <p className="text-white p-1 bg-gm-green border-2 border-gm-green rounded">
        {charityName}
      </p>
    )}
  </div>}
    <div className="flex flex-col gap-y-5">
      <div>
        <p className="text-left text-gm-green-500">YOU DONATE</p>
        <div className='w-full flex border-2 rounded-md border-gm-green'>
          <span className='w-2/6 flex items-center px-2 text-5xl font-semibold text-right text-gm-green focus:outline-none '>{getCurrencySymbol(GetCurrencyByString(donation.donationCurrency))}</span>
          <CurrencyInput
            defaultValue={penniesToPound(donation.amount) ?? ''}
            data-test-id={GivematchTestId.donationInput}
            id="cause amount"
            className="w-4/6 px-2 text-5xl font-semibold text-right text-gm-green focus:outline-none border-gm-green"
            placeholder='.00'
            step={0.01}
            onValueChange={onValueChange}
            autoFocus
            readOnly={notAcceptingDonation}
          />
        </div>
        <p className="text-left text-gm-green-500 text-xs font-light">Enter your donation amount</p>
      </div>
      <div>
        <p className="text-left">WE DONATE</p>
        <div className="w-full border-2 rounded-md flex justify-between text-5xl text-left px-2 py-3 font-semibold overflow-hidden">
          <span>{getCurrencySymbol(GetCurrencyByString(donation.donationCurrency))}</span>
          <span>{displayTidyWithoutCurrency(donation.matchedAmount)}</span>
        </div>
        <p className="text-left text-xs font-light">Match Fund delivered by <Link target='_blank' rel='noreferrer' className=' text-gm-green cursor-pointer' to={Navigation.matchFundPartners()}> Partners </Link></p>
      </div>
      {donation.matchedAmount === maxDonationMatch && <div className='text-xs font-light'>*We match your first {displayTidyCurrency(maxDonationMatch, donation.donationCurrency)}</div>}
      <Button
        className={`w-full rounded-lg ${disableButton ? ' cursor-not-allowed bg-sz-gray-500  active:bg-sz-gray-500 text-gm-black' : ''}`}
        variant={!donateButton ? 'gm-primary' : disableButton ? 'gm-primary-md' : 'gm-share'}
        data-test-id={GivematchTestId.donateButton}
        disabled={ disableButton }
        onClick={() => onButtonClick(donation.amount)}>
        {(!donateButton && !charityId)
          ? <div className="flex gap-x-5 justify-center">
            <p className='text-2xl'>Pick a charity</p>
            <img src={rightArrow} alt="right arrow" className="w-6" />
          </div>
          : <div className="items-center">
            Donate
          </div>}
      </Button>

    </div>

  </div>
    {!charityId && showFundraiserButton && <Button
      className="w-full mt-5 rounded-lg shadow-md"
      variant='gm-share'
      data-test-id={GivematchTestId.startFundraiserButton}
      onClick={() => startFundraiser()}>
      <div className='flex items-center justify-center'>
        <img className="w-6 mr-5 " src={logoIcon} alt="Givematch" />
        <span className='font-semibold'>
          Start your fundraiser
        </span>
      </div>
    </Button>}
  </div>
}

export default DonationCard
