import { type FC, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth } from '@lib/hooks'
import { useExperiment } from '@lib/react-ab-test'
import { GivematchTestId } from '@givematch/common'

import ErrorBoundary from '../error'
import angleRightWhite from '~/assets/angle-right-white.svg'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { type CharityList, getCharity } from '~/state/charity'
import { ActiveExperiments, ExplainerPageRemovedVariants } from '~/splitTesting/splittesting'
import { defaultCharitySpelling, isNonApprovedAPICharity } from '~/utils/charityAPI'
import { ModalBtn, ModalNonApprovedAPICharity } from '~/components/ModalNonApprovedAPICharity'

const CharityCards: FC<{ charities: CharityList[], defaultCharity?: CharityList }> = ({ charities, defaultCharity }) => {
  const items = charities.map((charity) => (
    <ErrorBoundary key={charity.charityID} component={<></>}>
      <CharityCard charityItem={charity} defaultCharity={defaultCharity}></CharityCard>
    </ErrorBoundary>
  ))

  return <div className="flex flex-col justify-center">
    {charities.length === 0 &&
      <p className="mt-8 font-bold text-gm-red">There are currently no charities accepting donations in your selected country or matching search</p>}
    {items}
  </div>
}

export const CharityImageDisplay: FC<{ logoExist: boolean, charityID: string, setLogoExist: (val: boolean) => void, coverImage: string }> = ({ logoExist, charityID, setLogoExist, coverImage }) => {
  return <>
    {logoExist &&
      <div className='overflow-hidden h-14 w-14 shadow rounded-full bg-white'>
        <img className="h-14 w-14 object-contain " src={`/imageuploads/charity/${charityID}/${coverImage}`} alt={charityID} onError={() => setLogoExist(false)}></img>
       </div>
    }
    {!logoExist &&
      <div className='bg-gm-green h-12 w-12 flex justify-center items-center shadow rounded-md'>
        <img src={angleRightWhite} alt={charityID} className='h-6' />
      </div>
    }
  </>
}
export const CharityListView: FC<{ charity: CharityList, onCharitySelect: () => Promise<void> }> = ({ charity, onCharitySelect }) => {
  const [logoExist, setLogoExist] = useState(true)
  const { charityID, name, coverImage } = charity
  return (
    /* eslint-disable @typescript-eslint/no-misused-promises */
    <div
      data-test-id={`${GivematchTestId.charityCard_}${charityID}`}
      className="flex w-full border-2 my-1 px-4 py-2 gap-x-5  border-gm-green flex-row justify-between items-center bg-sz-gray-50 overflow-x-hidden rounded-md cursor-pointer hover:shadow-2xl"
      onClick={onCharitySelect}>
      <div className='w-2/3'>
        <p className=' text-sm text-left '>{name}</p>
      </div>
      <div className='w-1/3 flex justify-end'>
        <CharityImageDisplay charityID={charityID} logoExist={logoExist} setLogoExist={setLogoExist} coverImage={coverImage} />
      </div>
    </div>
    /* eslint-enable @typescript-eslint/no-misused-promises */
  )
}
const CharityCard: FC<{ charityItem: CharityList, defaultCharity?: CharityList }> = ({ charityItem, defaultCharity }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { setCharity, donation } = useDonationPlan()
  const { activeVariant: explainerPageRemovedVariant } = useExperiment(ActiveExperiments.ExplainerPageRemoved)
  const [showModal, setShowModal] = useState(false)

  const onCharitySelect = async (selectedCharity: CharityList): Promise<void> => {
    const charity = await getCharity(selectedCharity.charityID)

    if (isNonApprovedAPICharity(selectedCharity)) {
      setShowModal(true)
      Tracking.selectAPICharityNotApproved(charity, donation.amount, pathname, user?.email)
    } else {
      setCharity(charity)
      Tracking.selectCharity(charity, donation.amount)
      if (explainerPageRemovedVariant === ExplainerPageRemovedVariants.present) {
        navigate(Navigation.explainer())
      } else {
        navigate(Navigation.payment())
      }
    }
  }

  const onSelectMultiCharityBtnClick = async (): Promise<void> => {
    setShowModal(false)
    if (defaultCharity) {
      await onCharitySelect(defaultCharity)
    }
  }

  return (
    <>
      <CharityListView charity={charityItem} onCharitySelect={async () => onCharitySelect(charityItem)} />

      {showModal && (
        <ModalNonApprovedAPICharity
          showModal={showModal}
          setShowModal={setShowModal}
          charityName={charityItem.name}
          defaultCharity={defaultCharity}
          cta1={(
            <ModalBtn primary={true} hasBottomMargin={true} onClick={onSelectMultiCharityBtnClick}>
              Select {defaultCharitySpelling(defaultCharity?.name ?? '')}
            </ModalBtn>
          )}
          cta2={(
            <ModalBtn primary={!defaultCharity} onClick={() => setShowModal(false)}>
              Select another charity
            </ModalBtn>
          )}
        />
      )}
    </>
  )
}

export default CharityCards
