import { callService, Currency } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'

export enum MetricName {
  countReferral = 'count_referral',
  countMatched = 'count_matched',
  countDonor = 'count_donor',
  totalRaised = 'total_raised'
}

export enum LeaderboardReset {
  no = 'no', // all time, never reset
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly'
}

export enum ParticipantType {
  charity = 'CHARITY',
  donor = 'DONOR'
}

// NoChildBoards value for resetTimes, when board is non-recurring
export const NoChildBoards = -1

export interface PrizeInfo {
  rank: number
  amount: number
  unit: string
  metric: MetricName
}

export interface MetricTracked {
  name: MetricName
  isCurrency: boolean
  currency?: Currency
}

export interface MetricTrackedResponse {
  name: MetricName
  is_currency: boolean
  currency?: Currency
}

export interface Ranking {
  participantId: string
  participantName: string
  fundraiserId: string
  fundraiserTitle: string
  description?: string
  value: number
  delta?: number
}

export type MetricEntry = {
  [metric in MetricName]: {
    rankings: Ranking[][]
  }
}
export type EnrichedMetricEntry = {
  [metric in MetricName]: {
    rankings: Ranking[][]

    // Enrichment data
    leaderboardTitle: string
    valuesTitle: string
    isCurrency: boolean
  }
}

export interface LeaderboardInfo {
  leaderboardId: string
  participantsType: ParticipantType
  metricsTracked: MetricTracked[]

  createdAt: Date
  updatedAt?: Date
  archived: boolean

  activeBoardId: string
  rootBoardId: string
  childBoardNum: number

  name: string
  slug: string
  startAt: Date
  endAt?: Date
  reset: LeaderboardReset
  resetTimes: number
  prizes: PrizeInfo[]
}

export interface LeaderboardDetails {
  info: LeaderboardInfo
  metrics: EnrichedMetricEntry | Record<string, unknown>
  participantRankings?: Record<MetricName, number>
}

export interface LeaderboardGetResponse {
  info: {
    leaderboard_id: string
    participants_type: ParticipantType
    metrics_tracked: MetricTrackedResponse[]

    created_at: string
    updated_at?: string
    archived: boolean

    active_board_id: string
    root_board_id: string
    child_board_num: number

    name: string
    slug: string
    start_at: string
    end_at?: string
    reset: LeaderboardReset
    reset_times: number
    prizes: PrizeInfo[]
  }
  metrics: MetricEntry | Record<string, unknown>
  participant_rankings?: Record<MetricName, number>
}

export const LOCALE_EN_GB = 'en-GB'

export const AvailableMetrics: Record<MetricName, MetricTracked> = {
  [MetricName.totalRaised]: { name: MetricName.totalRaised, currency: Currency.GBP, isCurrency: true },
  [MetricName.countMatched]: { name: MetricName.countMatched, isCurrency: false },
  [MetricName.countDonor]: { name: MetricName.countDonor, isCurrency: false },
  [MetricName.countReferral]: { name: MetricName.countReferral, isCurrency: false }
}

// Helper functions

export const formatNumber = (locale: string, value: number, maximumFractionDigits: number, isCurrency: boolean, currency?: Currency): string => (
  new Intl.NumberFormat(locale,
    isCurrency
      ? { style: 'currency', currency, maximumFractionDigits }
      : undefined
  ).format(isCurrency ? value / 100 : value)
)

// export const formatNumberGB = (value: number, isCurrency: boolean, currency?: Currency, maximumFractionDigits = 2): string =>
//   formatNumber(LOCALE_EN_GB, value, maximumFractionDigits, isCurrency, currency)

export const formatPrize = ({
  amount,
  unit
}: Pick<PrizeInfo, 'amount' | 'unit'>, decimalPlaces = 0, locale = LOCALE_EN_GB): string => {
  switch (unit) {
    case Currency.AUD:
    case Currency.CAD:
    case Currency.CHF:
    case Currency.EUR:
    case Currency.GBP:
    case Currency.IDR:
    case Currency.MYR:
    case Currency.NOK:
    case Currency.SGD:
    case Currency.USD:
    case Currency.ZAR:
      return formatNumber(locale, amount, decimalPlaces, true, unit)

    default:
      return `${amount} ${unit}`
  }
}

export const friendlyMetricName = (metric: MetricName): string => {
  switch (metric) {
    case MetricName.countReferral:
      return 'Most Referrals'
    case MetricName.countMatched:
      return 'Most Matched'
    case MetricName.countDonor:
      return 'Most Donors'
    case MetricName.totalRaised:
      return 'Most Raised'
  }
}

export const friendlyValuesName = (metric: MetricName): string => {
  switch (metric) {
    case MetricName.countReferral:
      return 'Referrals'
    case MetricName.countMatched:
      return 'Matched'
    case MetricName.countDonor:
      return 'Donors'
    case MetricName.totalRaised:
      return 'Raised'
  }
}

export const friendlyValueDescription = (metric: MetricName, reset: LeaderboardReset): string => {
  const unit = getUnitForReset(reset, false).toLowerCase()
  switch (metric) {
    case MetricName.countReferral:
      return `Most successful referrals each ${unit}`
    case MetricName.countMatched:
      return `Most successful matches each ${unit}`
    case MetricName.countDonor:
      return `Most donors each ${unit}`
    case MetricName.totalRaised:
      return `Most funds raised each ${unit}`
  }
}

export const getUnitForReset = (reset: LeaderboardReset, plural: boolean = true): string => {
  switch (reset) {
    case LeaderboardReset.daily:
      return `Day${plural ? 's' : ''}`
    case LeaderboardReset.weekly:
      return `Week${plural ? 's' : ''}`
    case LeaderboardReset.monthly:
      return `Month${plural ? 's' : ''}`
    default:
      return 'Reset Times'
  }
}

export const getEndAtDate = (boardInfo: LeaderboardInfo, ignoreIsRoot: boolean = false): Date | null => {
  // https://stackoverflow.com/questions/563406/how-to-add-days-to-date
  function addDays (date: Date, days: number): Date {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  const multiplier =
    ignoreIsRoot
      ? 1
      : (
          boardInfo.rootBoardId === boardInfo.leaderboardId
            ? boardInfo.resetTimes // root board shows complete timeframe
            : 1 // child boards only increment by 1 reset amount
        )

  switch (boardInfo.reset) {
    case LeaderboardReset.no:
      return boardInfo.endAt ? new Date(boardInfo.endAt) : null
    case LeaderboardReset.daily:
      return new Date(addDays(new Date(boardInfo.startAt), multiplier))
    case LeaderboardReset.weekly:
      return new Date(addDays(new Date(boardInfo.startAt), multiplier * 7))
    case LeaderboardReset.monthly:
      // 4 weeks (28 days) - keep consistent with backend definition
      return new Date(addDays(new Date(boardInfo.startAt), multiplier * 28))
  }
}

export const currencyForMetric = (metric: MetricName, prizes: PrizeInfo[]): Currency => {
  // Grab the first prize that matches the metric & return the prize currency
  for (const prize of prizes) {
    if (prize.metric === metric) {
      return prize.unit as Currency
    }
  }

  // default fallback
  return Currency.GBP
}

const metricValueIsCurrency = (metric: MetricName, tracked: MetricTracked[]): boolean => {
  for (const t of tracked) {
    if (t.name === metric) {
      return t.isCurrency
    }
  }

  // default fallback
  return false
}

// Call API

export const getLeaderboardDetails = async (leaderboardID: string, qsParams: URLSearchParams): Promise<LeaderboardDetails> => {
  const zeroDate = '0001-01-01T00:00:00Z'
  const board: LeaderboardGetResponse = await callService(
    `/api/leaderboards/${leaderboardID}/details?${qsParams.toString()}`,
    HttpRequestMethod.GET
  )
  const leaderboard: LeaderboardDetails = {
    info: {
      leaderboardId: board.info.leaderboard_id,
      participantsType: board.info.participants_type,
      metricsTracked: board.info.metrics_tracked.map(m => {
        const metricTracked: MetricTracked = {
          name: m.name,
          isCurrency: m.is_currency,
          currency: m.currency
        }
        return metricTracked
      }),

      createdAt: new Date(board.info.created_at),
      ...(board.info.updated_at && board.info.updated_at !== zeroDate ? { updatedAt: new Date(board.info.updated_at) } : {}),
      archived: board.info.archived,

      activeBoardId: board.info.active_board_id,
      rootBoardId: board.info.root_board_id,
      childBoardNum: board.info.child_board_num,

      name: board.info.name,
      slug: board.info.slug,
      startAt: new Date(board.info.start_at),
      ...(board.info.end_at && board.info.end_at !== zeroDate ? { endAt: new Date(board.info.end_at) } : {}),
      reset: board.info.reset,
      resetTimes: board.info.reset_times,
      prizes: board.info.prizes
    },
    metrics: board.metrics,
    participantRankings: board.participant_rankings
  }

  // Enrich metric entries
  Object.keys(leaderboard.metrics).forEach((m) => {
    const metric = m as MetricName
    (leaderboard.metrics as EnrichedMetricEntry)[metric] = {
      // Original data
      rankings: (leaderboard.metrics as EnrichedMetricEntry)[metric].rankings,

      // Enrichment data
      leaderboardTitle: friendlyMetricName(metric),
      valuesTitle: friendlyValuesName(metric),
      isCurrency: metricValueIsCurrency(metric, leaderboard.info.metricsTracked)
    }
  })

  return leaderboard
}
