import { type FC } from 'react'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { Button } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { displayCurrency, displayTidyCurrency } from '@lib/services'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'
import CauseCardsRamadan from '~/components/ramadan/CauseCardsRamadan'
import rightArrow from '~/assets/icons/rightArrow.svg'
import { maxDonationByCurrency } from '~/service/maximumDonationAmounts'
import { calculateCauseTotal, GivematchTestId } from '@givematch/common'
import { Tracking } from '~/service/tracking'
import { ActiveExperiments, ExplainerPageRemovedVariants } from '~/splitTesting/splittesting'
import { useExperiment } from '@lib/react-ab-test'

const CausesRamadan: FC = () => {
  const { donation, setDonation } = useDonationPlan()
  const navigate = useNavigate()
  const maxDonationMatch = maxDonationByCurrency(donation.donationCurrency)
  const { activeVariant: explainerPageRemovedVariant } = useExperiment(ActiveExperiments.ExplainerPageRemoved)
  const onCauseAmountChange = (causeID: string, value: number): void => {
    value = isNaN(value) ? 0 : value
    setDonation({ ...donation, causeAmounts: { ...donation.causeAmounts, [causeID]: value } })
  }

  const getDonationTotal = (): number => {
    return calculateCauseTotal(donation.causeAmounts)
  }

  const getMatchedDonationAmount = (): number => {
    const donationAmount = getDonationTotal()
    return donationAmount > maxDonationMatch ? maxDonationMatch : donationAmount
  }

  const onContinue = (): void => {
    setDonation({ ...donation, matchedAmount: getMatchedDonationAmount() })
    Tracking.confirmAmount(donation.charity, donation.causeAmounts)
    if (explainerPageRemovedVariant === ExplainerPageRemovedVariants.present) {
      navigate(Navigation.explainer())
    } else {
      navigate(Navigation.payment())
    }
  }

  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    <section className="my-4 mx-4 sm:mx-20 md:mx-auto lg:mx-auto flex flex-col lg:justify-between md:max-w-7xl">
      <ProgressSteps stepToActivate={2} numberOfSteps={4}/>
      <p className="text-2xl font-semibold tracking-tighter text-left">Enter the amount you want to donate to each cause</p>

      {!donation.charity.causes && <p className="mt-8 font-bold text-gm-red">
        We were unable to load any causes
      </p>}

      { donation.charity.causes.length > 0 &&
        <>
          <CauseCardsRamadan onCauseAmountChange={onCauseAmountChange}/>
          <div className='border-2 rounded-md w-full flex justify-around mx-auto'>
            <div className='flex flex-col justify-center py-2'>
              <p className='text-gm-green text-xs font-light'>YOU DONATE</p>
              <p className='text-gm-green text-4xl font-bold'>{displayCurrency({ amount: getDonationTotal(), currency: donation.donationCurrency })}</p>
            </div>
            <div className='border-r-2 my-3'></div>
            <div className='flex flex-col justify-center py-2'>
              <p className='text-xs font-light'>WE DONATE</p>
              <p className='text-4xl font-bold'>{displayCurrency({ amount: getMatchedDonationAmount(), currency: donation.donationCurrency })}</p>
            </div>
          </div>
          {getMatchedDonationAmount() === maxDonationMatch && <div className='text-xs font-light text-left'>*We match your first {displayTidyCurrency(maxDonationMatch, donation.donationCurrency)}</div>}
          <Button
            className='w-full rounded-lg mt-5 mx-auto'
            variant='gm-primary'
            data-test-id={GivematchTestId.continueButton}
            onClick={onContinue}
            disabled={getDonationTotal() < 500}>
            <div className="flex gap-x-5 justify-between">
              <p>Continue</p>
              <img src={rightArrow} alt="right arrow" className="w-4" />
            </div>
          </Button>
        </>}

    </section>
    <Footer />
  </div>
}

export default CausesRamadan
