import { type FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@lib/components'
import { Currency, StringUtil } from '@lib/services'
import { useNavigate } from 'react-router-dom'
import GiftAidBanner from '../components/GiftAidBanner'
import PaymentBreakdown from '../components/PaymentBreakdown'
import styles from '../css/Payment.module.css'
import { calculateCauseTotal, calculateFee, calculatePlatformTip, GivematchTestId, isCause, isFundraiserCharity, isRamadan } from '@givematch/common'
import { PaymentFormTestId } from '@lib/testing'
import { type PaymentDetails, Tracking, type UserDetails } from '../service/tracking'
import { Navigation } from '~/service/navigation'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { type PaymentFormData, PaymentType } from '~/data/paymentFormData'
import PlatformTip from '~/components/PlatformTip'
import { useDonationPayment } from '~/hooks/payment'
import PaymentOptions from '~/components/PaymentOptions'
import GivematchLayout from '~/components/GivematchLayout'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'
import { useElements } from '@stripe/react-stripe-js'
const Payment: FC = () => {
  const { donation, resetDonation, setDonation } = useDonationPlan()
  const [formLoading, setFormLoading] = useState(true)
  const elements = useElements()
  const causeTotalAmount: number = isCause(donation.causeType) ? calculateCauseTotal(donation.causeAmounts) : donation.amount
  const calculateTotalFee = (payFees: boolean, causeTotal: number, platformTip: number, currency: Currency): number => {
    return payFees ? calculateFee(causeTotal + platformTip, currency) : 0
  }

  const calculateTotalAmount = (payFees: boolean, causeTotal: number, platformTip: number, transactionFee: number): number => {
    if (!payFees) {
      return causeTotal + platformTip
    }
    return causeTotal + transactionFee + platformTip
  }

  const navigate = useNavigate()
  if (!donation.charity || donation.charity.charity === '') {
    navigate(Navigation.home())
  }
  const form = useForm<PaymentFormData>({
    defaultValues: {
      email: donation.billingDetails.email,
      firstName: donation.billingDetails.firstName,
      lastName: donation.billingDetails.lastName,
      postcode: donation.billingDetails.postcode,
      country: donation.charity.country,
      firstAddress: donation.billingDetails.firstAddress,
      giftAid: donation.paymentDetails.giftAid,
      taxReceipt: donation.paymentDetails.taxReceipt,
      payFees: donation.paymentDetails.payFees,
      charityMarketingEmail: false,
      givematchMarketingEmail: false,
      remainAnonymous: donation.paymentDetails.remainAnonymous,
      tipPercentage: donation.paymentDetails.tipPercent,
      platformTip: calculatePlatformTip(causeTotalAmount, donation.paymentDetails.tipPercent),
      paymentType: donation.paymentDetails.paymentType,
      fundraiserNotification: false,
      ramadanEmail: false
    }
  })

  const { handleSubmit, watch, setValue } = form

  const [tipPercent] = watch(['tipPercentage'])

  const platformTip = calculatePlatformTip(causeTotalAmount, tipPercent)
  const transactionFee = calculateTotalFee(donation.paymentDetails.payFees, causeTotalAmount, platformTip, donation.donationCurrency ?? Currency.USD)
  const totalAmount = calculateTotalAmount(donation.paymentDetails.payFees, causeTotalAmount, platformTip, transactionFee)

  const toggleFees = (): void => {
    setValue('payFees', !form.getValues('payFees'))
    setDonation({ ...donation, paymentDetails: { ...donation.paymentDetails, payFees: !donation.paymentDetails.payFees } })
  }

  const checkGiftAid = (value: boolean): void => {
    setValue('giftAid', value)
    setDonation({ ...donation, paymentDetails: { ...donation.paymentDetails, giftAid: value } })
  }

  const onTipChange = (tip: number): void => {
    setValue('tipPercentage', tip)
    setValue('platformTip', calculatePlatformTip(causeTotalAmount, tip))
    setDonation({ ...donation, paymentDetails: { ...donation.paymentDetails, tipPercent: tip } })
  }

  const onSuccessHandler = (data: PaymentFormData): void => {
    const paymentDetails: PaymentDetails = {
      tipAmount: data.platformTip,
      tipPercentage: data.tipPercentage,
      totalAmount: causeTotalAmount,
      transactionFee,
      charityName: donation.charity.name ?? '',
      causeType: donation.causeType,
      fundraiserId: donation.fundraiserID,
      fundraiserName: donation.fundraiserTitle,
      eventId: donation.eventID,
      hearFromCharity: data.charityMarketingEmail,
      hearFromUs: data.givematchMarketingEmail,
      giftAid: data.giftAid,
      payWith: data.paymentType,
      currency: donation.donationCurrency,
      causes: donation.causeAmounts,
      hearFromUsAboutRamadan: data.ramadanEmail
    }
    const userDetail: UserDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.firstAddress,
      country: data.country ?? '',
      email: data.email
    }
    const shareCode = sessionStorage.getItem('shareCode') ?? ''
    Tracking.paymentSuccessful(
      shareCode,
      paymentDetails,
      donation.charity,
      userDetail)

    navigate(`/complete/${shareCode}`, {
      state: {
        currency: donation.donationCurrency,
        charityID: donation.charity.charity,
        donation: causeTotalAmount,
        email: data.email
      }
    })
    resetDonation()
    sessionStorage.removeItem('clientSecret')
    sessionStorage.removeItem('paymentIntentID')
    sessionStorage.removeItem('shareCode')
  }
  const { paymentRequest: phonePaymentRequest, submit, isLoading, error: paymentError, paymentCapabilities } = useDonationPayment(onSuccessHandler, form.getValues())
  const payByPhone = phonePaymentRequest && form.getValues('paymentType') !== PaymentType.card

  if (payByPhone) {
    form.setValue('paymentType', paymentCapabilities?.applePay ? PaymentType.applePay : PaymentType.googlePay)
  }
  useEffect(() => {
    if (phonePaymentRequest !== null && !StringUtil.isEmpty(donation.donationCurrency)) {
      phonePaymentRequest.update({
        currency: donation.donationCurrency,
        total: {
          label: 'Givematch total amount',
          amount: totalAmount
        }
      })
    }
  }, [totalAmount, phonePaymentRequest, donation.donationCurrency, donation.charity?.charity])

  useEffect(() => {
    if (elements !== null && !StringUtil.isEmpty(donation.donationCurrency)) {
      elements.update({
        currency: donation.donationCurrency,
        amount: totalAmount
      })
    }
  }, [totalAmount, donation.donationCurrency, elements, donation.charity?.charity])

  return (
    <GivematchLayout>
      <ProgressSteps stepToActivate={3} numberOfSteps={4} />

      <h1 className="text-2xl font-semibold tracking-tighter ">Payment</h1>
      {!donation.charity && <p className="mt-4 text-center text-gm-red">
        We were unable to retrieve the charity's information. Please go back and try again.
      </p>}

      {donation.charity && <div className={styles.narrow}>
        <div className={styles.paymentCards}>
          <div className={styles.paymentForm}>
            <PlatformTip onChange={onTipChange} tipPercentage={tipPercent} platformTip={platformTip} currency={donation.donationCurrency ?? Currency.USD} />

            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form id="paymentForm" onSubmit={handleSubmit(async (data) => submit(data))} className="flex flex-col gap-y-4 my-4">
              <PaymentOptions form={form} formLoading={formLoading} setFormLoading={setFormLoading}/>
              <div className='flex flex-col rounded bg-gm-gray-50 border gap-y-4 py-4'>
                {isFundraiserCharity(donation.causeType) &&
                  <label className="flex items-center w-full pl-4 border-gm-green">
                    <input
                      type="checkbox"
                      className="mr-4"
                      id="remainAnonymous"
                      {...form.register('remainAnonymous')} />
                    <span>
                      Don't display my name publicly on the fundraiser.
                    </span>
                  </label>
                }
                {isRamadan(donation.landingPage) &&
                  <label className="flex items-center w-full pl-4 border-gm-green">
                    <input
                      type="checkbox"
                      className="mr-4"
                      id="ramadanEmail"
                      {...form.register('ramadanEmail')} />
                    <span>
                      <span className='font-semibold'>I want to double my donation every Ramadan!</span> Email me annual reminders.
                    </span>
                  </label>
                }
                <label className="flex items-center w-full pl-4 border-gm-green">
                  <input
                    type="checkbox"
                    className="mr-4"
                    {...form.register('givematchMarketingEmail')}
                  />
                  <span className="">
                    <span className='font-semibold'>I’m a tech savvy donor,</span> please keep me updated on Givematch activity and news. </span>
                </label>

                <label className="flex items-center w-full pl-4 border-gm-green">
                  <input
                    type="checkbox"
                    className="mr-4"
                    data-test-id={PaymentFormTestId.charityMarketingEmail}
                    {...form.register('charityMarketingEmail')}
                  />
                  <span className="">
                    <span className="capitalize"> Keep me updated on news from {donation.charity.name}</span> </span>
                </label>

                {donation.fundraiserID !== '' && <label className="flex items-center w-full pl-4 border-gm-green">
                  <input
                    type="checkbox"
                    className="mr-4"
                    data-test-id={PaymentFormTestId.fundraiserNotification}
                    {...form.register('fundraiserNotification')}
                  />
                  <span className="">
                    This fundraiser can send me updates about their progress </span>
                </label>}
              </div>
              {donation.charity !== undefined && (donation.charity.gift_aid ?? false) &&
                <GiftAidBanner
                  checkbox={<input id="giftaid" className="w-5 h-5 border-2 border-gm-green-900 rounded mr-5 appearance-none checked:bg-gm-green-900" type="checkbox" onChange={(e) => checkGiftAid(e.target.checked)} defaultChecked={form.getValues('giftAid')} />}
                />}
            </form>
          </div>
          <div className={`lg:max-w-lg shadow-lg w-full border border-gm-green rounded-md mt-5 mb-4 p-4 ${styles.paymentContainer}`}>
            <PaymentBreakdown
              currency={donation.donationCurrency ?? Currency.USD}
              updatePayFees={toggleFees}
              payFees={donation.paymentDetails.payFees}
              platformTip={platformTip}
              totalAmount={totalAmount}
              transactionFee={transactionFee}
              causes={donation.charity?.causes ?? []}
            />
            <div className="flex justify-around">
              <Button
                  data-test-id={GivematchTestId.paymentConfirmButton}
                  type="submit"
                  form="paymentForm"
                  variant="gm-share"
                  className="w-full rounded-lg shadow-lg text-3xl mt-4"
                  disabled={isLoading || formLoading}>
                  {isLoading ? 'Processing...' : 'Donate'}
                </Button>
            </div>
            {paymentError && <div data-test-id={PaymentFormTestId.errorText}
              className="p-4 text-sm text-right text-gm-red">
              {paymentError instanceof Error ? paymentError.message : 'Sorry, we weren\'t able to process your donation'}
            </div>}
          </div>
        </div>
      </div>}
    </GivematchLayout>
  )
}

export default Payment
