import { Spinner } from '@lib/components'
import { type FC } from 'react'
import { useQuery } from 'react-query'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { getAlldonations } from '~/state/donation'
import DonorDonationCard from './DonorDonationCard'

export const DonorDashboard: FC = () => {
  const { isLoading: donationsLoading, isError: donationError, data: donations } = useQuery(['donations'], async () => getAlldonations())
  return <FundraiserLayout title="Donations you've made">
        <section className="py-4 grid md:grid-cols-2  grid-cols-1 gap-4">
            {!donationsLoading && !donationError && donations?.donations?.map(donation => <DonorDonationCard donation={donation} key={donation.share_code} />)
            }
            {(donationsLoading) && <Spinner className="m-auto" />}
            {(donationError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch your donations </p>}
        </section>
    </FundraiserLayout>
}
