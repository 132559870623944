import { type FC, type ReactNode, useState } from 'react'
import giftaid from '~/assets/gift-aid-logo.svg'
import info from '~/assets/info.svg'

const GiftAidBanner: FC<{ checkbox: ReactNode }> = ({ checkbox }) => {
  const [showInfo, setShowInfo] = useState(false)
  return <div className="rounded bg-gm-gray-50 border">
    <div className="flex p-4">
      <div className='w-1/4 flex items-center'>
        {checkbox}
      </div>
      <div className='w-2/4'>
        <img src={giftaid} alt={'giftaid'} className='h-8' />
      </div>
      <div onClick={() => setShowInfo(!showInfo)} className='w-1/4 flex items-center justify-end'>
        <img src={info} alt={'info'} className='h-4 cursor-pointer' />
      </div>
    </div>
    {showInfo && <div className="text-xs p-4">
      <label htmlFor="giftaid">By selecting yes, I declare that I am a UK taxpayer. I understand that if I pay less Income Tax and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on my donations I must pay the difference. I am happy for Mercy Mission to claim Gift Aid on this donation and on prior donations I have made in the past to Mercy Mission where applicable. Mercy Mission reserves the right to use third party service providers to collect gift aid on its behalf. Where necessary, your personal data may be shared with your chosen charity or our third-party providers in order to process your gift aid. For more information, please see our Privacy Policy.
        Where Mercy Mission deems it appropriate, Mercy Mission may use all or parts of this GiftAid to cover costs from 3rd party partners, including but not limited to fundraisers, donor advised fund fees and/or other third party providers who help facilitate or promote donations. This helps keep costs lower for charities, since the costs may be taken from the gift aid and thereby maintaining a free platform for your chosen charity and therefore furthers Mercy Mission’s charitable objects. If you do not agree with this, you should not select yes.</label>
    </div>}
  </div>
}

export default GiftAidBanner
