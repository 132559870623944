import { type FC } from 'react'
import { type EventDonation } from '~/state/charity'
import { DonorCard } from '../FundraiserDonation'

interface props {
  donations: EventDonation[]
}
export const DonationList: FC<props> = ({ donations }) => {
  return <section className="flex flex-col gap-y-4">
        {/* Donation Item component  */}
        {donations.map(donation => <DonorCard donation={donation} />)}

        {/* More component  */}
        {/* <div className="text-sm underline text-gm-green-500 cursor-pointer">
            <span> See all donations</span>
        </div> */}
    </section>
}
