import { type FC } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { Button, CircleChecked, CircleCrossed } from '@lib/components'

import { unsubscribe } from '~/state/unsubscribe'

const Unsubscribe: FC = () => {
  const { shareCode } = useParams() as { shareCode: string }

  const { mutate: submit, isIdle, isLoading, isError, isSuccess } = useMutation(async () => {
    await unsubscribe(shareCode)
  })

  return <div className="flex flex-col px-4 min-h-screen min-h-screen-ios bg-gm-gray-50">
    <div className="flex flex-col justify-center items-center p-8 m-auto max-w-xl text-center bg-white rounded shadow-lg">
      {(isIdle || isLoading) && <>
        <h1 className="text-2xl font-bold tracking-tighter sm:text-4xl">Are you sure you want to unsubscribe?</h1>
        <p className="mt-4">You'll no longer receive notification emails for this donation.</p>
        <Button variant="gm-primary" className="mt-8" onClick={() => submit()} disabled={isLoading} loading={isLoading}>
          Unsubscribe
        </Button>
      </>}

      {!isLoading && isSuccess && <>
        <CircleChecked className="w-12 h-12 text-gm-green" />
        <p className="mt-4 text-2xl font-bold tracking-tighter">You have successfully unsubscribed</p>
        <p className="mt-4 text-sm">You'll no longer receive notification emails for this donation.</p>
      </>}

      {!isLoading && isError && <>
        <CircleCrossed className="w-12 h-12 text-gm-red" />
        <p className="mt-4 text-2xl font-bold tracking-tighter">Something went wrong</p>
        <p className="mt-4 text-sm">Sorry, we were unable to unsubscribe you from our notification emails, please try again.</p>
      </>}

    </div>
  </div>
}

export default Unsubscribe
