import { Button } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type FC, useState } from 'react'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { publishFundraiserDraft } from '~/state/fundraiser'
import { GivematchFundraiserTestIds, PaymentFormTestId } from '@lib/testing'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { useMutation } from 'react-query'
import { charityDafPartnerDetails, Country } from '@lib/services'
import { Tracking } from '~/service/tracking'
import { useAuth } from '@lib/hooks'
import defaultCharityLogo from '~/assets/default-charity-logo.svg'
import { SessionStorage } from '~/service/sessionStorage'
import { getNextStep } from '~/utils/fundraiserSteps'
import { recaptchaFundraiserDraftPublish } from '~/utils/fundraiserDraft'

export const ConfirmCharity: FC = () => {
  const navigate = useNavigate()
  const { fundraiser, resetFundraiser, setFundraiser } = useFundraiserPlan()
  const [processing, setProcessing] = useState(false)
  const [informCharity, setInformCharity] = useState(false)
  const [error, setError] = useState('')
  const daf = charityDafPartnerDetails.get(fundraiser.charity.country as Country)!
  const { user } = useAuth()

  const updateInformingCharity = (): void => {
    setInformCharity(!informCharity)
    setFundraiser({ ...fundraiser, charity_opt_in: !informCharity })
  }

  const completeFundraiser = useMutation(async () => {
    setProcessing(true)
    setError('')
    return await publishFundraiserDraft(
      fundraiser.teamMembers,
      await recaptchaFundraiserDraftPublish.getToken()
    )
  }, {
    onSuccess: async (publishResponse) => {
      console.log('success')
      // Remove old draft that was just published
      sessionStorage.removeItem(SessionStorage.fundraiser_draft_id_created)
      // Set the id of the published fundraiser, so it can be used for sharing
      sessionStorage.setItem(SessionStorage.fundraiser_id_published, publishResponse.fundraiser_id)
      Tracking.completeFundraiser({ ...fundraiser, is_campaign: publishResponse.is_campaign }, informCharity, user)
      navigate(getNextStep(Navigation.fundraiserConfirmCharity()))
      resetFundraiser()
    },
    onError: (e) => {
      console.error(e)
      setError('We\'re sorry there has been a problem with setting your fundraiser')
      setProcessing(false)
    }
  })

  return <FundraiserLayout title="Confirm your charity">
    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Money raised will support</span>
    <div className="flex mt-5 px-10 py-5 rounded-lg border-2 border-gm-green">
    <img src={`/imageuploads/charity/${fundraiser.charity.charityID}/${fundraiser.charity.logoImage}`} className="rounded w-1/6 object-contain" alt={`${fundraiser.charity.name} logo`} onError={(error) => (error.currentTarget.src = defaultCharityLogo)}/>
        <div className='ml-5 flex flex-col w-5/6'>
          <span className='text-md font-semibold'>{fundraiser.charity.name === '' ? fundraiser.charity.charityID : fundraiser.charity.name}</span>
          <ul className='list-disc list-inside'>
            {Object.values(fundraiser.charity.causes).map(cause => <li className='text-xs font-light'>{cause.name}</li>)}
          </ul>
        </div>
    </div>

    <span className="mt-5 font-bold">This means you will not personally withdraw money from our fundraisers.</span>
    <p className="mt-5">Your donations will be received by The Givematch Fund held at {daf.name}, a registered charity (No. {daf.number}), will receive the donations and grant the funds to your chosen charity via bank transfer or cheque.</p>
    <p className="mt-5">Charities will typically receive the donations from {daf.name} within 15 - 45 days of your donation if the charity has enrolled with {daf.name} (or 90 days if the charity has not yet enrolled). {(fundraiser.charity.country as Country) === Country.GB ? 'Unlike other platforms, 100% of any Gift Aid will go to the charity as well.' : ''}</p>
    <p className="mt-5">In very rare cases, a recommended charity may cease to meet {daf.name}'s requirements to receive funds. In such cases, {daf.name} will reassign the funds to another charity and (wherever possible) seek a new recommendation from the donor, in accordance with its policies.</p>
    <p className="mt-5">If the charity you are fundraising for requests your contact information, Givematch may provide it to the charity to contact you about the fundraiser you started on its behalf, and for other legally permissible uses. By continuing, you agree to Givematch's terms and {daf.name}'s terms.</p>

    <div className="border-t border-gm-green my-5"></div>
    <p>By launching your fundraiser, you understand donors will donate to and receive tax receipts from {daf.name}.</p>

    <section className="border-2 border-bluegray-200 rounded-lg bg-bluegray-50 mt-5 cursor-pointer">
      <div className="flex pl-5 py-4">
        <div>
          <input
            id="inform"
            className="w-5 h-5 border-2 border-gm-green-900 rounded mr-5 appearance-none checked:bg-gm-green-900"
            type="checkbox"
            checked={informCharity}
            onChange={() => updateInformingCharity()} />
        </div>
        <label htmlFor="inform" className="text-xs">{`I'm happy for ${fundraiser.charity.name} to inform me by email about more ways that I can help and the impact of my fundraising.`}</label>
      </div>
    </section>

    {/* eslint-disable @typescript-eslint/no-misused-promises */}
    <Button
      data-test-id={GivematchFundraiserTestIds.completeFundraiserButton}
      className="w-full mt-5"
      variant="gm-primary"
      onClick={async () => completeFundraiser.mutate()}
      disabled={processing}>
      <span>Complete Fundraiser</span>
    </Button>
    {/* eslint-enable @typescript-eslint/no-misused-promises */}
    {error !== '' && <p data-test-id={PaymentFormTestId.errorText}
                        className="p-4 text-sm text-right text-gm-red">{error}</p>}
  </FundraiserLayout>
}
