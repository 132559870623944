import { type FC } from 'react'
import { Tracking } from '../service/tracking'

const ShareTracker: FC<{ type: string, referralLink: string, charityId: string }> = ({ children, type, referralLink, charityId }) => {
  const isWidget = window.parent !== window

  const handleClick = (): void => {
    Tracking.shareInviteLinkType(type, referralLink, isWidget, charityId)
  }

  return <div onClick={() => handleClick()}>
    {children}
  </div>
}

export default ShareTracker
