import { type FC } from 'react'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { useQuery } from 'react-query'
import { getCharity } from '~/state/charity'
import { Spinner } from '@lib/components'
import DonationHero from '~/components/DonationHero'
import { SessionStorage } from '~/service/sessionStorage'
import { LandingPageType } from '@givematch/common'
import { Tracking } from '~/service/tracking'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { HeadlineWithDonate } from '~/components/HeadlineWithDonate'
import { isNonApprovedAPICharity } from '~/utils/charityAPI'

export const LandingPageWithCharity: FC<{ referrerCharityId: string, landingPage: string }> = ({ referrerCharityId, landingPage }) => {
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { setCharity, donation } = useDonationPlan()
  const {
    isLoading: charityLoading,
    isError: charityError,
    data: charity
  } = useQuery(['charity', referrerCharityId], async () => getCharity(referrerCharityId), {
    onSuccess: (charity) => {
      setCharity(charity)
      sessionStorage.setItem(SessionStorage.referrerCharityKey, JSON.stringify({
        name: charity.name,
        id: charity.charity
      }))
      if (landingPage === LandingPageType.Ramadan) {
        sessionStorage.setItem(SessionStorage.campaignName, Tracking.getRamadanCampaignName())
      }
      Tracking.pageViewedWithTracking(pathname, user?.email)
    }
  })
  const acceptingDonations = (donation.charity?.causes ?? []).length > 0

  return <>
    {charityLoading && <Spinner className="m-auto" />}
    {charityError && <p className="px-4 mt-4 text-gm-red"> We were unable to find your friend's charity </p>}
    {!charityLoading && !charityError && !acceptingDonations && <p className="mt-4 text-gm-red"> Sorry, but we&apos;re no longer accepting donations to this charity.</p>}
    {!charityLoading && !charityError && acceptingDonations && charity && landingPage !== LandingPageType.Ramadan && (
      <DonationHero
        charityName={charity.name}
        charityId={charity.charity}
        charityCurrency={charity.currency}
        logoImage={charity.logo_image}
        landingPage={landingPage}
        isNotApprovedCharityAPI={isNonApprovedAPICharity(charity)}
      />
    )}
    {!charityLoading && !charityError && acceptingDonations && charity && landingPage === LandingPageType.Ramadan && <HeadlineWithDonate charityId={charity.charity}/>}
  </>
}
