import { useParams } from 'react-router-dom'
import { type FC } from 'react'
import Fundraiser from '~/components/Fundraiser'
import { SessionStorage } from '~/service/sessionStorage'

const FundraiserLanding: FC = () => {
  sessionStorage.removeItem(SessionStorage.currentFundraiser) // we want to remove any fundraiser in local storage before page renders
  const { fundraiseID } = useParams() as { fundraiseID: string }

  return <Fundraiser fundraiserID={fundraiseID} />
}

export default FundraiserLanding
