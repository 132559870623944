import { Spinner } from '@lib/components'
import { type FC } from 'react'
// import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DonationList } from '~/components/FundRaiser/DonationList'
import { FundRaiserInvite } from '~/components/FundRaiser/FundRaiserInvite'
// import { FundRaiserSearch } from '~/components/FundRaiser/FundRaiserSearch'
import { FundRaiserShareCard } from '~/components/FundRaiser/FundRaiserShareCard'
import { FundraiserLayout } from '~/components/FundraiserLayout'
// import { DonationSearchData } from '~/data/donationFormData'
import { getAllDonationsByFundraiserId } from '~/state/fundraiser'

export const Donations: FC = () => {
  const { fundraiserID } = useParams() as { fundraiserID: string }
  const { isLoading: fundraiserLoading, isError: fundraiserError, data: fundraiserDetails } = useQuery(['fundraiser', fundraiserID], async () => getAllDonationsByFundraiserId(fundraiserID))
  //   let filterTimeout: NodeJS.Timeout
  //   const filterDonations = (): void => {
  //     clearTimeout(filterTimeout)
  //     filterTimeout = setTimeout(() => {
  //       setType(form.getValues('category'))
  //       console.log(form.getValues())
  //       // TODO make api request
  //     }, 500)
  //   }
  //   const form = useForm<DonationSearchData>({
  //     defaultValues: {
  //       searchTerm: '',
  //       category: ''
  //     }
  //   })
  return <FundraiserLayout title="Donations">
        {(fundraiserLoading) && <Spinner className="m-auto" />}
        {(fundraiserError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch donations data </p>}
        {!fundraiserLoading && !fundraiserError && fundraiserDetails &&
            <><FundRaiserShareCard fundraiserId={fundraiserID} />
                {/* <FundRaiserSearch form={form} onChange={filterDonations} /> */}
                {fundraiserDetails.donations ? <DonationList donations={fundraiserDetails.donations} /> : <FundRaiserInvite messageType={''} />}
            </>
        }
    </FundraiserLayout>
}
