import { type FC } from 'react'
import logoIcon from '../assets/new_logo.svg'
import { productName } from '../service/activeCampaign'
import { Navigation } from '~/service/navigation'

interface FooterProps {
  displayPolicies?: boolean
  className?: string
}

const Footer: FC<FooterProps> = ({ className, displayPolicies = true }) => {
  const isWidget: boolean = window.parent !== window

  return <footer className={`sticky items-center py-4 px-12 md:px-12 lg:px-40 mt-auto font-semibold bg-gm-gray-50 text-gm-green ${className ?? ''} w-full`}>
    <div className="flex flex-col  my-4">
      <img className="w-36" src={logoIcon} alt="Givematch" />
    </div>
    <div className="flex flex-col ">
      {displayPolicies && !isWidget && <div className="flex flex-col md:flex-row md:justify-between gap-y-5 text-left">
        <div className='flex flex-col text-left'>
          <a href="/about-us">About Us</a>
          <a target="_blank" rel="noreferrer" href="https://medium.com/@Givematch">Blog</a>
          <a href="/faqs">FAQs</a>
          <a href="/contact">Contact Us</a>
        </div>
        <div className='flex flex-col '>
          <a href={Navigation.charityPartner()}>For Charities</a>
          <a href={Navigation.corporatePartner()}>For Corporates</a>
          <a href="/partners/matchfunders">Match Fund Partners</a>
          <a href={Navigation.goodPractice()}>Good Practice</a>
        </div>
        <div className='flex flex-col'>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/cookie-preferences">Cookie Preferences</a>
          <a href="/terms-conditions">Terms &amp; Conditions</a>
          <a href={Navigation.complaintsPolicy()}>Complaints Policy</a>
        </div>

      </div>}
      <div className='flex flex-col py-5'>
        <p >© {new Date().getFullYear()} {productName()} All Rights Reserved</p>
      </div>
    </div>
  </footer>
}

export default Footer
