import { type FC, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Spinner } from '@lib/components'
import { useAuth } from '@lib/hooks'
import { Tracking } from '~/service/tracking'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import Leaderboard from '~/components/Leaderboard'

import { getLeaderboardDetails, type LeaderboardDetails } from '@givematch/common'

// A custom hook that builds on useLocation to parse the query string for you
// https://v5.reactrouter.com/web/example/query-parameters
function useQueryStrings (): URLSearchParams {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

interface LeaderboardLandingProps {
  leaderboardID?: string
}

const LeaderboardLanding: FC<LeaderboardLandingProps> = ({ leaderboardID }) => {
  const pathParams = useParams() as { leaderboardID: string }
  if (leaderboardID === undefined) {
    // this is used when accessing the leaderboard by id route
    leaderboardID = pathParams.leaderboardID
  }
  const qsParams = useQueryStrings()
  const { user } = useAuth()
  const { pathname } = useLocation()

  const [leaderboard, setLeaderboard] = useState<LeaderboardDetails>()
  const {
    isLoading,
    isError
  } = useQuery<LeaderboardDetails>(['leaderboard'], async () => getLeaderboardDetails(leaderboardID!, qsParams), {
    // leaderboardID can still be undefined when using the leaderboard by slug route;
    // ensure we don't fire the API call until we have a valid id
    enabled: !!leaderboardID,
    onSuccess: (details: LeaderboardDetails) => {
      setLeaderboard(details)
      Tracking.pageViewedWithTracking(pathname, user?.email)
    }
  })

  return (
    <div className="lg:min-h-screen w-full flex flex-col">
      <Header className="bg-gm-gray-50 sm:bg-white" />
      {!isLoading && !isError && leaderboard && <Leaderboard leaderboard={leaderboard}/>}
      {(isLoading) && <Spinner className="m-auto"/>}
      {(isError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch leaderboard</p>}
      <Footer className="text-gm-newgreen" />
    </div>
  )
}

export default LeaderboardLanding
