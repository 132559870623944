import { type FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@lib/components'
import { useLocalStorage } from '@lib/hooks'
import { GivematchTestId } from '@givematch/common'
import { CustomerIO, MixpanelInstance } from '@lib/services'
import { MIXPANEL_PROD_TOKEN, MIXPANEL_TEST_TOKEN } from '../service/tracking'
import { facebookPixel } from '~/facebook'
import { initializeDataLayer } from '~/ga'

const CookieBanner: FC = () => {
  const [accepted, setAccepted] = useLocalStorage<boolean | null>('cookie-banner', true)
  const location = useLocation()

  useEffect(() => {
    if (accepted) {
      MixpanelInstance.init(MIXPANEL_PROD_TOKEN, MIXPANEL_TEST_TOKEN)
      CustomerIO.enable()
      facebookPixel()
      initializeDataLayer()
    }
  }, [accepted])

  if ((accepted === null) && (location.pathname.startsWith('/share/') || location.pathname.startsWith('/charityShare/') || location.pathname.startsWith('/link/'))) {
    setAccepted(true)
    return <></>
  }

  if (accepted !== null) {
    return <></>
  }

  return <div className="sticky bottom-0 px-4 py-4 text-center transition bg-gm-gray-100 sm:text-left sm:px-12 sm:py-4">
    <div className="items-center mx-auto max-w-7xl sm:flex">
      <p className="flex-1 text-sm">
        We use cookies to <strong>authenticate your identity and keep your data secure</strong>, these cookies are
        essential to the functioning of this site.
        We use other cookies to <strong>analyse traffic and understand where our visitors are coming from</strong>.
        Information about your use of this site is
        shared
        with our analytics and advertising partners. For more information about the cookies we use please go <Link
        className="underline text-gm-green"
        to="/privacy-policy">here</Link>.
        You can approve or reject the use of cookies on this site by clicking <Link className="underline text-gm-green"
                                                                                    to="/cookie-preferences">here</Link>.
        By
        clicking “Accept All” you are consenting to our use of all cookies listed in our cookies policy.
      </p>

      <Button
        data-test-id={GivematchTestId.cookieBannerAcceptAll}
        className="mt-4 transform scale-75 sm:mt-0"
        variant="gm-secondary"
        onClick={() => setAccepted(true)}>
        Accept All
      </Button>
    </div>
  </div>
}

export default CookieBanner

export const CookieLayout: FC = ({ children }) => <>
  {children}
  <CookieBanner />
</>
