import { Button } from '@lib/components'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import type Fundraiser from '~/state/fundraiser'
import moment from 'moment'
import { type Charity } from '~/state/charity'
import { compactNumber, displayTidyCurrency, isProd } from '@lib/services'
import ProgressBar from '@ramonak/react-progress-bar'
import defaultFundraiserImage from '~/assets/fundraiser/fundraiser-default.jpeg'

const GenerateCardContent: FC<props> = ({ fundraiser }) => {
  const navigate = useNavigate()
  return <div className="p-4">
        <p className="py-2">{fundraiser.title}</p>
        <div className="px-2 text-xs flex flex-col gap-1">
            <p> • {fundraiser.charity_name}</p>
            <p> • Created {moment(fundraiser.created_time).fromNow()} </p>
        </div>
        <div className='py-2'>
            <Button
                className="w-full mt-5 rounded-lg shadow-md"
                variant='gm-share'
                onClick={() => {
                  navigate(Navigation.fundraiserDetails(fundraiser.fundraiser_id))
                }}
            >
                <div className='flex items-center justify-center'>
                    <span className='text-lg'>
                        Manage
                    </span>
                </div>

            </Button>
        </div>
    </div>
}
const getProgressBar = (targetAmount: number, raisedAmount: number): number => {
  const percentageRaised = (raisedAmount / targetAmount) * 100
  return percentageRaised
}
const GenerateDetailsCardContent: FC<props> = ({ fundraiser, charity }) => {
  const navigate = useNavigate()
  return <div className="p-4">
        <p className="py-2">{fundraiser.title}</p>
        <ProgressBar className='w-full' completed={getProgressBar(fundraiser.target_amount, fundraiser.raised_amount)} bgColor='#02A47B' height='3px' isLabelVisible={false} />
        {charity && <div className="flex flex-row py-2">
            <div className="w-1/3 text-lg font-bold">
                <span> {displayTidyCurrency(fundraiser.raised_amount, charity.currency, true)} </span>
            </div>
            <div className="w-2/3 text-xs">
                <span>
                    raised of {displayTidyCurrency(fundraiser.target_amount, charity.currency, true)} - {compactNumber(fundraiser.donation_count)} donors on behalf of {fundraiser.charity_name}</span>
            </div>
        </div>}
        <div className='py-2 flex flex-row-reverse gap-2'>
            <div className="w-1/2">
                <Button
                    className="w-full mt-5 rounded-lg shadow-md"
                    variant='gm-share'
                    onClick={() => {
                      navigate(Navigation.shareFundraiser(fundraiser.fundraiser_id))
                    }}
                >
                    <div className='flex items-center justify-center'>
                        <span className='text-sm'>
                            Share
                        </span>
                    </div>

                </Button>
            </div>
            {/* <div className="w-1/2">
                <Button
                    className="w-full mt-5 rounded-lg shadow-md"
                    variant='gm-primary'
                    onClick={() => {
                      alert('not implemented')
                    }}
                >
                    <div className='flex items-center justify-center'>
                        <span className='text-sm'>
                            Post Update
                        </span>
                    </div>

                </Button>
            </div> */}
        </div>
    </div>
}
interface props {
  isDetails: boolean
  fundraiser: Fundraiser
  charity?: Charity
  className?: string
}
export const FundRaiserImage: FC<{ classname: string, image: string, fundraiserId: string }> = ({ classname, fundraiserId, image }) => {
  let imgSrc
  if (image.startsWith('data:image')) { // we need a way to render base64 directly, this is useful for edit section
    imgSrc = image
  } else {
    const imagePrefix: string = isProd() ? '' : 'https://givematch.link'
    imgSrc = imagePrefix + image
  }
  return <img className={classname} src={imgSrc} alt={fundraiserId} onError={(e) => (e.currentTarget.srcset = defaultFundraiserImage)} />
}
const FundRaiserCard: FC<props> = ({ isDetails, fundraiser, charity, className }) => {
  return <section className={`border rounded border-gm-green-300 shadow ${className}`}>
        <div className="relative">
            <FundRaiserImage classname='rounded-xl w-full' image={fundraiser.newCoverPhoto ?? fundraiser.image_path} fundraiserId={fundraiser.fundraiser_id} />
            {/* <div className={`absolute border border-transparent rounded-2xl px-3 py-1 bg-white text-gm-green-500 bottom-4 right-4 text-sm md:bottom-2 md:right-2 md:text-xs`}>{fundraiser.active ? 'Published' : 'Unpublished'}</div> */}
        </div>
        {
            isDetails
              ? <GenerateDetailsCardContent isDetails={true} fundraiser={fundraiser} charity={charity} />
              : <GenerateCardContent isDetails={false} fundraiser={fundraiser} />
        }
    </section>
}
export default FundRaiserCard
