import { type FC } from 'react'
import aol from '~/assets/media/Aol_logo.svg'
import charityToday from '~/assets/media/CharityToday.svg'
import theIndependent from '~/assets/media/TheIndependent_logo.svg'
import ukFundraiser from '~/assets/media/uk-fundraising-logo.svg'
import womenHealth from '~/assets/media/Womens_Health_logo.svg'
import yahoo from '~/assets/media/Yahoo-Logo.svg'

export const FeaturedOnMedia: FC = () => {
  return <div className='px-5 sm:px-20 md:px-24 lg:px-40 my-5'>
    <h1 className='text-2xl font-bold '>As featured on</h1>
    <div className='flex flex-col gap-y-5'>
      <div className='flex justify-center gap-x-10 w-full flex-wrap '>
        <img src={theIndependent} className='w-25' alt='The Independent'/>
        <img src={yahoo} className='w-24' alt='Yahoo'/>
      </div>
      <div className='flex justify-center gap-x-10 w-full flex-wrap'>
        <img src={charityToday} className='w-25' alt='Charity Today'/>
        <img src={aol} className='w-25' alt='Aol'/>
      </div>
      <div className='flex justify-center gap-x-10 w-full flex-wrap'>
        <img src={womenHealth} className='w-25' alt='Women Health'/>
        <img src={ukFundraiser} className='w-25' alt='UK fundraiser'/>
      </div>
    </div>
  </div>
}
