import { type FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type CharityList, getCharity } from '../../state/charity'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import ErrorBoundary from '~/error'
import { GivematchTestId } from '@givematch/common'
import angleRightWhite from '~/assets/angle-right-white.svg'
import { Button } from '@lib/components'
import ir_uk from '~/assets/charity-icons/islamic-relief.svg'
import al_mustafa from '~/assets/charity-icons/al-mustafa.svg'
import muslim_aid from '~/assets/charity-icons/muslim-aid.svg'
import orphan_in_need from '~/assets/charity-icons/orphans-in-need.svg'

const RamadanCharityListView: FC<{ charity: CharityList, onCharitySelect: () => Promise<void> }> = ({ charity, onCharitySelect }) => {
  const [logoExist, setLogoExist] = useState(true)
  const { charityID, name, coverImage } = charity
  return (
    /* eslint-disable @typescript-eslint/no-misused-promises */
    <div
      data-test-id={`${GivematchTestId.charityCard_}${charityID}`}
      className="flex w-full flex-row lg:w-1/4 md:w-1/3 md:flex-col border-2 my-1 px-4 py-2 md:py-4 gap-x-5 md:gap-y-5  border-gm-green  justify-between md:justify-center items-center bg-sz-gray-50 overflow-x-hidden rounded-md cursor-pointer hover:shadow-2xl"
      onClick={onCharitySelect}>
      <div className='w-2/3 md:w-full'>
        <p className=' text-sm text-left md:text-center md:text-md font-semibold'>{`${charityID.startsWith('multi-charity') ? 'Spread donations across charities' : name}`}</p>
      </div>
      <div className='w-1/3 flex justify-end md:w-full  md:flex-col md:items-center'>
        <RamadanCharityImageDisplay charityID={charityID} logoExist={logoExist} setLogoExist={setLogoExist} coverImage={coverImage} />
      </div>
    </div>
    /* eslint-enable @typescript-eslint/no-misused-promises */
  )
}

const RamadanCharityImageDisplay: FC<{ logoExist: boolean, charityID: string, setLogoExist: (val: boolean) => void, coverImage: string }> = ({ logoExist, charityID, setLogoExist, coverImage }) => {
  let imageSrc = `/imageuploads/charity/${charityID}/${coverImage}`
  if (charityID.startsWith('islamic-relief')) {
    imageSrc = ir_uk
  } else if (charityID.startsWith('al-mustafa-welfare')) {
    imageSrc = al_mustafa
  } else if (charityID.startsWith('muslim-aid')) {
    imageSrc = muslim_aid
  } else if (charityID.startsWith('orphans-in-need')) {
    imageSrc = orphan_in_need
  } else if (charityID.startsWith('multi-charity')) {
    imageSrc = ''
  }
  return <>
    {logoExist &&
      <div className='overflow-hidden h-14 w-14 md:h-32 md:w-32 shadow rounded-full bg-white'>
        <img className="h-14 w-14  md:h-32 md:w-32 object-contain " src={imageSrc} alt={charityID} onError={() => setLogoExist(false)}></img>
      </div>
    }
    {!logoExist &&
      <>
        <div className='bg-gm-green h-12 w-12 flex md:hidden justify-center items-center shadow rounded-md'>
          <img src={angleRightWhite} alt={charityID} className='h-6' />
        </div>
        <div className='bg-gm-green h-12 w-12 md:flex justify-center items-center hidden'>
          <Button variant={'gm-primary'} className='shadow rounded-md'> Select</Button>
        </div></>
    }
  </>
}

const CharityCardsRamadan: FC<{ charities: CharityList[] }> = ({ charities }) => {
  const navigate = useNavigate()
  const { setCharity, donation } = useDonationPlan()
  const onContinue = async (charityID: string): Promise<void> => {
    const charity = await getCharity(charityID)
    setCharity(charity)
    Tracking.selectCharity(charity, donation.amount)
    navigate(Navigation.ramadanSelectCauses())
  }

  const items = charities.map((charity) => <ErrorBoundary key={charity.charityID} component={<></>}>
    <RamadanCharityListView charity={charity} onCharitySelect={async () => onContinue(charity.charityID)} />
  </ErrorBoundary>
  )

  return <div className="flex flex-wrap justify-center mx-auto w-full">
    {charities.length === 0 &&
      <p className="mt-8 font-bold text-gm-red">There are currently no charities accepting donations in your selected country or matching search</p>}
    <div className='flex md:flex-row md:flex-wrap justify-center flex-col w-full gap-x-4'>

      {items}
    </div>

  </div>
}

export default CharityCardsRamadan
