import type { FC } from 'react'

const VerticalGradient: FC = () => {
  return (
    <div
      className="w-full h-16 mix-blend-multiply"
      style={{ background: 'linear-gradient(180deg, #EAF8F1 0%, #FFF 100%)' }}
    />
  )
}

export default VerticalGradient
