import { type FC } from 'react'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import back from '~/assets/arrow-left.svg'
import { useNavigate } from 'react-router-dom'

export const FundraiserLayout: FC<{ title: string }> = ({ children, title }) => {
  const navigate = useNavigate()
  return <div className="min-h-screen w-full flex flex-col md:items-center">
    <Header />
    <div className='flex flex-col mx-5 pb-10 max-w-lg md:w-1/2'>
      <div className='flex items-center gap-x-2 hover:underline cursor-pointer font-xs mt-2' onClick={() => navigate(-1)}>
        <img src={back} className="w-4 h-4" alt='back'/>
        <span className='underline'>Back</span>

      </div>
      <span className='text-2xl font-bold py-2'>{title}</span>
      {children}
    </div>
    <Footer />
  </div>
}
