import md5 from 'md5'
import { uploadImageBase64 } from '@lib/components'
import { authStorageKey, StorageEvents, type User } from '@lib/hooks'
import { type Listener, localStorage } from '@lib/services'
import {
  createFundraiserDraft,
  type FundraiserDraft,
  type FundraiserDraftCreateOrUpdateResponse, getFundraiserDraft,
  updateDraftFundraiser
} from '~/state/fundraiser'
import { SessionStorage } from '~/service/sessionStorage'
import { type editDraftFundRaiserData } from '~/data/donationFormData'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'
import { RecaptchaV3 } from '@lib/services'

const recaptchaFundraiserDraftCreate = new RecaptchaV3('fundraiser_draft_create')
const recaptchaFundraiserDraftUpdate = new RecaptchaV3('fundraiser_draft_update')
export const recaptchaFundraiserDraftPublish = new RecaptchaV3('fundraiser_draft_publish')

const draftToPlan = (draft: FundraiserDraft): FundraiserPlan => ({
  country: draft.country,
  postcode: draft.postcode,
  charity: {
    charityID: draft.charity_id,
    name: draft.charity_name,
    currency: draft.target_currency,
    causes: {},
    country: '',
    weight: 0,
    charityApi: '',
    coverImage: '',
    logoImage: '',
    status: ''
  },
  target_amount: draft.target_amount,
  title: draft.title,
  story: draft.story,
  youtubeUrl: draft.youtube_url,
  charity_opt_in: draft.charity_opt_in,
  coverPhoto: '',
  new_image_upload: false,
  teamMembers: draft.team_members,
  draft_updated_time: draft.draft_updated_time
})

const getDraftUpdatePayload = (fundraiserDraftId: string, plan: FundraiserPlan): editDraftFundRaiserData => ({
  fundraiser_id: fundraiserDraftId,
  country: plan.country,
  postcode: plan.postcode,
  charity_id: plan.charity.charityID,
  charity_name: plan.charity.name,
  target_amount: plan.target_amount,
  target_currency: plan.charity.currency,
  title: plan.title,
  story: plan.story,
  youtube_url: plan.youtubeUrl,
  charity_opt_in: plan.charity_opt_in,
  new_image_upload: plan.new_image_upload,
  team_members: plan.teamMembers
})

const _initializeFundraiserDraft = async (user: User | null): Promise<void> => {
  if (user) {
    let resolvedDraft: FundraiserDraft

    // User logged in - fetch remote fundraiser draft
    const drafts: FundraiserDraft[] = await getFundraiserDraft()
    if (drafts.length === 1) {
      const remoteDraft: FundraiserDraft = drafts[0]

      // Do we have a local fundraiser draft?
      const fundraiserDraftId: string = sessionStorage.getItem(SessionStorage.fundraiser_draft_id_created) ?? ''
      if (fundraiserDraftId === '') {
        // No, save the remote draft in sessionStorage
        // This will trigger a redundant remote draft update, but it's fine
        // console.log('[initializeFundraiserDraft] No local draft fundraiser present. Storing remote draft as local draft.')
        resolvedDraft = remoteDraft
      } else {
        // Decide between local & remote drafts

        // Compare fundraiser IDs; if they differ, discard local one & use remote
        // (perhaps local draft was already published from a different device)
        if (fundraiserDraftId !== remoteDraft.fundraiser_id) {
          // console.log('[initializeFundraiserDraft] Local & remote draft fundraisers present. IDs are NOT equal. Storing remote draft as local draft.')
          resolvedDraft = remoteDraft
        } else {
          // We need to check which draft has the freshest data
          const localDraft: FundraiserDraft = JSON.parse(sessionStorage.getItem(SessionStorage.fundraiserData)!)

          let latestVersion: FundraiserDraft
          // let latestVersionName: string
          if (localDraft.draft_updated_time === '') {
            latestVersion = remoteDraft
            // latestVersionName = 'REMOTE'
          } else {
            const localDraftDate: Date = new Date(localDraft.draft_updated_time)
            const remoteDraftDate: Date = new Date(remoteDraft.draft_updated_time)
            latestVersion = localDraftDate > remoteDraftDate ? localDraft : remoteDraft
            // latestVersionName = localDraftDate > remoteDraftDate ? 'LOCAL' : 'REMOTE'
          }

          // console.log(`[initializeFundraiserDraft] Local & remote draft fundraisers present. IDs are equal. Storing the freshest data from ${latestVersionName} draft.`)
          resolvedDraft = latestVersion
        }
      }

      // Create a fundraiser plan from the resolved draft
      const plan: FundraiserPlan = draftToPlan(resolvedDraft)

      // Persist plan to session storage
      const fundraiserData: string = JSON.stringify(plan)
      sessionStorage.setItem(SessionStorage.fundraiserData, fundraiserData)
      sessionStorage.setItem(SessionStorage.fundraiser_draft_md5, md5(fundraiserData))
      sessionStorage.setItem(SessionStorage.fundraiser_draft_id_created, resolvedDraft.fundraiser_id)
    } else {
      // No remote fundraiser draft; clear the fundraiserDraftID, to prevent an update failure;
      // Creating a new draft fundraiser (on the server) is done via the SessionStorage listener,
      // as soon as the user enters some data (in the local draft fundraiser).
      // console.log('[initializeFundraiserDraft] No remote draft fundraiser found on server')
      sessionStorage.removeItem(SessionStorage.fundraiser_draft_id_created)
    }
  } else {
    // User logged out - we don't need to do anything here
    // console.log('[initializeFundraiserDraft] User not logged in')
  }
}

export const initializeFundraiserDraft = (user: User | null): void => {
  // Fire & forget, don't await the promise
  void _initializeFundraiserDraft(user)
}

export const handleFundraiserDataStorageUpdate: (listenerId: string, debugPrint: boolean) => Listener =
    (listenerId: string, debugPrint: boolean) => async ({ storageKey, storedValue, setFn }): Promise<void> => {
      debugPrint && console.group(`[SessionStorage-LISTENER][${StorageEvents.SET}][${SessionStorage.fundraiserData}][${listenerId}]`)

      // Exit early if stored value is null or undefined
      if (!storedValue) {
        debugPrint && console.log('Stored value is undefined or null, no further action taken')
        debugPrint && console.groupEnd()
        return
      }

      // If the user is not authenticated, we can't save the draft on the backend
      const user: string | null = localStorage.get(authStorageKey)
      if (!user) {
        debugPrint && console.log('User not logged in, no further action taken')
        debugPrint && console.groupEnd()
        return
      }

      // Parse stored value & create its MD5
      const updatedFundraiser: FundraiserPlan = JSON.parse(storedValue)
      const planNewMD5: string = md5(storedValue)

      // Do we already have a draft created?
      const fundraiserDraftId: string = sessionStorage.getItem(SessionStorage.fundraiser_draft_id_created) ?? ''

      let photoNewMD5: string = ''
      let fundraiserDraftResponse: FundraiserDraftCreateOrUpdateResponse | undefined
      if (fundraiserDraftId === '') {
        // No, create a new one & save its ID in SessionStorage
        debugPrint && console.log('Create draft fundraiser', updatedFundraiser)
        fundraiserDraftResponse = await createFundraiserDraft(
          updatedFundraiser,
          await recaptchaFundraiserDraftCreate.getToken()
        )
        sessionStorage.setItem(SessionStorage.fundraiser_draft_id_created, fundraiserDraftResponse.fundraiser_id)
      } else {
        // Update existing plan, only if it actually changed
        const planOldMD5: string = sessionStorage.getItem(SessionStorage.fundraiser_draft_md5) ?? ''
        if (planOldMD5 === '' || planOldMD5 !== planNewMD5) {
          try {
            // Generate upload link for the image, if it's a new one
            if (updatedFundraiser.coverPhoto && updatedFundraiser.coverPhoto !== '') {
              const photoOldMD5: string = sessionStorage.getItem(SessionStorage.draftCoverPhotoMD5) ?? ''
              photoNewMD5 = md5(updatedFundraiser.coverPhoto)
              if (photoOldMD5 === '' || photoOldMD5 !== photoNewMD5) {
                updatedFundraiser.new_image_upload = true
              }
            }

            // Update draft
            debugPrint && console.log('Update remote draft fundraiser', updatedFundraiser)
            debugPrint && console.groupEnd()
            const editDraftFundRaiser: editDraftFundRaiserData = getDraftUpdatePayload(fundraiserDraftId, updatedFundraiser)
            fundraiserDraftResponse = await updateDraftFundraiser(
              editDraftFundRaiser,
              await recaptchaFundraiserDraftUpdate.getToken()
            )
          } catch (err) {
            // Even though the `user` is present in LocalStorage,
            // `ProvideAuth.onSetUser (initializeFundraiserDraft) in <App>` is triggered
            // at a later time than storage update listeners.
            // This means that we could have a stale local draft (that was already published from
            // another device), that hasn't been updated against the remote draft yet (which is
            // loaded in `initializeFundraiserDraft`).
            // This is ok, because by the time the next update is triggered, the local draft would
            // have already (most likely) updated against the remote draft.
            debugPrint && console.warn('Failed to update draft fundraiser', (err as Error).message)
            return
          }
        } else {
          debugPrint && console.log('Draft data hasn\'t changed; Skip updating remote draft fundraiser')
        }
      }

      // Actions performed if we created or updated a remote draft
      if (fundraiserDraftResponse) {
        // Store the new MD5
        sessionStorage.setItem(SessionStorage.fundraiser_draft_md5, planNewMD5)

        // Store the time the draft was created/updated
        updatedFundraiser.draft_updated_time = fundraiserDraftResponse.draft_updated_time
        if (setFn && typeof setFn === 'function') {
          // `setFn` updates the storage without triggering the listeners (avoid an infinite loop)
          setFn(storageKey, JSON.stringify(updatedFundraiser))
        } else {
          // Should not get here!
          console.error(new Error('`setFn` not set; can\'t set `draft_updated_time` on local draft'))
        }

        // Upload the image if it's a new one
        if (updatedFundraiser.new_image_upload) {
          if (fundraiserDraftResponse.uploadUrl === '') {
            console.warn('Need to upload cover photo, but no upload url returned from server')
            return
          }

          if (!(updatedFundraiser.coverPhoto && updatedFundraiser.coverPhoto !== '' && photoNewMD5 !== '')) {
            console.warn('Need to upload cover photo, but no picture data available')
            return
          }

          // All good, upload
          await uploadImageBase64(updatedFundraiser.coverPhoto, fundraiserDraftResponse.uploadUrl, 'fundraiserImage')
          sessionStorage.setItem(SessionStorage.draftCoverPhotoMD5, photoNewMD5)
          updatedFundraiser.new_image_upload = false
          console.log('image uploaded')
        }
      }
    }
