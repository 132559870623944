import { type FC } from 'react'
import { useMediaQuery } from 'react-responsive'

import { type EnrichedMetricEntry, type LeaderboardDetails, friendlyValueDescription } from '@givematch/common'
import { getPrizeImg } from '~/utils/rankings'
import { ContainerLayout } from '~/components/ContainerLayout'
import VerticalGradient from '~/components/leaderboard/VerticalGradient'

interface OurLeaderboardsSectionProps {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
  leaderboard: LeaderboardDetails
}

const OurLeaderboardsSection: FC<OurLeaderboardsSectionProps> = ({ leaderboard, setSelectedTab }) => {
  // https://tailwindcss.com/docs/responsive-design
  const isMD = useMediaQuery({ query: '(min-width: 768px)' })
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' })

  const { metricsTracked, reset } = leaderboard.info
  const metricEntry: EnrichedMetricEntry = leaderboard.metrics as EnrichedMetricEntry

  return (
    <div className="w-full">
      {!isLG && <VerticalGradient/>}

      <ContainerLayout
        className="px-8 lg:px-40 flex flex-col items-center"
        style={{ gap: isXL ? '2.125rem' : '1rem' }}
      >
        {/* Title */}
        <h3
          className="text-center text-gm-newgreen font-bold"
          style={{
            fontSize: isMD ? '1.875rem' : '1.5rem',
            lineHeight: '110%',
            letterSpacing: isMD ? '-0.0375rem' : undefined
          }}
        >
          Our Leaderboards
        </h3>

        {/* Metrics */}
        <div
          className="md:px-20 pt-4 flex flex-col xl:flex-row flex-start"
          style={{ gap: isXL ? '6.1875rem' : '1.5rem' }}
        >
          {metricsTracked.map((m, mId) => {
            const metric = m.name
            return (
              <div
                key={`${mId + 1}-${metric}`}
                className="flex flex-row xl:flex-col lg:items-center text-gm-black-100"
                style={{ gap: isXL ? '0.5rem' : '0.9375rem' }}
              >
                {/* Prize Image */}
                <img
                  loading="lazy" src={getPrizeImg(metric)} alt="Prize Rank"
                  style={{ width: '2.9375rem', height: '3.66438rem' }}
                />

                {/* Text */}
                <div className="flex flex-col xl:text-center font-bold">
                  {/* Prize Title */}
                  <h4 style={{ fontSize: '1.5rem', lineHeight: '1.875rem' }}>
                    {metricEntry[metric].leaderboardTitle}
                  </h4>

                  {/* Prize Info */}
                  <p
                    className="mt-1 font-normal"
                    style={{ fontSize: '1.125rem', lineHeight: '1.875rem' }}
                  >
                    {friendlyValueDescription(metric, reset)}
                  </p>

                  {/* Leaderboard Link  */}
                  <a
                    href={isLG ? `#leaderboard_${metric}` : '#leaderboards'}
                    onClick={() => setSelectedTab(mId)}
                    className="mt-2 text-gm-newgreen font-bold"
                    style={{
                      fontSize: isLG ? '1.125rem' : '0.875rem',
                      lineHeight: isLG ? '1.875rem' : '120%'
                    }}
                  >
                    <span className="underline">View leaderboard</span>
                    <span className="font-normal"> &gt;</span>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </ContainerLayout>
    </div>
  )
}

export default OurLeaderboardsSection
