import { type FC, useState } from 'react'
import { useAuth } from '@lib/hooks'
import Login from '~/pages/Login'
import { CreateAccount } from '~/pages/CreateAccount'
import { VerifyAccount } from '~/pages/VerifyAccount'

const AuthenticatedRoute: FC<{ loginPage?: boolean }> = ({ children, loginPage }) => {
  const { user } = useAuth()
  const [signIn, setSignIn] = useState(loginPage ?? false)
  const [verifyAccount, setVerifyAccount] = useState(false)
  const [email, setEmail] = useState('')
  return <>
    {user !== null && children}
    {user === null && signIn && <Login setSignIn={setSignIn}/>}
    {user === null && !signIn && !verifyAccount && <CreateAccount setSignIn={setSignIn} setVerifyAccount={setVerifyAccount} setEmail={setEmail}/>}
    {user === null && !signIn && verifyAccount && <VerifyAccount email={email} emailUnEditable/>}
  </>
}

export default AuthenticatedRoute
