// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const hasActiveGivematchCampaign = () => {
  return true
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const contactEmail = () => {
  return 'contact@givematch.com'
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const productName = () => {
  return 'Givematch'
}
