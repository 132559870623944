import { createContext, type FC, useContext, useEffect, useState } from 'react'
import { type CanMakePaymentResult, type PaymentRequest, type PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { useDonationPlan } from '../hooks/useDonationPlan'

interface PaymentRequestContext {
  paymentRequest: PaymentRequest | null
  setPaymentRequest: (p: PaymentRequest) => void
  isLoading: boolean
  paymentCapabilities: PaymentCapabilities | null
}

export interface PaymentCapabilities {
  applePay: boolean
  googlePay: boolean
}

const paymentRequestContext = createContext<PaymentRequestContext>({
  paymentRequest: null,
  setPaymentRequest: (p) => console.log(p),
  isLoading: false,
  paymentCapabilities: null
})

export const PaymentRequestProvider: FC<{ label: string }> = ({ children, label }) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult | null>(null)
  const { donation } = useDonationPlan()

  useEffect(() => {
    (async (): Promise<void> => {
      if (stripe === null || paymentRequest !== null) {
        return
      }
      setLoading(true)
      // We need to initialize stripe with respective country, because payment will be processed through DAF account in that country
      const pr = stripe.paymentRequest({
        country: donation.charity.country.toUpperCase(),
        currency: donation.donationCurrency,
        total: {
          label,
          amount: 0
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          {
            label: 'Digital Shipping',
            amount: 0,
            id: 'test_id',
            detail: 'We use your selected address to generate tax receipts for you'
          }
        ]
      })

      const canMakePaymentResponse = await pr.canMakePayment()
      setCanMakePayment(canMakePaymentResponse)
      setLoading(false)
      if (canMakePaymentResponse) {
        setPaymentRequest(pr)
      }
    })().catch((e) => {
      console.error(e)
      setLoading(false)
    })
  }, [stripe, setPaymentRequest, paymentRequest, label, donation.charity.country, donation.donationCurrency])

  return <paymentRequestContext.Provider
    value={{ paymentRequest, setPaymentRequest, isLoading, paymentCapabilities: canMakePayment as any as PaymentCapabilities }}>{children}</paymentRequestContext.Provider>
}

export const usePaymentRequest = (cancel?: () => void, completePayment?: (p: PaymentRequestPaymentMethodEvent) => Promise<void>): PaymentRequestContext => {
  const { paymentRequest, ...rest } = useContext(paymentRequestContext)

  useEffect(() => {
    paymentRequest?.on('cancel', () => cancel?.())
    if (completePayment) {
      paymentRequest?.on('paymentmethod', completePayment)
    }

    return () => {
      paymentRequest?.off('cancel', () => cancel?.())
      if (completePayment) {
        paymentRequest?.off('paymentmethod', completePayment)
      }
    }
  }, [cancel, completePayment, paymentRequest])

  return { paymentRequest, ...rest }
}
