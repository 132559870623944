import { type FC } from 'react'
import { Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const CorporatePartner: FC = () => {
  return <div className="flex flex-col min-h-screen">
        <Header />

        <Page skinny className="flex-1 px-4 mb-8 text-justify">
            <h1 className="mt-12 text-4xl font-semibold tracking-tighter text-center text-gm-green">Why your corporation should match fund on Givematch</h1>
            <p className="mt-8">
                Corporations should be interested in match funding on Givematch because it offers a unique opportunity to maximize the impact of their corporate social responsibility (CSR) initiatives. Givematch's platform incentivizes donors to not only make a donation themselves, but also to share and convince their friends to donate as well. This creates a ripple effect that can result in a much larger pool of donations than what would have been possible through traditional match funding platforms.
            </p>
            <p className='mt-4'>
                By participating in match funding on Givematch, corporations can not only amplify the impact of their CSR efforts, but also engage their employees and customers in the process, creating a sense of community and shared purpose. Furthermore, the ability to track and measure the impact of their contributions and the resulting ripple effect can provide valuable insights and data for future CSR strategies.
            </p>
            <p className='mt-8 font-semibold italic'> If your corporation is interested in learning more about how your corporation could benefit from Givematch’s services, please email us at <a className="underline text-gm-green" href="mailto:partners@givematch.com">partners@givematch.com</a>.  </p>
        </Page>
        <Footer />
    </div>
}

export default CorporatePartner
