import { type FC, useEffect } from 'react'
import CauseCards from '../components/CauseCards'
import Header from '~/components/Header'
import { Tracking } from '../service/tracking'
import Footer from '~/components/Footer'
import { useDonationPlan } from '~/hooks/useDonationPlan'

const Causes: FC = () => {
  const { donation } = useDonationPlan()
  // ToDo: No need of getting charity as charity is already fetched. If charity is different from what is in useDonationPlan tehn redirect to home or charity landing page

  useEffect(() => {
    if (donation.charity) { Tracking.selectCharity(donation.charity, donation.amount) }
  }, [donation.charity, donation.amount])

  return <div className="flex flex-col text-center causePage">
    <Header />
    <section className="px-4 mt-6">
      <h1 className="text-4xl font-semibold tracking-tighter">Select your cause</h1>

      {!donation.charity.causes && <p className="mt-8 font-bold text-gm-red">
        We were unable to load any causes
      </p>}

      { donation.charity.causes.length > 0 && <CauseCards />}
    </section>
    <Footer />
  </div>
}

export default Causes
