import { type FC, type ReactElement } from 'react'

import { type Currency, displayCurrency } from '@lib/services'
import { type Cause, getCauseName } from '../state/charity'
import { calculateCauseTotal, calculateFee, GivematchTestId, isCause, isFundraiserCharity } from '@givematch/common'

import style from '../css/PaymentBreakdown.module.css'
import { PaymentFormTestId } from '@lib/testing'
import { useDonationPlan } from '~/hooks/useDonationPlan'

const PaymentBreakdownRow: FC<{ testId?: string, fieldName: string, value: string }> = ({ testId, fieldName, value }) => {
  return (
    <div className="flex items-center justify-between">
      <p>{fieldName}</p>
      <p data-test-id={testId ?? ''} className="text-right">{value}</p>
    </div>
  )
}

interface props {
  totalAmount: number
  transactionFee: number
  currency: Currency
  updatePayFees: () => void
  payFees: boolean
  platformTip: number
  causes: Cause[]
}

const PaymentBreakdown: FC<props> = ({ currency, updatePayFees, payFees, platformTip, causes }) => {
  const { donation } = useDonationPlan()
  const getCauseAmountRow = (): ReactElement => {
    if (isCause(donation.causeType)) {
      const causeAmountRows = Object.entries(donation.causeAmounts).filter(([_, amount]) => amount > 0).map(([causeId, amount]) =>
        <PaymentBreakdownRow key={causeId} fieldName={getCauseName(causes, causeId)} value={displayCurrency({ amount, currency })}></PaymentBreakdownRow>)
      return <>{causeAmountRows}</>
    }
    let key: string
    let fieldName: string
    if (isFundraiserCharity(donation.causeType)) {
      key = donation.fundraiserID
      fieldName = donation.fundraiserTitle
    } else {
      key = donation.charity.charity
      fieldName = donation.charity.name
    }
    return <PaymentBreakdownRow key={key} fieldName={fieldName} value={displayCurrency({ amount: donation.amount, currency })}/>
  }

  const causeTotalAmount = isCause(donation.causeType) ? calculateCauseTotal(donation.causeAmounts) : donation.amount
  const transactionFee = calculateFee(causeTotalAmount + platformTip, currency)
  const total = payFees ? causeTotalAmount + platformTip + transactionFee : causeTotalAmount + platformTip

  return <div className={style.paymentCard}>
    <div className="cardBody text-sm">
      {getCauseAmountRow()}
      <PaymentBreakdownRow testId={PaymentFormTestId.tipAmount} fieldName="Givematch tip" value={displayCurrency({ amount: platformTip, currency })} />

      <div className="flex justify-between">
        <div className="column">
          <div>Card processing fee</div>
        </div>
        <div className="column">
          <p data-test-id={GivematchTestId.feeAmount} className="text-right">{payFees ? displayCurrency({
            amount: transactionFee,
            currency
          }) : displayCurrency({ amount: 0, currency })}</p>
          <div
            data-test-id={GivematchTestId.payFeesButton}
            className="text-xs text-right underline cursor-pointer text-gm-violet"
            onClick={updatePayFees}>
            {payFees ? 'Remove' : 'Add'}
          </div>
        </div>
      </div>

      <div className={`text-2xl text-gm-green my-2 font-semibold ${style.totalRow}`}>
        <div>TOTAL:</div>
        <div data-test-id={GivematchTestId.totalAmount} className={[style.heading, style.rightText].join(' ')}>{displayCurrency({
          amount: total,
          currency
        })}</div>
      </div>
    </div>

  </div>
}

export default PaymentBreakdown
