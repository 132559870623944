import { type FC } from 'react'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCharity } from '~/state/charity'
import { Spinner } from '@lib/components'
import Header from '~/components/Header'
import { CharityFundraiser } from '~/components/CharityFundraiser'
import Footer from '~/components/Footer'

export const CharityFundraiseLanding: FC = () => {
  const { setFundraiser, fundraiser } = useFundraiserPlan()
  const { charityID } = useParams() as { charityID: string }

  const { isLoading: charityLoading, isError: charityError, data: charity } = useQuery(['charity', charityID], async () => getCharity(charityID), {
    onSuccess: (charity) => {
      setFundraiser({
        ...fundraiser,
        charity: {
          charityID: charity.charity,
          name: charity.name,
          coverImage: charity.logo_image,
          country: charity.country,
          currency: charity.currency,
          causes: charity.causes.reduce((obj, item) => {
            return {
              ...obj,
              [item.causeID]: item
            }
          }, {}),
          charityApi: '',
          weight: 0,
          logoImage: charity.logo_image,
          status: ''
        }
      })
    }
  })

  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    { charityLoading && <Spinner>Loading</Spinner>}
    {!charityLoading && charityError && <>Error while loading charity</>}
    {!charityLoading && !charityError && charity && <CharityFundraiser charity={charity}/>}
    <Footer/>
  </div>
}
