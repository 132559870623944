import { type FC, useState } from 'react'
import infoTooltip from '~/assets/icons/info-tool-tip.svg'
export const ToolTip: FC = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return <>
    <div
      tabIndex={0}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
      className="inline-flex items-center justify-center  ml-2 cursor-pointer focus:outline-none"
    >
      <img src={infoTooltip} className='w-3 h-3' alt='info tool tip'/>
    </div>
    {showTooltip && <div className='absolute p-3 text-xs bg-white font-medium bottom-full border bg-white border-gm-green-300 rounded-lg shadow-md'>
      {children}
    </div>}
  </>
}
