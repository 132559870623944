import { LandingPageType } from '@givematch/common'
import { type FC } from 'react'
import line from '~/assets/line.svg'

const StepsExplainer: FC<{ landingPage?: string }> = ({ landingPage }) => {
  const headlineColor = 'text-gm-green'
  let headline = 'Earn a match to double your donation'
  if (landingPage && landingPage === LandingPageType.Ramadan) {
    headline = 'Earn a match to double your donation.'
  }
  const isRamadanLanding = landingPage === LandingPageType.Ramadan
  const isCharityLandingPage = landingPage === LandingPageType.CharityLandingPage
  return <section className={`px-8 sm:px-20 ${isCharityLandingPage ? 'md:px-10' : 'md:px-24'} lg:px-40 bg-white text-left ${isRamadanLanding ? '' : 'my-10'}`}>
    <h1 className={`text-3xl tracking-tighter  font-semibold ${headlineColor}`}>
      {isCharityLandingPage ? '' : headline}
    </h1>
    <div className={`flex flex-col md:flex-col lg:flex-row lg:justify-evenly gap-y-3 ${isCharityLandingPage ? 'lg:gap-x-10' : 'md:gap-x-4'} my-5`}>
      <div className={`${isCharityLandingPage ? 'md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2' : 'flex md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2'}`}>
        <div className='flex-col md:flex-col'>
          <div className={`w-8 h-8 flex ${isCharityLandingPage ? 'text-4xl md:text-5xl font-bold steps-expaliner-gray' : 'bg-gm-yellow rounded-full justify-center items-center'}`}><span>{isCharityLandingPage ? '01' : '1'}</span>
          </div>
          <img className={`${isCharityLandingPage ? 'hidden' : 'w-12 h-12 relative right-0 md:hidden'}`} src={line} alt="line" />
        </div>
        <div className="">
          <h4 className={`text-gm-green tracking-tighter ${isCharityLandingPage ? 'font-bold my-3 md:text-3xl text-2xl md:my-10' : 'font-semibold text-lg'}`}>Donate to any charity</h4>
          <p className={`${isCharityLandingPage ? 'text-xl my-5' : 'text-xs'}`}>Support a charity and cause of your choice via Givematch.</p>
        </div>
      </div>
      <div className={`${isCharityLandingPage ? 'md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2' : 'flex md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2'}`}>
        <div className='flex-col md:flex-col'>
          <div className={`w-8 h-8 flex ${isCharityLandingPage ? 'text-4xl md:text-5xl font-bold steps-expaliner-gray' : 'bg-gm-yellow rounded-full justify-center items-center'}`}><span>{isCharityLandingPage ? '02' : '2'}</span>
          </div>
          <img className={`${isCharityLandingPage ? 'hidden' : 'w-12 h-12 relative right-0 md:hidden'}`} src={line} alt="line" />
        </div>
        <div className="">
          <h4 className={`text-gm-green tracking-tighter ${isCharityLandingPage ? 'font-bold my-3 md:text-3xl text-2xl md:my-10' : 'font-semibold  md:text-base'}`}>Share your personal link</h4>
          <p className={`${isCharityLandingPage ? 'text-xl my-5' : 'text-xs'}`}>You will then receive your very own personal link to share with friends to donate as well.</p>
        </div>
      </div>
      <div className={`${isCharityLandingPage ? 'md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2' : 'flex md:flex-col flex-row gap-x-4 md:w-full md:gap-y-2'}`}>
        <div className='flex md:flex-col'>
          <div className={`w-8 h-8 flex ${isCharityLandingPage ? 'text-4xl md:text-5xl font-bold steps-expaliner-gray' : 'bg-gm-yellow rounded-full justify-center items-center'}`}><span>{isCharityLandingPage ? '03' : '3'}</span>
          </div>
        </div>
        <div className="">
          <h4 className={`text-gm-green tracking-tighter ${isCharityLandingPage ? 'font-bold my-3 md:text-3xl text-2xl md:my-10' : 'font-semibold text-lg'}`}>Double your donation</h4>
          <p className={`${isCharityLandingPage ? 'text-xl my-5' : 'text-xs'}`}>When 2 friends give using your Givematch link, you’ll earn a match - doubling your donation.</p>
        </div>
      </div>
    </div>
  </section>
}

export default StepsExplainer
