export enum GivematchTestId {

  chooseCauseButton = 'chooseCauseButton',
  startFundraiserButton = 'startFundraiserButton',
  donateButton = 'donateButton',
  donationInput = 'donationInput',
  widgetShareLink = 'widgetShareLink',
  countrySelect = 'countrySelect',
  cookieBannerAcceptAll = 'cookieBannerAcceptAll',
  shareLinkCopy = 'shareLinkCopy',
  charityCard_ = 'charityCard_',
  charityCardDonateButton_ = 'charityCardDonateButton_',
  causeCard_ = 'causeCard_',
  causeCardInput_ = 'causeCardInput_',
  causeCardEnterButton_ = 'causeCardEnterButton_',
  causesContinueButton = 'causesContinueButton',
  causesBackButton = 'causesBackButton',
  feeAmount = 'feeAmount',
  payFeesButton = 'payFeesButton',
  totalAmount = 'totalAmount',
  thankYouPage = 'thankYouPage',
  paymentBackButton = 'paymentBackButton',
  paymentConfirmButton = 'paymentConfirmButton',
  explainerGotItCheckBox = 'explainerGotItCheckBox',
  explainerContinueButton = 'explainerContinueButton',
  continueButton = 'continueButton',

}

export enum CustomerServiceTestId {
  searchPage = 'searchPage',
  searchInput = 'searchInput',
  searchButton = 'searchButton',
  donationCard = 'donationCard',
}
