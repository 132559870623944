import { type DefaultVariants } from '@lib/services'

export enum GivematchNativeVariants {
  heyFriend = 'heyFriend',
  givesMore = 'givesMore',
  doubleReward = 'doubleReward',
  forFree = 'forFree',
  time = 'time',
  canDouble = 'canDouble'
}

export enum ActiveExperiments {
  GivematchNative = 'GivematchNative',
  RamadanHomeHeadline = 'RamadanHomeHeadline',
  ExplainerPageRemoved = 'ExplainerPageRemoved',
  ShareButtonText = 'ShareButtonText'
}

export enum RamadanHomeHeadlineVariant {
  youGiveWeMatch = 'youGiveWeMatch',
  doubleYourReward = 'doubleYourReward',
  doubleYourRamadanReward = 'doubleYourRamadanReward'

}

export enum ExplainerPageRemovedVariants {
  removed = 'removed',
  present = 'present'
}

export enum ShareButtonTextVariants {
  shareLink = 'shareLink',
  inviteFriends = 'inviteFriends',
}

export const defaultVariants: DefaultVariants = {
  GivematchNative: {
    [GivematchNativeVariants.heyFriend]: 100,
    [GivematchNativeVariants.givesMore]: 100,
    [GivematchNativeVariants.doubleReward]: 100,
    [GivematchNativeVariants.forFree]: 100,
    [GivematchNativeVariants.time]: 100,
    [GivematchNativeVariants.canDouble]: 100
  },
  RamadanHomeHeadline: {
    [RamadanHomeHeadlineVariant.youGiveWeMatch]: 100,
    [RamadanHomeHeadlineVariant.doubleYourReward]: 100,
    [RamadanHomeHeadlineVariant.doubleYourRamadanReward]: 100
  },
  ExplainerPageRemoved: {
    [ExplainerPageRemovedVariants.present]: 100,
    [ExplainerPageRemovedVariants.removed]: 100
  },
  ShareButtonText: {
    [ShareButtonTextVariants.shareLink]: 100,
    [ShareButtonTextVariants.inviteFriends]: 100
  }
}
