import { type FC, useEffect } from 'react'

import '~/css/home.css'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { SessionStorage } from '~/service/sessionStorage'
import Header from '~/components/Header'
import StepsExplainer from '~/components/StepsExplainer'
import Footer from '~/components/Footer'
import { Tracking } from '~/service/tracking'
import { useLocation } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { HeadlineWithDonate } from '~/components/HeadlineWithDonate'
import { LandingPageType } from '@givematch/common'
import { FeaturedOnMedia } from '~/components/FeaturedOnMedia'

export const RamadanHome: FC = () => {
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { donation, setDonation } = useDonationPlan()
  useEffect(() => {
    const ClearExistingData = (): void => {
      setDonation({ ...donation, chainCode: '' })
      // clear any shared charity from storage
      sessionStorage.removeItem(SessionStorage.referrerCharityKey)
      sessionStorage.removeItem(SessionStorage.partnerCharityKey)
      sessionStorage.removeItem(SessionStorage.currentFundraiser)
    }
    ClearExistingData()
    sessionStorage.setItem(SessionStorage.campaignName, Tracking.getRamadanCampaignName())
    Tracking.pageViewedWithTracking(pathname, user?.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div className="lg:min-h-screen w-full flex flex-col bg-white">
    <Header className='bg-white'/>
    <HeadlineWithDonate />
    <StepsExplainer landingPage={LandingPageType.Ramadan}/>
    <FeaturedOnMedia />
    <Footer />
  </div>
}
