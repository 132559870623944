import { type FC } from 'react'
import { type Charity, type EventDonation } from '~/state/charity'
import profile from '~/assets/icons/profile.svg'
import profileDark from '~/assets/icons/profile-dark-green.svg'
import warning from '~/assets/warning.svg'
import moment from 'moment'
import DonationCard from '~/components/DonationCard'
import AboutGivematch from '~/components/AboutGivematch'
import { Button } from '@lib/components'
import { type EventBase, type Organiser } from '~/state/fundraiser'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { GivematchTestId } from '@givematch/common'
import { compactNumber, displayTidyCurrency, GetCurrencyByString } from '@lib/services'
import { YoutubeEmbed } from '~/components/YoutubeEmbed'
import { FundRaiserImage } from './FundRaiser/FundRaiserCard'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { ToolTip } from '~/components/ToolTip'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Editor } from './FundRaiser/Editor'
import '~/css/editor.css'

interface Props {
  event: EventBase
  onDonateButtonClick: (amount: number) => void
  eventID: string
  charity: Charity
  isPreview: boolean
}

export const DonorCard: FC<{ donation: EventDonation }> = ({ donation }) => {
  const donationAmount = Object.values(donation.cause_donations).reduce((acc, value) => acc + value, 0)
  return <div key={`${donation.first_name}${donation.last_name}`} className="flex mt-5">
    <img src={profile} className="mr-3" alt='profile' />
    <div className="flex flex-col">
      <div>{donation.anonymous ? 'Anonymous' : `${donation.first_name} ${donation.last_name}`}</div>
      <div><strong>{displayTidyCurrency(donationAmount, GetCurrencyByString(donation.currency), true)}</strong> <span className="text-bluegray-300">- {moment(donation.created_time).fromNow()}</span></div>
    </div>
  </div>
}

const FundraiserProgressDetails: FC<{ fundraiser: EventBase }> = ({ fundraiser }) => {
  const { donation } = useDonationPlan()
  const givematchAddedTotal = fundraiser.referral_donation_amount + fundraiser.matched_amount
  const raisedTotal = fundraiser.non_referral_donation_amount + givematchAddedTotal
  const percentageRaised = Math.floor((100 * raisedTotal) / fundraiser.target_amount)
  const youRaisedPercentage = Math.floor((100 * fundraiser.non_referral_donation_amount) / fundraiser.target_amount)
  return <div className='flex text-gm-green-900'>
    <div className='my-auto w-24 h-24'>
      <CircularProgressbarWithChildren
        value={percentageRaised}
        strokeWidth={15}
        styles={buildStyles({
          textColor: '#003232',
          pathColor: '#003232',
          trailColor: '#E6F6F2',
          strokeLinecap: 'butt'
        })}
        text={`${percentageRaised}%`}
        counterClockwise={true}
      >
        {/* Foreground path */}
        <CircularProgressbar
          value={youRaisedPercentage}
          strokeWidth={15}
          styles={buildStyles({
            pathColor: '#02A47B',
            trailColor: 'transparent',
            strokeLinecap: 'butt'
          })}
          counterClockwise={true}
        />
      </CircularProgressbarWithChildren>
    </div>
    <div className='flex flex-col ml-2'>
      <span className='text-xl font-bold'>{displayTidyCurrency(raisedTotal, donation.donationCurrency, true)}</span>
      <span className="text-sm font-light">raised of {displayTidyCurrency(fundraiser.target_amount, donation.donationCurrency, true)} - {compactNumber(fundraiser.donation_count ?? 0)} donors</span>
      <div className='relative mt-3'>
        <span className="text-md font-thin text-gm-green">You raised: {displayTidyCurrency(fundraiser.non_referral_donation_amount, donation.donationCurrency, true)}</span>
        <ToolTip>This is how much people have donated to this fundraiser via Givematch.</ToolTip>
      </div>
      <div className='relative'>
        <span className="text-md font-thin">Givematch added: {displayTidyCurrency(givematchAddedTotal, donation.donationCurrency, true)}</span>
        <ToolTip>The amount Givematch has added by matching donations and donor referrals.</ToolTip>
      </div>
    </div>
  </div>
}
export const FundraiserDonation: FC<Props> = ({ event, onDonateButtonClick, eventID, charity, isPreview }) => {
  const { donation } = useDonationPlan()
  const navigate = useNavigate()
  const getRecentDonations = (): React.ReactNode => {
    return <>
      <h2 className="text-xl py-6 font-bold">Donations ({compactNumber(event.donation_count ?? 0)})</h2>
      {event.donations?.map((donor, index) => <DonorCard key={index} donation={donor} />)}
      <div className='w-full border-t mt-10 border-gm-green'></div>
    </>
  }

  const onShareButtonClick = (): void => {
    Tracking.shareFundraiserButton()
    navigate(Navigation.shareFundraiser(eventID))
  }

  const generateStory = (story: string): React.ReactNode => {
    console.log(story)
    const disableButton = isPreview || donation.amount < 500
    return <>
      <div className='w-full border-b py-6 gap-y-4 border-gm-green'>
          <div data-color-mode="light" className='whitespace-pre-wrap'>
            {story.charAt(0) === '<'
              ? <Editor story={story} className="editor" />
              : story }
          </div>
        {event.active && <div className={`flex justify-between gap-x-2 py-3 ${disableButton ? ' cursor-not-allowed' : ''}`}>
          <Button variant='gm-secondary-md' className={`w-1/2 ${disableButton ? 'bg-sz-gray-100 border-sz-gray-100 text-gm-gray-100' : 'bg-gm-yellow border-gm-yellow'} font-bold text-lg  py-1`}
            data-test-id={GivematchTestId.chooseCauseButton}
            disabled={disableButton}
            onClick={() => onDonateButtonClick(donation.amount)}>
            Donate
          </Button>
          <Button variant='gm-secondary-md'
            className={`border-gm-green w-1/2 text-gm-green font-bold text-lg py-1 ${isPreview ? ' cursor-not-allowed' : ''}`}
            onClick={onShareButtonClick} disabled={isPreview}>
            Share
          </Button>
        </div>}
      </div>
    </>
  }
  const generateDonorCard = (organiser: Organiser): React.ReactNode => {
    return <>
      {organiser.first_name && <div className='w-full flex gap-x-2 items-center text-gm-green '>
        <img src={profileDark} alt="donor" className='h-6 w-6' />
        <span className='tracking-tight text-sm font-light'>  {organiser.first_name + ' ' + organiser.last_name} is organizing this campaign</span>
      </div>}
    </>
  }
  const generateOrganizerCard = (organiser: Organiser, charity: Charity): React.ReactNode => {
    return <>
      <div className='w-full border-b py-6 border-gm-green flex gap-x-2 flex-col'>
        <h1 className='font-bold text-lg'> Organizer</h1>
        {organiser.first_name && <div className='flex flex-row gap-x-2 py-2 text-xs'>
          <img src={profile} alt="donor" className='h-6 w-6' />
          <div className=''>
            <h6 className='font-bold'> {organiser.first_name + ' ' + organiser.last_name}</h6>
            <p> Organizer</p>
          </div>

        </div>}
        <div className='flex flex-row gap-x-2 py-2 text-xs'>
          <img src={profile} alt="beneficiary" className='h-6 w-6' />
          <div>
            <h6 className='font-bold'> {charity.name}</h6>
            <p> Beneficiary</p>
          </div>

        </div>
      </div>
    </>
  }
  // const CommentCard = ():JSX.Element => {
  //   return <div className='flex flex-row gap-x-2 py-2 text-xs'>
  //     <img src={profile} alt="commenter" className='h-6 w-6' />
  //     <div className=''>
  //       <h6 className='font-bold'> Ismael Dainehine </h6>
  //       <p> <span className='font-bold'> $10,000</span> <span className='text-sz-gray-300'> - 4 days ago</span></p>
  //       <p className=' text-sz-gray-400 py-2'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
  //     </div>

  //   </div>
  // }
  // const generateCommentCard = (): React.ReactNode => {
  //   return <>
  //     <div className='w-full border-b py-6 border-gm-green flex gap-x-2 flex-col'>
  //       <h1 className='font-bold text-lg'> Comments (24)</h1>
  //       <span className=' text-sz-gray-300 text-xs py-2'> Please donate to share words of support </span>
  //       <CommentCard />
  //       <CommentCard />

  //     </div>
  //   </>
  // }

  return <section className={`${isPreview ? '' : 'px-4'} py-2 md:px-12 lg:px-40 lg:flex-1 w-full text-gm-green-900`}>
    <div className={`grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5`}>
      <div className="col-span-1 xl:col-span-2">
        {!event.active && <div className='flex items-center border border-gm-yellow p-3 my-3 rounded-lg'>
          <img src={warning} className="mr-3 w-6 h-6" alt='warning' />
          This fundraiser is not receiving donations at the moment
        </div>}
        <div className="flex flex-col  gap-y-2">
          <div className="font-bold text-xl md:text-2xl">
            {event.title}
          </div>
          {event.youtube_url && <YoutubeEmbed classname="rounded-xl w-full md:h-96" embedUrl={event.youtube_url} />}
          {!event.youtube_url && <FundRaiserImage classname='rounded-xl w-full' image={event.newCoverPhoto ?? event.image_path} fundraiserId={eventID} />}
          <div className="flex flex-col gap-y-2">
            {generateDonorCard(event.organiser)}
          </div>
        </div>
        <div>
          <div className="flex-col gap-y-10 hidden md:inline">
            {generateStory(event.story)}
          </div>
          {/* <div className="flex-col gap-y-2 hidden md:inline">
            {generateCommentCard()}
          </div> */}
        </div>

      </div>

      <div className="flex flex-col gap-y-1 col-span-1 xl:col-start-3 md:mt-12 max-w-sm">
        <FundraiserProgressDetails fundraiser={event} />
        <DonationCard
          onButtonClick={onDonateButtonClick}
          donateButton={true}
          charityId={charity.charity}
          charityName={charity.name}
          notAcceptingDonation={isPreview || !event.active}
          showFundraiserButton={false} />
        <div className="flex-col gap-y-2 text-left mt-5 hidden md:inline">
          {getRecentDonations()}
        </div>
        <div className="flex-col gap-y-2 md:inline hidden">
          {generateOrganizerCard(event.organiser, charity)}
        </div>
      </div>

      <div className='flex flex-col md:hidden'>
        <div className="flex flex-col col-span-1 md:col-span-3 gap-y-5 ">
          {generateStory(event.story)}
        </div>

        <div className="flex flex-col gap-y-2">
          {getRecentDonations()}
        </div>
        <div className="flex flex-col gap-y-2">
          {generateOrganizerCard(event.organiser, charity)}
        </div>
        {/* <div className="flex flex-col gap-y-2">
          {generateCommentCard()}
        </div> */}
      </div>

    </div>

    <AboutGivematch />
  </section>
}
