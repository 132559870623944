import { type FC, useState } from 'react'
import { Button } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import { calculateCauseTotal, GivematchTestId, isCause } from '@givematch/common'
import { displayTidyCurrency } from '@lib/services'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { Tracking } from '~/service/tracking'
import { Navigation } from '~/service/navigation'
import GivematchLayout from '~/components/GivematchLayout'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'

const Explainer: FC = () => {
  const { donation } = useDonationPlan()
  const navigate = useNavigate()
  const [confirmed, setConfirmed] = useState(false)
  const donationTotal = isCause(donation.causeType) ? calculateCauseTotal(donation.causeAmounts) : donation.amount
  const onContinue = (): void => {
    Tracking.understoodHowItWorks(donation.charity, donationTotal)
    navigate(Navigation.payment())
  }
  return <GivematchLayout>
    <ProgressSteps stepToActivate={2} numberOfSteps={4} />

    <h1 className="text-2xl font-semibold tracking-tighter ">How to get matched</h1>
    <img src="/twofriends.jpg" className="rounded-xl object-cover" alt="Three friends laughing" />
    <div className="flex flex-col gap-y-4">
      <h1 className="text-3xl font-semibold mt-4 lg:mt-0">Get 2 friends to donate (any amount), <span className="text-gm-green">to earn your {displayTidyCurrency(donation.matchedAmount, donation.donationCurrency)} match</span></h1>
      <div className="border border-gm-green rounded-lg p-4 shadow-md flex items-center ">
        <input
          type="checkbox"
          data-test-id={GivematchTestId.explainerGotItCheckBox}
          id="gotit"
          className="w-5 h-5 border-2 border-gm-green rounded mr-5 appearance-none checked:bg-gm-green"
          onClick={() => setConfirmed(!confirmed)}
        />
        <label htmlFor="gotit" className="font-semibold w-4/5"><span className="text-gm-green">Got it!</span> I understand how to get my donation matched</label>
      </div>
      <Button
        data-test-id={GivematchTestId.explainerContinueButton}
        type="submit"
        className="rounded-lg w-full"
        variant="gm-primary"
        disabled={!confirmed}
        onClick={() => onContinue()}
      >
        Continue
      </Button>
    </div>
  </GivematchLayout>
}

export default Explainer
