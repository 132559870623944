import { type FC, useState } from 'react'
import { Navigation } from '~/service/navigation'
import { useNavigate } from 'react-router-dom'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { SelectMedia } from '~/components/FundRaiser/SelectMedia'
import { Tracking } from '~/service/tracking'
import { getNextStep } from '~/utils/fundraiserSteps'

export const SelectCoverPhoto: FC = () => {
  const { fundraiser, setFundraiser } = useFundraiserPlan()
  const navigate = useNavigate()
  const [coverPhoto, setCoverPhoto] = useState(fundraiser.coverPhoto)
  const [youtubeEmbedUrl, setYoutubeEmbedUrl] = useState(fundraiser.youtubeUrl)

  const proceedToNextPage = (): void => {
    setFundraiser({ ...fundraiser, coverPhoto, youtubeUrl: youtubeEmbedUrl })
    Tracking.addCoverImage(fundraiser)
    navigate(getNextStep(Navigation.fundraiserSelectCoverPhoto()))
  }

  const onDelete = (): void => {
    setFundraiser({ ...fundraiser, coverPhoto: '', youtubeUrl: '' })
  }

  return <>
    <SelectMedia onProceed={proceedToNextPage} onDelete={onDelete}
                 setCoverPhoto={setCoverPhoto} setYoutubeEmbedUrl={setYoutubeEmbedUrl}
                 coverPhoto={coverPhoto} youtubeEmbedUrl={youtubeEmbedUrl} />
  </>
}
