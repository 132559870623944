import { Currency } from '@lib/services'

export const symbolMap: Record<string, string> = {
  AUD: 'A$',
  CAD: 'CA$',
  CHF: 'Fr ',
  EUR: '€',
  GBP: '£',
  NOK: 'Kr ',
  USD: 'US$',
  ZAR: 'R'
}

export const currencyMap: Record<string, Currency> = {
  au: Currency.AUD,
  ca: Currency.CAD,
  ch: Currency.CHF,
  es: Currency.EUR,
  ie: Currency.EUR,
  it: Currency.EUR,
  gb: Currency.GBP,
  no: Currency.NOK,
  us: Currency.USD,
  za: Currency.ZAR
}

const baseFeeAmount: Record<string, number> = {
  AUD: 30,
  CAD: 30,
  CHF: 30,
  EUR: 30,
  GBP: 20,
  NOK: 200,
  USD: 30,
  ZAR: 30
}

const feePercentage = 0.029

const minimumAmounts: Record<string, number> = {
  AUD: 100,
  CAD: 100,
  CHF: 80,
  EUR: 80,
  GBP: 60,
  NOK: 600,
  USD: 100,
  ZAR: 1500
}

export const minAmount = (currency: Currency): number => minimumAmounts[currency] || 100

export const validPaymentAmount = (amount: number, currency: Currency): boolean => {
  return amount >= minAmount(currency) && amount <= 99999999
}

export const getCurrency = (country: string): Currency => {
  return currencyMap[country]
}

export const getCurrencySymbol = (currency: Currency): string => symbolMap[currency.toUpperCase()] || ''

export const calculateFee = (amount: number, currency: Currency): number => {
  const baseFee = baseFeeAmount[currency.toUpperCase()]
  return Math.ceil((amount + baseFee) / (1 - feePercentage)) - amount
}

export const calculatePlatformTip = (causeTotal: number, platformTipPercentage: number): number => {
  const tipAmount = causeTotal / 100 * platformTipPercentage
  return Math.floor(tipAmount)
}

export const calculateCauseTotal = (causeAmounts: Record<string, number>): number => {
  return Object.values(causeAmounts).reduce((a, c) => a + (isNaN(c) ? 0 : c), 0)
}
