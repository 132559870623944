import { Button } from '@lib/components'

import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type FC } from 'react'
import { SessionStorage } from '~/service/sessionStorage'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import rightMark from '~/assets/right-mark-white.svg'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { Tracking } from '~/service/tracking'

export const NextSteps: FC = () => {
  const onButtonClick = (): void => {
    Tracking.letsGoFundraiser()
    navigate(Navigation.shareFundraiser(sessionStorage.getItem(SessionStorage.fundraiser_id_published) ?? ''))
  }

  const navigate = useNavigate()
  return <FundraiserLayout title="Your fundraiser is ready.">
    <span className="text-2xl font-bold">Let's start getting donations!</span>

    <ol className="relative border-l border-gm-green-200 my-10">
      <li className="mb-10 ml-4">
        <div className="absolute w-5 h-5 bg-gm-green rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"><img src={rightMark} alt='right mark'/></div>
        <div className="text-gm-green">Set up your Givematch fundraiser</div>
      </li>
      <li className="mb-10 ml-4">
        <div className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"></div>
        <div>Share with 3-5 friends and ask them to help you share your page</div>
      </li>
      <li className="mb-10 ml-4">
        <div className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"></div>
        <div>Post your page on social media</div>
      </li>
      <li className="ml-4">
        <div className="absolute w-5 h-5 bg-gm-green-200 rounded-full -left-2.5 border border-white dark:border-gray-900 dark:bg-gray-700 flex justify-center items-center"></div>
        <div>Send your share link to your friends</div>
      </li>
    </ol>
    <Button
      data-test-id={GivematchFundraiserTestIds.continueButton}
      className="w-full"
      variant="gm-primary"
      onClick={() => onButtonClick()}>
      <span>Let's go!</span>
    </Button>
  </FundraiserLayout>
}
