import { type FC, useEffect, useState } from 'react'
import { getUserCountry, isMultiCharity } from '@lib/services'
import { type CharityList, getCharities } from '~/state/charity'
import { Input, SearchIcon, Spinner, Variant } from '@lib/components'
import CharityCards from '~/components/CharityCards'
import GivematchLayout from '~/components/GivematchLayout'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'
import { useDebounce } from '@lib/hooks'

const SelectCharity: FC = () => {
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [charities, setCharities] = useState<CharityList[]>()
  const [multiCharity, setMultiCharity] = useState<CharityList>()
  const [searchText, setSearchText] = useState('')
  const country = getUserCountry()
  const debouncedSearchText = useDebounce(searchText)
  const fetchCharities = (searchText: string): void => {
    setLoading(true)
    let onlyWithWeight = true
    let debouncedSearchTerm = ''
    if (searchText) {
      onlyWithWeight = false
      debouncedSearchTerm = searchText
    }
    getCharities(country, onlyWithWeight, debouncedSearchTerm)
      .then((res) => {
        setCharities(res)
        const found = res.find((charity) => isMultiCharity(charity.charityID))
        if (found) {
          setMultiCharity(found)
        }
      }).catch(() => {
        setIsError(true)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (debouncedSearchText.length > 2) {
      fetchCharities(debouncedSearchText)
    } else {
      fetchCharities('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText])

  return <GivematchLayout>
    <ProgressSteps stepToActivate={1} numberOfSteps={4} />
    <h1 className="text-2xl font-semibold tracking-tighter ">Select your charity</h1>

    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Search for a charity</span>
    <div className="relative mt-5">
      <SearchIcon className='text-gm-green-500'/>
      <Input variant={Variant.gm} value={searchText} onChange={(event) => setSearchText(event.currentTarget.value)}
             placeholder="Name, location, cause" className="w-full max-w-full pl-12 rounded" autoFocus />
    </div>

    {loading && <Spinner className="m-auto mt-10" />}
    {isError && <p className="px-4 mt-4 text-gm-red"> We were unable load charities </p>}
    {!loading && !isError && charities && charities.length > 0 && <div className='pt-5'>
      {!debouncedSearchText && <span className="text-gm-green-500 font-bold text-xl mt-5">Pick from suggested charities</span>}
      <CharityCards charities={charities} defaultCharity={multiCharity} />
    </div>}
  </GivematchLayout>
}

export default SelectCharity
