import { type FC } from 'react'
import { Button, Page } from '@lib/components'
import { useNavigate } from 'react-router-dom'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const AboutUs: FC = () => {
  const navigate = useNavigate()
  return <div className="flex flex-col min-h-screen">
    <Header />

    <Page skinny className="flex-1 px-4 mb-8">
      <h1 className="mt-12 text-4xl font-semibold tracking-tighter text-center text-gm-green">Giving counts more on Givematch</h1>
      <p className="mt-8">
        We believe everyone wants a sustainable, affordable and fun way to support charitable projects and have a positive impact on the world. But usually, supporting a charity is limited to a cold transaction which doesn’t let people take an active role when contributing towards good causes.
      </p>
      <p className="mt-4">
        Nowadays, we’re all sort of social influencers and we can achieve so much through social sharing. So we created a new way to support people and causes in need, bringing fun and community spirit to charity so anyone can support charity work no matter how much they can afford.
      </p>
      <p className="mt-4">
        Whatever someone gives to charity via Givematch, they earn a donation match to double it as they encourage their followers, friends and family to donate through their personal link.
      </p>
      <p className="mt-4">
        We work with generous organisations, companies and individuals to match donations, so social posts can count towards the things that matter the most.
      </p>
      <div className="mx-auto mt-8 w-72">
        <Button
          className="w-full"
          variant="gm-primary"
          onClick={() => navigate('/#donate')}>
          Donate
        </Button>
      </div>
    </Page>
    <Footer />
  </div>
}

export default AboutUs
