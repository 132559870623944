import { type FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '~/components/Header'
import { type FundraiserInviteResponse, getFundraiserInvite, updateFundraiserInvite } from '~/state/fundraiser'
import tickWhite from '~/assets/tick-white.svg'

import { Button, Spinner } from '@lib/components'
import { Status, UrlRoutes } from '@lib/services'
import { YoutubeEmbed } from '~/components/YoutubeEmbed'
import { FundRaiserImage } from '~/components/FundRaiser/FundRaiserCard'

const Message: FC<{ text: string }> = ({ text }): JSX.Element => {
  return <div className='mt-4 p-2 bg-gm-green-200 flex rounded'>
    <img className='w-10' src={tickWhite} alt='tick white'/>
    <p className="px-4 my-auto"> {text}</p>
  </div>
}

export const FundraiserInvite: FC = () => {
  const { fundraiserID, inviteCode } = useParams() as { fundraiserID: string, inviteCode: string }
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [fundraiserInvite, setFundraiserInvite] = useState<FundraiserInviteResponse>()
  const [invitationUpdatedStatus, setInvitationUpdatedStatus] = useState('')

  const loadInvitation = (): void => {
    setLoading(true)
    getFundraiserInvite(fundraiserID, inviteCode).then(data => {
      setFundraiserInvite(data)
    }, () => {
      setError(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadInvitation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const acceptInvitation = (): void => {
    setProcessing(true)
    updateFundraiserInvite(fundraiserID, inviteCode).then(() => {
      setInvitationUpdatedStatus(Status.success)
    }, () => {
      setInvitationUpdatedStatus(Status.fail)
    }).finally(() => setProcessing(false))
  }

  return <div
    className="flex flex-col justify-items items-center min-h-screen min-h-screen-ios px-4">
    <Header />
    {loading && <Spinner className="m-auto" />}
    {error && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch fundraiser invitation details </p>}
    {!loading && !error && fundraiserInvite && fundraiserInvite.accepted && <Message text='Invitation already accepted' />}
    {!loading && !error && fundraiserInvite && !fundraiserInvite.accepted && <div className='flex flex-col items-center'>
      <p className="px-4 mt-4 text-xl mb-4">Accept invitation to join fundraiser</p>
      <div className='flex gap-x-3 md:gap-x-10 border-2 rounded-xl my-10 cursor-pointer align-items-center bg-gm-gray-300 w-full md:w-1/2' onClick={() => window.open(UrlRoutes.buildFundraiserUrl(fundraiserID), '_blank')}>
        <div className='w-1/3 md:w-1/6'>
          {fundraiserInvite.youtube_url && <YoutubeEmbed classname="rounded-xl w-full" embedUrl={fundraiserInvite.youtube_url} />}
          {!fundraiserInvite.youtube_url && <FundRaiserImage classname='rounded-xl w-full' image={fundraiserInvite.image_path} fundraiserId={fundraiserID} />}
        </div>
        <span className='text-gm-green-800 text-lg md:text-2xl md:mt-2 font-bold'>{fundraiserInvite.title}</span>
      </div>
      <Button variant='gm-primary' className='rounded-xl' disabled={processing || invitationUpdatedStatus === Status.success} onClick={ () => acceptInvitation() }>
        {!processing ? 'Join Fundraiser' : 'Processing'}
      </Button>
    </div>}
    {!processing && invitationUpdatedStatus === Status.success && <Message text='Invitation accepted successfully'/>}
    {!processing && invitationUpdatedStatus === Status.fail && <div className="text-sz-pink text-small text-end">Unable to join fundraiser. Please try again and contact us if errors persist.</div>}
  </div>
}
