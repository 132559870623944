import { type FC, useEffect } from 'react'
import { Tracking } from '../service/tracking'

const TrackShareLinkIFrame: FC = () => {
  useEffect(() => {
    Tracking.shareLinkOptionsIframe()
  }, [])

  return null
}

export default TrackShareLinkIFrame
