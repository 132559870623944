import type { FC } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ContainerLayout } from '~/components/ContainerLayout'

interface HeroSectionProps {
  imgSrc: string
}

const Subtitle: FC = () => (
  <h2 className="font-normal lg:text-left" style={{ fontSize: '1.125rem', lineHeight: '140%' }}>
    This Ramadan, Givematch is offering a spectacular £1 million prize pool for charities through daily competitions.
    <br/><br/>
    Boost your favourite charity's chances
    by <strong className="font-bold">donating, referring friends, and spreading the word.  </strong>
    Plus, enjoy the added bonus of having your donations matched! Join us in making a difference.
  </h2>
)

const HeroSection: FC<HeroSectionProps> = ({ imgSrc }) => {
  // https://tailwindcss.com/docs/responsive-design
  const isSM = useMediaQuery({ query: '(min-width: 640px)' })
  // const isMD = useMediaQuery({ query: '(min-width: 768px)' })
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' })
  // const is2XL = useMediaQuery({ query: '(min-width: 1536px)' })

  return (
    <ContainerLayout
      className="px-6 lg:px-40 pt-3.5 sm:pt-10 lg:pt-20 lg:pb-10 flex flex-col xl:flex-row gap-6 xl:gap-0 justify-between"
      style={{
        background: isLG ? 'linear-gradient(180deg,#EAF8F1 0%,#FFF 100%)' : undefined
      }}
    >

      {/* Text */}
      <div
        className="flex flex-col items-start text-gm-black-100"
        style={{ width: isXL ? '32.875rem' : '100%' }}
      >

        {/* Title */}
        <h1
          className="lg:mb-6 lg:mb-12 font-bold mx-auto"
          style={{
            fontSize: isLG ? '4.5rem' : '2.5rem',
            lineHeight: isLG ? '105%' : '118%',
            letterSpacing: isLG ? '-0.125rem' : '-0.05rem'
          }}
        >
          Win <span className="text-gm-newgreen">£1 million</span> in prizes this Ramadan
        </h1>

        {/* Subtitle - MD and above */}
        {isXL && <Subtitle/>}
      </div>

      {/* Picture */}
      <div className="mx-auto" style={{
        width: isSM ? '80%' : (isLG ? '39.25rem' : '100%'),
        maxWidth: isSM && !isLG ? '25rem' : '39.25rem',
        height: isLG ? '28.0625rem' : '100%'
      }}>
        <img src={imgSrc} loading="lazy" alt="Granny Dab"/>
      </div>

      {/* Subtitle - MD and below (mobile) */}
      {!isXL && (
        <div className="sm:px-20 md:px-40 lg:px-10">
          <Subtitle/>
        </div>
      )}

    </ContainerLayout>
  )
}

export default HeroSection
