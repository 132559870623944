import { type FC } from 'react'
import Header from '~/components/Header'
import Footer from '~/components/Footer'

const GivematchLayout: FC = ({ children }) => {
  return <div className="flex flex-col text-center causePage">
        <Header />
        <div className='flex justify-center'>
            <section className="p-4 flex w-full flex-col max-w-lg justify-center text-left gap-y-2">
                {children}

            </section>
        </div>
        <Footer />
    </div>
}

export default GivematchLayout
