import { type FC } from 'react'
import ir_uk from '../../assets/charity-icons/islamic-relief.svg'
import al_mustafa from '../../assets/charity-icons/al-mustafa.svg'
import human_relief from '../../assets/charity-icons/human-relief.svg'
import muslim_aid from '../../assets/charity-icons/muslim-aid.svg'
import muslim_charity from '../../assets/charity-icons/muslim-charity.svg'
import orphan_in_need from '../../assets/charity-icons/orphans-in-need.svg'

export const CharityPartners: FC = () => {
  return <div className='flex flex-col gap-y-5 my-5'>
    <div className='flex justify-center gap-x-10  w-full flex-wrap '>
      <img src={ir_uk} className='w-14 h-14' alt='Islamic Relief'/>
      <img src={human_relief} className='w-14 h-14' alt='Human Relief'/>
      <img src={muslim_aid} className='w-14 h-14' alt='Muslim Aid'/>
    </div>
    <div className='flex justify-center gap-x-10  w-full flex-wrap '>
      <img src={muslim_charity} className='w-14 h-14' alt='Muslim Charity'/>
      <img src={al_mustafa} className='w-14 h-14' alt='Al Mustafa'/>
      <img src={orphan_in_need} className='w-14 h-14' alt='Orphan In Need'/>
    </div>
  </div>
}
