import { Navigation } from '~/service/navigation'
import { type FundraiserPlan } from '~/hooks/useFundraiserPlan'

type Field = keyof FundraiserPlan
type ConstraintFn = (plan: FundraiserPlan) => boolean
type Validator = (field: Field, ...options: any) => ConstraintFn

// given field="foo.bar", return plan[foo][bar]
const getNestedValue = (plan: FundraiserPlan, field: Field): any => {
  let value: any = plan
  const keys: string[] = field.split('.')
  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]]
  }
  return value
}

// Validator functions, which return a Constraint function, to be run against the plan
const isNotUndefined: Validator = (field: Field) => (plan: FundraiserPlan): boolean => getNestedValue(plan, field) !== undefined
const isNotValue: Validator = (field: Field, value: number) => (plan: FundraiserPlan): boolean => getNestedValue(plan, field) !== value
const isNotEmptyString: Validator = (field: Field) => (plan: FundraiserPlan): boolean => getNestedValue(plan, field) !== ''
const isNotEmptyArray: Validator = (field: Field) => (plan: FundraiserPlan): boolean => {
  const value = getNestedValue(plan, field)
  return value !== undefined && Array.isArray(value) && (value).length > 0
}

// DRY
const STEP_ENTER_LOCATION = Navigation.fundraiserEnterLocation()
const STEP_SELECT_CHARITY = Navigation.fundraiserSelectCharity()
const STEP_ENTER_TARGET = Navigation.fundraiserEnterTarget()
const STEP_COVER_PHOTO = Navigation.fundraiserSelectCoverPhoto()
const STEP_ENTER_TITLE = Navigation.fundraiserEnterTitle()
const STEP_TEAM_MGMT = Navigation.fundraiserTeamManagementStep()
const STEP_CONFIRM_CHARITY = Navigation.fundraiserConfirmCharity()
const STEP_NEXT_STEPS = Navigation.fundraiserNextSteps()

// Define the list of steps, IN ORDER.
// NOTE: Changing the order here will change the navigation order in the UI,
//       as each step makes use of the `getNextStep()` fn to navigate.
const STEPS: string[] = [
  STEP_ENTER_LOCATION,
  STEP_SELECT_CHARITY,
  STEP_ENTER_TARGET,
  STEP_COVER_PHOTO,
  STEP_ENTER_TITLE,
  STEP_TEAM_MGMT,
  STEP_CONFIRM_CHARITY,
  STEP_NEXT_STEPS
]

// Define the fields used in the constraints
const COUNTRY: Field = 'country' as Field
const POSTCODE: Field = 'postcode' as Field
const CHARITY_ID: Field = 'charity.charityID' as Field // this is handled by `getNestedValue` fn
const TARGET_AMOUNT: Field = 'target_amount' as Field
const COVER_PHOTO: Field = 'coverPhoto' as Field
const TITLE: Field = 'title' as Field
const STORY: Field = 'story' as Field
const TEAM_MEMBERS: Field = 'teamMembers' as Field

// Define the constraint functions for each step.
// NOTE: This is a map, the order in which you define the entries doesn't matter
const CONSTRAINTS: Record<string, ConstraintFn[]> = {
  [STEP_ENTER_LOCATION]: [isNotEmptyString(COUNTRY), isNotEmptyString(POSTCODE)],
  [STEP_SELECT_CHARITY]: [isNotEmptyString(CHARITY_ID)],
  [STEP_ENTER_TARGET]: [isNotValue(TARGET_AMOUNT, 100000)],
  [STEP_COVER_PHOTO]: [isNotEmptyString(COVER_PHOTO)],
  [STEP_ENTER_TITLE]: [isNotUndefined(TITLE), isNotUndefined(STORY)],
  [STEP_TEAM_MGMT]: [isNotEmptyArray(TEAM_MEMBERS)]
}

// Get the next step from the current step; used for navigation between steps.
export const getNextStep = (currentStep: string): string => STEPS[STEPS.indexOf(currentStep) + 1]

// Go over all steps in order & check constraints. Stop at the step where a check fails.
// Return a list of all steps, up to, and including the failing step.
// In a nutshell, given an existing fundraiser plan, this returns the step at which the user abandoned the flow.
export const getWalkForPlan = (plan: FundraiserPlan, debugPrint: boolean = false): string[] => {
  const walk: string[] = []

  // Go over each step
  for (let i = 0; i < STEPS.length; i++) {
    // Save current step
    walk.push(STEPS[i])

    const constraints: ConstraintFn[] = CONSTRAINTS[STEPS[i]]
    const hasConstraints: boolean = constraints && Array.isArray(constraints) && constraints.length > 0
    debugPrint && console.log(`[getWalkForPlan] STEP[${i}] -> ${hasConstraints ? constraints.length : 0} constraints`)

    if (!hasConstraints) {
      // No constraints for this step, continue to next step
      debugPrint && console.log(`\t[getWalkForPlan] STEP[${i}] -> No constraints, continue to next step`)
      continue
    }

    // Check that all constraints are met
    let allConstraintsMet: boolean = true
    for (let j = 0; j < constraints.length; j++) {
      if (!constraints[j](plan)) {
        // Constraint not met, stop checking the rest of constraints
        debugPrint && console.log(`\t[getWalkForPlan] STEP[${i}] CONSTRAINT[${j}] -> Constraint failed, skip checking remaining constraints`)
        allConstraintsMet = false
        break
      } else {
        debugPrint && console.log(`\t[getWalkForPlan] STEP[${i}] CONSTRAINT[${j}] -> Constraint passed`)
      }
    }

    // If some constraints have failed, we have found the step with incomplete data
    if (!allConstraintsMet) {
      debugPrint && console.log(`\t[getWalkForPlan] STEP[${i}] FOUND STEP -> Some constraints failed, stopped at "${STEPS[i]}"`)
      break
    }
  }

  debugPrint && console.log(`[getWalkForPlan] Computed Walk ->`, walk)

  return walk
}
