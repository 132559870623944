import { type FC } from 'react'
import { Button } from '@lib/components'
import styles from '../css/Error.module.css'
import logo from '../assets/logo.svg'
import { NavLink } from 'react-router-dom'

const Error: FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <div className={`px-4 text-center ${styles.errorPage}`}>
      <div className="header">
        <NavLink to="/"><img className="w-36" src={logo} alt="give match"></img></NavLink>
      </div>
      <div className="mx-auto mt-12">
        <h2 className="text-4xl font-semibold tracking-tighter text-center">{errorMessage !== '' ? errorMessage : 'Sorry! Something\'s gone wrong.'}</h2>
        <div className="mx-auto mt-12 w-72">
          <a href="/">
            <Button
              className="w-full"
              variant="gm-primary">
              Home
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Error
