import { type FC, useEffect } from 'react'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { getUserCountry } from '@lib/services'
import { useQuery } from 'react-query'
import { getCharities } from '~/state/charity'
import { Spinner } from '@lib/components'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'
import CharityCardsRamadan from '~/components/ramadan/CharityCardsRamadan'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { CauseType, LandingPageType } from '@givematch/common'
import { Tracking } from '~/service/tracking'

const SelectCharityRamadan: FC = () => {
  const { donation, setDonation } = useDonationPlan()
  useEffect(() => {
    const checkIfPageIsFromStaticPage = (): void => {
      let obj: any = window.sessionStorage.getItem('static_ramadan')
      obj = JSON.parse(obj)
      if (obj?.static) {
        // get amount, so we can set amount, cause and all landing page requirements
        setDonation({ ...donation, donationCurrency: obj.currency, amount: obj.amount, matchedAmount: obj.matchedAmount, causeType: CauseType.CAUSE, fundraiserTitle: '', fundraiserID: '', causeAmounts: {}, landingPage: LandingPageType.Ramadan })
        Tracking.proceedToChooseCharity(obj.amount, obj.currency)
      }
      // remove the item from the storage session
      window.sessionStorage.removeItem('static_ramadan')
    }
    checkIfPageIsFromStaticPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const country = getUserCountry()

  const { isLoading: charitiesLoading, isError: charitiesError, data: charities } = useQuery(['charities', country], async () => getCharities(country, true, ''))

  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    <section className="py-4 px-4 sm:px-20 sm:mx-20 md:mx-auto lg:mx-auto flex flex-col lg:justify-between w-full md:px-12 lg:px-40 ">
      {charitiesLoading && <Spinner className="m-auto" />}
      {charitiesError && <p className="px-4 mt-4 text-gm-red"> We were unable load charities </p>}
      { !charitiesLoading && !charitiesError && charities && charities.length > 0 && <div className="mt-5 w-full grid grid-cols-1 place-content-center sm:grid-cols-auto-sm gap-y-2">
        <ProgressSteps stepToActivate={1} numberOfSteps={4}/>
        <p className="text-2xl font-semibold tracking-tighter text-left">Select your charity</p>
        <CharityCardsRamadan charities={charities}/>
      </div>}
    </section>
    <Footer />
  </div>
}

export default SelectCharityRamadan
