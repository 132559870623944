import { type FC, useEffect } from 'react'
import { type Currency, displayCurrency, type GivematchCauseCategory, ObjectUtil } from '@lib/services'
import '../../css/cause.css'
import { GivematchTestId, isCause } from '@givematch/common'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import CurrencyInput from 'react-currency-input-field'
import { causeImages } from '~/components/ramadan/CauseImages'
import { type Cause } from '~/state/charity'
import default_cause from '~/assets/causes-icons/sadaqah.svg'

interface Props {
  id: string
  category: GivematchCauseCategory
  name: string
  value: number
  matchPotID: string
  onCauseAmountChange: (causeID: string, value: number) => void
  currency: Currency
  autoFocus: boolean
}

const CauseCardRamadan: FC<Props> = ({ id, name, category, onCauseAmountChange, currency, value, autoFocus }) => {
  const onValueChange = (value?: string): void => {
    const causeDonationAmount = parseFloat(value ?? '0') * 100
    onCauseAmountChange(id, causeDonationAmount)
  }

  return <div
    key={id} className='grid grid-cols-4 gap-x-2'>
      <p className='col-start-2 col-span-3 text-left text-xs'>{name}</p>
      <img src={causeImages[category] ?? default_cause} alt={causeImages[category]} className='col-start-1 col-span-1 object-contain'/>
      <CurrencyInput
        data-test-id={`${GivematchTestId.causeCard_}${id}`}
        intlConfig={{ locale: 'en-UK', currency }}
        defaultValue={value > 0 ? value / 100 : ''}
        id="cause amount"
        className="col-start-2 col-span-3 w-full border-2 rounded-md font-semibold text-left text-gm-green focus:outline-none border-gm-green text-3xl pl-2"
        placeholder={displayCurrency({ amount: 0, currency })}
        step={0.01}
        onValueChange={(value) => onValueChange(value ?? '')}
        autoFocus = {autoFocus}
      />

  </div>
}

const CauseCardsRamadan: FC<{ onCauseAmountChange: (causeID: string, value: number) => void }> = ({ onCauseAmountChange }) => {
  const { donation, setDonation } = useDonationPlan()
  const causes = donation.charity.causes.filter(cause => isCause(cause.causeType))
  const causeAmounts: Record<string, number> = {}
  if (donation.amount > 0) {
    const causesCount = causes.length
    const causeShare = Math.floor(donation.amount / causesCount)
    const remainingShare = causeShare + (donation.amount % causesCount)
    causes.forEach((cause, index) => {
      causeAmounts[cause.causeID] = index === causesCount - 1 ? remainingShare : causeShare
    })
  }

  // The donation amount need to be split equally among the causes, setting this on first DOM mount and when the causeAmount is not set
  useEffect(() => {
    if (ObjectUtil.isEmptyObject(donation.causeAmounts)) {
      setDonation({ ...donation, causeAmounts })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = causes.map((cause: Cause, index) => {
    // Since on first mount the donation.causeAmount is still empty taking it from the causeAmounts object
    const value = donation.causeAmounts[(cause.causeID)] ?? causeAmounts[cause.causeID]
    return <CauseCardRamadan
          key={cause.causeID}
          id={cause.causeID}
          name={cause.name}
          value={value}
          matchPotID={cause.matchPotID}
          category={cause.category}
          onCauseAmountChange={onCauseAmountChange}
          currency={donation.donationCurrency}
          autoFocus={index === 0}
        />
  }
  )
  return <div className="flex flex-col justify-center mx-auto max-w-7xl gap-y-5 my-5">
    {items}
  </div>
}

export default CauseCardsRamadan
