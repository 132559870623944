import { type Charity, type CharityList } from '~/state/charity'
import { CharityStatus } from '@lib/services'

/* eslint-disable no-prototype-builtins */
export const isNonApprovedAPICharity = (charity: CharityList | Charity): boolean => {
  if (charity.hasOwnProperty('charityApi')) {
    const ch: CharityList = charity as CharityList
    return (
      (ch.charityApi !== undefined && ch.charityApi !== '') &&
      ch.status !== CharityStatus.approved
    )
  } else if (charity.hasOwnProperty('charity_api')) {
    const ch: Charity = charity as Charity
    return (
      (ch.charity_api !== undefined && ch.charity_api !== '') &&
      ch.status !== CharityStatus.approved
    )
  }

  return false
}

const capitalize = (text: string): string => text
  .split(' ')
  .map((word: string): string => {
    const letters = word.split('')
    const firstLetter = letters.shift() ?? ''
    return `${firstLetter.toUpperCase()}${letters.join('')}`
  })
  .join(' ')

export const defaultCharitySpelling = (charityName: string): string => {
  const parts = charityName.split(' ')
  const defaultCharityCountry = parts.pop() ?? ''
  const defaultCharityName = parts.join(' ')
  return `${capitalize(defaultCharityName)} ${defaultCharityCountry.toUpperCase()}`
}
