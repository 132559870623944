import { Button } from '@lib/components'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import charityBanner from '~/assets/charity-landing-page-icons/charity-banner.png'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'

const CharityDonationImpact: FC<{ charityID: string }> = ({ charityID }) => {
  const sectionStyle = {
    backgroundImage: `url(${charityBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
  const navigate = useNavigate()
  const handleDonateClick = (charityID: string): void => {
    navigate(Navigation.charityHome(charityID))
  }
  const startFundraiser = (): void => {
    Tracking.startYourFundraiser()
    navigate(Navigation.fundraiserEnterLocation())
  }
  return (<section className={`px-4 sm:px-20 md:px-10 lg:px-40 bg-gray text-center md:text-left`} style={sectionStyle}>
  <div className="text-white md:my-10 my-5 md:max-w-2xl">
    <p className="text-3xl md:text-6xl font-bold">Double your impact with GIVEMATCH.</p>
  </div>
  <div className="md:max-w-xl">
    <h4 className="tracking-tighter font-medium md:text-3xl text-base">
      By choosing to donate through GIVEMATCH, <span className='font-bold'>you can help twice as many children</span> in desperate need of your help.
    </h4>
  </div>
  <div className="md:my-10 md:flex flex-col md:flex-row md:gap-x-6">
    <Button className="rounded-lg w-9/12 h-full md:w-36 my-5 md:my-0" variant='gm-charity-sz-primary' onClick={() => handleDonateClick(charityID)}>
      <div className='items-center justify-center'>
        <span className='md:text-3xl text-xl font-bold'>
          Donate
        </span>
      </div>
    </Button>
    <Button className="rounded-lg md:w-80 w-9/12 h-full mb-10 md:mb-0" variant='gm-charity-outline' onClick={() => startFundraiser()}>
      <div className='items-center justify-center'>
        <span className='md:text-3xl text-xl font-bold'>
          Create Fundraiser
        </span>
      </div>
    </Button>
  </div>
</section>
  )
}

export default CharityDonationImpact
