export enum PaymentType {
  card = 'card',
  applePay = 'apple',
  googlePay = 'google'
}

export interface PaymentFormData {
  email: string
  firstName: string
  lastName: string
  postcode: string
  country: string
  firstAddress: string
  giftAid: boolean
  taxReceipt: boolean
  payFees: boolean
  charityMarketingEmail: boolean
  givematchMarketingEmail: false
  remainAnonymous: boolean
  tipPercentage: number
  platformTip: number
  paymentType: PaymentType
  fundraiserNotification: boolean
  ramadanEmail: boolean
  token: string
}
