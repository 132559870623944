import type { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import { isMultiCharity } from '@lib/services'
import type { EnrichedMetricEntry, LeaderboardInfo, LeaderboardDetails, MetricName, Ranking } from '@givematch/common'
import {
  currencyForMetric,
  formatNumber,
  friendlyValueDescription,
  getUnitForReset,
  LOCALE_EN_GB
} from '@givematch/common'
import { VARIANT_STYLES } from '@lib/components'

import rightArrow from '~/assets/icons/rightArrow.svg'
import { ContainerLayout } from '~/components/ContainerLayout'
import { getPrizeImg } from '~/utils/rankings'

interface RankingsByMetricProps {
  colW: string[]
  colWMobile: string[]
  leaderboardInfo: LeaderboardInfo
  metricEntry: EnrichedMetricEntry
  metric: MetricName
  metricIndex: number
}

const RankingsByMetric: FC<RankingsByMetricProps> = ({
  colW, colWMobile,
  leaderboardInfo,
  metricEntry, metric, metricIndex
}) => {
  // https://tailwindcss.com/docs/responsive-design
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })

  const { participantsType, prizes } = leaderboardInfo

  // Filter out multi charity pot total
  const filteredRankings = metricEntry[metric].rankings
    .map((rankings) => rankings.filter(({ participantId }) => !isMultiCharity(participantId)))
    .filter(rankings => rankings.length > 0)

  const getLink = (r: Ranking): string => {
    return r.fundraiserId === ''
      ? `/${participantsType.toLowerCase()}/${r.participantId}` // charity homepage
      : `/fr/${r.fundraiserId}` // fundraiser homepage
  }

  return (
    <>
      {filteredRankings.length < 1 && (
        <div className="mt-6">No Rankings Available Yet</div>
      )}
      {filteredRankings.map((jointRankings, pId) => (
        jointRankings.map((r, rId) => (
          <div
            className={[
              'flex flex-row justify-between gap-2 md:gap-6 lg:gap-0',
              pId !== filteredRankings.length - 1 ? 'border-b-2' : (rId !== jointRankings.length - 1 ? 'border-b-2' : 'border-b-0')
            ].join(' ')}
            style={{
              padding: '0.5rem 0rem 1.5rem 0rem',
              borderColor: '#E3E3E3'
            }}
            key={`${metricIndex + 1}-${pId + 1}-${rId + 1}-${metric}`}
          >
            {/* Position (Rank) */}
            <div className={`${isLG ? colW[0] : colWMobile[0]} flex flex-row`}>
              <div
                className="font-bold text-center rounded-full"
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  color: 'white',
                  background: 'black',
                  fontSize: '0.83331rem',
                  lineHeight: '1.5rem'
                }}
              >
                {pId + 1}
              </div>
              {jointRankings.length > 1 && <div className="ml-1">=</div>}
            </div>

            {/* On mobile, we have one column, with stacked rows */}
            <div
              className={`${colWMobile[1]} flex flex-col gap-2 lg:hidden`}
              style={{ paddingTop: '0.125rem' }}
            >
              {/* Participant(s) */}
              <div className={`${isLG ? colW[2] : 'w-full'} font-bold min-w-0 pr-4 capitalize`}
                   style={{ fontSize: '1rem', lineHeight: '125%' }}>
                <div className="truncate">{r.participantName}</div>
                {r.fundraiserTitle !== '' && (
                  <div
                    className="mt-1 truncate font-normal tracking-normal text-gm-silver-200"
                    style={{ fontSize: '14px' }}
                  >
                    {r.fundraiserTitle}
                  </div>
                )}
              </div>

              {/* Metric & Donate Link */}
              <div className={`${isLG ? colW[2] : 'w-full'} font-medium`}
                   style={{ fontSize: '1rem', lineHeight: '140%' }}>
                <div className="min-w-0 flex flex-row justify-between">
                  <div className="truncate">
                    {metricEntry[metric].valuesTitle}: {formatNumber(
                      LOCALE_EN_GB, r.value, 2,
                      metricEntry[metric].isCurrency,
                      currencyForMetric(metric, prizes)
                    )}
                  </div>
                  <Link to={getLink(r)} target="_blank" className="text-gm-newgreen font-bold">
                    <span className="underline">Donate</span>
                    <span className="font-normal"> &gt;</span>
                  </Link>
                </div>
              </div>
            </div>

            {/* On Desktop, we have 2 columns */}
            {/* Participant */}
            <div className={`${colW[1]} hidden lg:flex flex-col font-bold min-w-0 lg:pr-4 capitalize`}
                 style={{ fontSize: '1rem', lineHeight: '125%' }}>
              <div className="truncate">{r.participantName}</div>
              {r.fundraiserTitle !== '' && (
                <div
                  className="mt-1 truncate font-normal tracking-normal text-gm-silver-200"
                  style={{ fontSize: '14px' }}
                >
                  {r.fundraiserTitle}
                </div>
              )}
            </div>

            {/* Metric */}
            <div className={`${colW[2]} hidden lg:flex font-medium min-w-0 lg:pr-4`}
                 style={{ fontSize: '1rem', lineHeight: '140%' }}>
              <div className="truncate">
                {formatNumber(
                  LOCALE_EN_GB, r.value, 2,
                  metricEntry[metric].isCurrency,
                  currencyForMetric(metric, prizes)
                )}
              </div>
            </div>

            {/* Donate Button */}
            <div className={`${colW[3]} h-full hidden lg:flex justify-center`}>
              <Link
                to={getLink(r)}
                target="_blank"
                className={`${VARIANT_STYLES['gm-primary']} bg-gm-newgreen flex items-center justify-center`}
                style={{
                  borderRadius: '2.3rem',
                  padding: '0.25rem 2.5rem 0.25rem 2.0rem',
                  width: '8.08163rem'
                }}
              >
                <div
                  className="flex gap-x-2 justify-between font-bold"
                  style={{ fontSize: '1rem', padding: '0' }}
                >
                  <p>Donate</p>
                  <img src={rightArrow} alt="right arrow" className="w-4"/>
                </div>
              </Link>
            </div>
          </div>
        ))
      ))}
    </>
  )
}

interface RankingsProps {
  selectedTab: number
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
  leaderboard: LeaderboardDetails
}

const Rankings: FC<RankingsProps> = ({ leaderboard, selectedTab, setSelectedTab }) => {
  // https://tailwindcss.com/docs/responsive-design
  const isMD = useMediaQuery({ query: '(min-width: 768px)' })
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' })

  const { metricsTracked, participantsType, reset } = leaderboard.info

  // Table column widths
  const colW = ['w-16', 'w-2/3', 'w-1/3', 'w-1/3']
  const colWMobile = ['w-11', 'w-5/6']

  return (
    <ContainerLayout className="flex flex-col gap-2 lg:gap-y-20 md:items-center">

      {/* Header & Tabs */}
      <div
        id={`leaderboards`}
        className="flex flex-col gap-7 lg:gap-0 lg:text-center bg-gm-newgreen-50 md:bg-transparent"
      >
        {/* Header */}
        <div className="px-6 lg:px-40 pt-6 md:pt-0 flex flex-row">
          {/* Red Dot */}
          <div
            className="w-4 h-4 mt-1.5 lg:mt-2 mr-4 rounded-full bg-gm-red-100"
            style={{ lineHeight: '110%', animation: 'live-pulse 1.5s infinite' }}
          >&nbsp;</div>

          {/* Title */}
          <div
            className="flex flex-col md:flex-row text-gm-black-100"
            style={{
              fontSize: isLG ? '1.875rem' : '1.5rem',
              lineHeight: '110%',
              letterSpacing: isLG ? '-0.0375rem' : '-0.03rem'
            }}
          >
            <h3 className="font-bold">
              Live Leaderboard {isLG ? <span>-&nbsp;</span> : (isMD ? <span>:&nbsp;</span> : ':')}
            </h3>
            <h4>{leaderboard.info.name} {getUnitForReset(leaderboard.info.reset, false)} {leaderboard.info.childBoardNum + 1}</h4>
          </div>
        </div>

        {/* Tabs */}
        {!isLG && (
          <div
            className="flex flex-row text-center text-gm-black-100"
            style={{ fontSize: '0.875rem', lineHeight: '140%', zIndex: 2 }}
          >
            {metricsTracked.map((m, mId) => {
              const metric = m.name
              const metricEntry: EnrichedMetricEntry = leaderboard.metrics as EnrichedMetricEntry
              return (
                <button
                  key={`${mId + 1}-${metric}-tab`}
                  className={`w-1/3 pb-5 border-b-8 ${mId === selectedTab ? 'font-bold' : 'font-normal'} cursor-pointer border-${mId === selectedTab ? 'gm-newgreen-200' : 'transparent'}`}
                  onClick={() => setSelectedTab(mId)}
                >
                  {metricEntry[metric].leaderboardTitle}
                </button>
              )
            })}
          </div>
        )}
      </div>

      {/* Tabs Gutter */}
      {!isLG && <hr
        className="w-full h-2 bg-gm-newgreen-150 opacity-30 border-none"
        style={{ marginTop: '-16px', zIndex: 1 }}
      />}

      {/* Table Contents */}
      <div className="px-6 md:px-20 lg:px-40 w-full flex flex-col">
        {(isLG ? metricsTracked : [metricsTracked[selectedTab]]).map((m, mId) => {
          const metric = m.name
          const metricEntry: EnrichedMetricEntry = leaderboard.metrics as EnrichedMetricEntry
          return (
            <div
              id={`leaderboard_${metric}`}
              key={`${mId + 1}-${metric}-board`}
              className={
                `lg:pb-10 lg:pt-11 flex flex-row flex-start gap-16 justify-center border-gm-silver-50 ${
                  isLG
                    ? (mId === metricsTracked.length - 1 ? 'border-b-0' : 'border-b')
                    : 'border-b-0'
                }`
              }
            >
              {/* Prize  */}
              <div
                className="hidden lg:flex flex-col flex-start pr-16"
                style={{ width: isLG ? '26rem' : 0, gap: '1.0625rem' }}
              >
                {/* Prize Image */}
                <img
                  loading="lazy" src={getPrizeImg(metric)} alt="Prize Rank"
                  style={{ width: '2.9375rem', height: '3.66438rem' }}
                />

                {/* Prize Title */}
                <h4 className="font-bold" style={{ fontSize: '1.5rem', lineHeight: '1.875rem' }}>
                  {metricEntry[metric].leaderboardTitle}
                </h4>

                {/* Prize Info */}
                <p className="font-normal" style={{ fontSize: '1.125rem', lineHeight: '1.875rem' }}>
                  {friendlyValueDescription(metric, reset)}
                </p>
              </div>

              {/* Rankings Table */}
              <div
                className="w-full flex flex-col flex-start gap-2 text-left"
                style={{
                  padding: '0.4375rem 0rem',
                  width: isLG ? '46rem' : '100%'
                }}
              >
                {/* Table Headers */}
                <div
                  className="hidden lg:flex flex-row uppercase font-semibold text-gm-silver-100"
                  style={{ fontSize: '0.625rem', lineHeight: '140%' }}
                >
                  <div className={`${colW[0]}`}>Position</div>
                  <div className={`${colW[1]}`}>{participantsType}</div>
                  <div className={`${colW[2]}`}>{metricEntry[metric].valuesTitle}</div>
                  <div className={`${colW[3]}`}>&nbsp;</div>
                </div>

                {/* Table Rows */}
                <div
                  className="flex flex-col flex-start gap-3.5 text-gm-black-100"
                  style={{ padding: '0.3125rem 0rem' }}
                >
                  {/* Rankings (by metric) */}
                  <RankingsByMetric
                    colW={colW}
                    colWMobile={colWMobile}
                    leaderboardInfo={leaderboard.info}
                    metricEntry={metricEntry}
                    metric={metric}
                    metricIndex={mId}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>

    </ContainerLayout>
  )
}

export default Rankings
