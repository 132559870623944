// NOTE: don't change `var` to `let` or `const`; you'll get
// "ReferenceError: can't access lexical declaration 'global$1' before initialization"
var global = global || window;

import './index.css'

import { StrictMode } from 'react'
import * as serviceWorker from './serviceWorker'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { Domains } from '@givematch/common'
import AppContainer from "./App";
import {initializeGTM} from "~/gtm";
import { getGivematchEnvironmentFromURL, initSentryInBrowser, StageName } from '@lib/services'
// import { I18nextProvider, initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import globalen from './locales/en/translation.json'
// import globalfr from './locales/fr/translation.json'
// import i18n from "i18next"
/* eslint-disable */
(function (i, s, o, g, r, a, m) {
  i['GoogleAnalyticsObject'] = r;
  i[r] = i[r] || function () {
    (i[r].q = i[r].q || []).push(arguments)
  }, i[r].l = 1 * new Date();
  a = s.createElement(o),
    m = s.getElementsByTagName(o)[0];
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

ga('create', window.location.host.endsWith('givematch.com') ? 'UA-189645571-1' : 'UA-241937121-1', 'auto');
ga('require', 'ecommerce');

(function (f, b) {
  if (!b.__SV) {
    var e, g, i, h;
    window.mixpanel = b;
    b._i = [];
    b.init = function (e, f, c) {
      function g (a, d) {
        var b = d.split('.');
        b.length === 2 && (a = a[b[0]], d = b[1]);
        a[d] = function () {
          a.push([d].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }

      var a = b;
      typeof c !== 'undefined' ? a = b[c] = [] : c = 'mixpanel';
      a.people = a.people || [];
      a.toString = function (a) {
        var d = 'mixpanel';
        c !== 'mixpanel' && (d += '.' + c);
        a || (d += ' (stub)');
        return d
      };
      a.people.toString = function () {
        return a.toString(1) + '.people (stub)'
      };
      i = 'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(' ')
      for (h = 0; h < i.length; h++) g(a, i[h]);
      var j = 'set set_once union unset remove delete'.split(' ');
      a.get_group = function () {
        function b (c) {
          d[c] = function () {
            call2_args = arguments;
            call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
            a.push([e, call2])
          }
        }

        for (var d = {}, e = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)), c = 0; c < j.length; c++) b(j[c]);
        return d
      };
      b._i.push([e, f, c])
    };
    b.__SV = 1.2;
    e = f.createElement('script');
    e.type = 'text/javascript';
    e.async = !0;
    e.src = typeof MIXPANEL_CUSTOM_LIB_URL !== 'undefined'
      ? MIXPANEL_CUSTOM_LIB_URL : f.location.protocol === 'file:' && '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//) ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js' : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
    g = f.getElementsByTagName('script')[0];
    g.parentNode.insertBefore(e, g)
  }
})(document, window.mixpanel || [])
window.mixpanel.init('a441c8f70a83abec3b04e9e9e1bc7c32', { api_host: 'https://api-eu.mixpanel.com', batch_requests: true })

initializeGTM()
/* eslint-enable */

// Figure out environment from URL
const environment = getGivematchEnvironmentFromURL(Domains.givematchDomain.bind(Domains))

// Prevent search engine spiders from crawling the website
if (environment !== StageName.prod) {
  const meta = document.createElement('meta')
  meta.name = 'robots'
  meta.content = 'noindex'
  document.querySelector('head').appendChild(meta)
}

// Initialize Sentry (crash analytics)
const dsn = 'https://0725e60283dde8391cef7166e52d5774@o4505828020125696.ingest.sentry.io/4506082901164032'
// - control which URLs distributed tracing should be enabled for
const tracePropagationTargetsStaging = ['https://givematch.link/api'] // private envs & staging
const tracePropagationTargetsProduction = ['https://givematch.com/api']
initSentryInBrowser(environment, dsn, tracePropagationTargetsStaging, tracePropagationTargetsProduction)

//Initialize i18n
// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     lng: 'en',
//     debug: true,
//     interpolation: {
//       escapeValue: true,
//     },
//     resources : {
//       en: { global: globalen },
//       fr: { global: globalfr },
//     }
//   });
const container = document.getElementById('root')

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement).render(
    <StrictMode>
    {/* Add I18nextProvider */}
    {/* <I18nextProvider i18n={i18next}> */}
    <AppContainer />
    {/* </I18nextProvider> */}
  </StrictMode>
  )
} else {
  createRoot(rootElement).render(
    <StrictMode>
      <AppContainer />
    </StrictMode>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
