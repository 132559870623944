import { Domains } from '../constants'

export function isProd (): boolean {
  if (typeof window !== 'undefined') {
    return window.location.hostname.endsWith(Domains.prod)
  }
  return false
}

const GM_URL = `https://${isProd() ? Domains.prod : Domains.beta}/`
export const givematchFundraiserUrl = (fundraiserId: string): string => {
  return `${GM_URL}?fr=${fundraiserId}`
}
export const givematchFundraiserCreateUrl = (): string => {
  return `${GM_URL}fundraiser/create/select-location`
}
export const generateURL = (path: string): string => {
  return `${GM_URL}${path}`
}

export const isFileSizeInMbValid = (file: File, maxSizeInMb: number): boolean => {
  return file.size <= maxSizeInMb * 1024 * 1024
}

export const extractValueFromURL = (url: string, paramKey: string): string | null => {
  const parsedUrl = new URL(url)
  const linkValue = parsedUrl.searchParams.get(paramKey)

  if (linkValue) {
    return linkValue
  }

  const pathname = parsedUrl.pathname
  const match = pathname.match(new RegExp(`^/${paramKey}/([^/]+)`))

  return match ? match[1] : null
}
