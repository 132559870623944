import { type FC } from 'react'
import { Tracking } from '~/service/tracking'
import { Navigation } from '~/service/navigation'
import { Button } from '@lib/components'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { CauseType, GivematchTestId, LandingPageType } from '@givematch/common'
import { useNavigate } from 'react-router-dom'
import { CharityPartners } from '~/components/ramadan/CharityPartners'
import { Experiment, Variant } from '@lib/react-ab-test'
import { ActiveExperiments, RamadanHomeHeadlineVariant } from '~/splitTesting/splittesting'

interface props {
  charityId?: string
  charityName?: string
  logoImage?: string
}

export const HeadlineWithDonate: FC<props> = () => {
  const { setDonation, donation } = useDonationPlan()
  const navigate = useNavigate()
  return <section className="px-4 py-2 sm:px-20 md:px-24 lg:px-40 lg:flex-1 w-full ">
    <div className="my-auto flex flex-col items-center">
      <div className="flex flex-col justify-evenly sm:py-10 items-center sm:items-start sm:mr-4 mb-5">
        <Experiment name={ActiveExperiments.RamadanHomeHeadline}>
          <Variant name={RamadanHomeHeadlineVariant.youGiveWeMatch}>
            <div className="flex flex-col justify-center text-4xl font-bold tracking-tighter md:text-5xl lg:text-6xl whitespace-nowrap text-center sm:text-left mb-5 sm:mb-10 mx-auto">
              <h2 className="text-gm-green text-6xl mx-auto tracking-tighter">You Give</h2>
              <h2 className='text-6xl mx-auto tracking-tighter'>We Match</h2>
            </div>
          </Variant>
          <Variant name={RamadanHomeHeadlineVariant.doubleYourReward}>
            <div className="flex flex-col justify-center text-4xl font-bold tracking-tighter md:text-5xl lg:text-6xl whitespace-nowrap text-center sm:text-left mb-5 sm:mb-10 mx-auto">
              <h2 className="text-gm-green text-6xl mx-auto tracking-tighter">Double Your</h2>
              <h2 className='text-6xl mx-auto tracking-tighter'>Reward</h2>
            </div>
          </Variant>
          <Variant name={RamadanHomeHeadlineVariant.doubleYourRamadanReward}>
            <div className="flex flex-col justify-center text-4xl font-bold tracking-tighter md:text-5xl lg:text-6xl whitespace-nowrap text-center sm:text-left mb-5 sm:mb-10 mx-auto">
              <h2 className="text-gm-green text-4xl mx-auto tracking-tighter">Double Your</h2>
              <h2 className='text-4xl mx-auto tracking-tighter'>Ramadan Reward</h2>
            </div>
          </Variant>
        </Experiment>
        <p className='text-center tracking-tighter mx-auto font-semibold'>Givematch doubles your donations so you double your Ramadan reward</p>
      </div>
      <Button
        variant={'gm-primary'}
        data-test-id={GivematchTestId.donateButton}
        onClick={() => {
          setDonation({ ...donation, amount: 0, causeType: CauseType.CAUSE, fundraiserTitle: '', fundraiserID: '', causeAmounts: {}, landingPage: LandingPageType.Ramadan })
          Tracking.proceedToChooseCharity(0, donation.donationCurrency)
          navigate(Navigation.ramadanSelectCharity())
        }}
        className='w-80 text-5xl tracking-tighter shadow-xl rounded-lg'>
        Donate
      </Button>
      <CharityPartners/>
    </div>
  </section>
}
