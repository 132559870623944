import { type FC, type PropsWithChildren, type ReactNode, type CSSProperties } from 'react'

interface ContainerLayoutProps {
  className?: string
  style?: CSSProperties
  children: ReactNode | ReactNode[]
}

export const ContainerLayout: FC<PropsWithChildren<ContainerLayoutProps>> = ({ className, style, children }) => {
  return (
    <div className={`w-full ${className ?? ''}`} style={style ?? {}}>
      {children}
    </div>
  )
}
