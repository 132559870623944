import { withAuth } from '@lib/hooks'
import { callService } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'
import { type donationSettingData } from '~/data/donationFormData'
import { type EventDonation } from './charity'

export interface DonationResponse {
  donations: Donation[]
}
export interface Donation extends EventDonation {
  charity: string
  charity_name: string
  charity_marketing: boolean
  email: string
  post_code: string
  country: string
  tip: number
  fundraiser_id: string
  fundraiser_title: string
  image_path: string
  youtube_url: string
  fundraiser_notification: boolean
  charity_cover_image: string
}
export const getAlldonations = withAuth(async (): Promise<DonationResponse> => {
  return await callService(`/api/donor/donations`, HttpRequestMethod.GET)
})
export const getDonationDetails = withAuth(async (shareCode: string): Promise<Donation> => {
  return await callService(`/api/donor/${shareCode}/details`, HttpRequestMethod.GET)
})
export const updateDonationSetting = withAuth(async (updateDonation: donationSettingData): Promise<Donation> => {
  return await callService(`/api/donor/${updateDonation.share_code}/update`, HttpRequestMethod.PUT, updateDonation)
})
