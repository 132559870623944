import { createContext, type FC, useCallback, useContext } from 'react'
import { useSessionStorage } from '@lib/hooks'
import { type Charity } from '~/state/charity'
import { CauseType } from '@givematch/common'
import { type BillingDetails } from '~/state/paymentTypes'
import { PaymentType } from '~/data/paymentFormData'
import { Currency, getUserCurrency } from '@lib/services'
import { SessionStorage } from '~/service/sessionStorage'

export interface DonationPlan {
  amount: number
  eventID: string
  causeAmounts: Record<string, number>
  matchedAmount: number
  charity: Charity
  causeType: CauseType
  fundraiserID: string
  fundraiserTitle: string
  chainCode: string
  billingDetails: BillingDetails
  paymentDetails: PaymentDetailsData
  donationCurrency: Currency
  landingPage: string
}

export interface PaymentDetailsData {
  giftAid: boolean
  payFees: boolean
  taxReceipt: boolean
  tipPercent: number
  paymentType: PaymentType
  remainAnonymous: boolean
}

interface DonationPlanResponse {
  donation: DonationPlan
  setDonation: (donation: DonationPlan) => void
  resetDonation: () => void
  setCharity: (charity: Charity) => void
}

const getDefaultDonationPlan = (): DonationPlan => {
  return {
    amount: 0.00,
    causeAmounts: {},
    eventID: '',
    matchedAmount: 0.00,
    causeType: CauseType.CAUSE,
    fundraiserID: '',
    fundraiserTitle: '',
    chainCode: '',
    charity: {
      charity: '',
      charity_api: '',
      status: '',
      campaign: '',
      country: '',
      name: '',
      about: '',
      causes: [],
      gift_aid: false,
      tax_receipt: false,
      currency: Currency.GBP,
      logo_image: ''
    },
    billingDetails: {
      email: '',
      firstName: '',
      lastName: '',
      postcode: '',
      country: '',
      firstAddress: ''
    },
    paymentDetails: {
      giftAid: false,
      taxReceipt: false,
      payFees: true,
      tipPercent: 15,
      paymentType: PaymentType.applePay,
      remainAnonymous: false
    },
    donationCurrency: getUserCurrency(),
    landingPage: ''
  }
}
const donationPlanContext = createContext<DonationPlanResponse>({
  donation: getDefaultDonationPlan(),
  setDonation: (donation: DonationPlan) => console.log(donation),
  resetDonation: () => {},
  setCharity: (charity: Charity) => console.log(charity)
})

export const DonationPlanProvider: FC = ({ children }) => {
  const [donation, setDonation] = useSessionStorage(SessionStorage.donationData, getDefaultDonationPlan())

  const setCharity = (charity: Charity): void => {
    const causes = donation.charity && donation.charity.charity === charity.charity ? donation.causeAmounts : {}
    setDonation({ ...donation, charity, causeAmounts: causes })
  }

  const resetDonation = useCallback((): void => {
    setDonation(getDefaultDonationPlan())
  }, [setDonation])

  const value = {
    donation,
    setDonation,
    resetDonation,
    setCharity
  }
  return <donationPlanContext.Provider value={value} >{children}</donationPlanContext.Provider>
}
export const useDonationPlan = (): DonationPlanResponse => {
  return useContext(donationPlanContext)
}
