import { type FC } from 'react'

export const YoutubeEmbed: FC<{ embedUrl: string, classname: string }> = ({ embedUrl, classname }) => {
  return <div className="video-responsive">
    <iframe
      src={embedUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className={classname}
    />
  </div>
}
