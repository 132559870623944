import { type FC, useState } from 'react'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import profile from '~/assets/icons/profile.svg'
import info from '~/assets/info.svg'
import { Button, Spinner } from '@lib/components'
import { useNavigate, useParams } from 'react-router-dom'
import { getDonationDetails, updateDonationSetting } from '~/state/donation'
import { useMutation, useQuery } from 'react-query'
import { FundraiserImageDisplay } from './DonorDonationCard'
import { displayTidyCurrency, GetCurrencyByString } from '@lib/services'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { type donationSettingData } from '~/data/donationFormData'
import { Navigation } from '~/service/navigation'

export const DonationCharityImageDisplay: FC<{ logoExist: boolean, charityID: string, setLogoExist: (val: boolean) => void, name: string, coverImage: string }> = ({ logoExist, charityID, setLogoExist, name, coverImage }) => {
  return <>
        {logoExist &&
            <img className="cardImage h-full object-contain" src={`/imageuploads/charity/${charityID}/${coverImage}`} alt={charityID} onError={() => setLogoExist(false)}></img>
        }
        {!logoExist && <div className='h-36 bg-gm-green flex justify-center items-center'>
            <p className='   text-white text-2xl'>{name !== '' ? name : charityID}</p></div>}
    </>
}
export const DonationDetails: FC = () => {
  const { shareCode } = useParams() as { shareCode: string }
  const navigate = useNavigate()
  const [logoExist, setLogoExist] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const { isLoading: donationLoading, isError: donationError, data: donation } = useQuery(['shareCode', shareCode], async () => getDonationDetails(shareCode), {
    onSuccess (data) {
      setValue('anonymous', data.anonymous)
      setValue('share_code', data.share_code)
      setValue('fundraiser_notification', data.fundraiser_notification)
    }
  })
  const donationAmount = Object.values(donation?.cause_donations ?? {}).reduce((acc, value) => acc + value, 0)
  const form = useForm<donationSettingData>({
    defaultValues: {
      anonymous: donation?.anonymous,
      share_code: donation?.share_code,
      fundraiser_notification: donation?.fundraiser_notification
    }
  })
  const { register, getValues, setValue } = form
  const updateDonation = useMutation(async () => {
    // prepare record for update
    setProcessing(true)
    setError('')
    const updateDonation: donationSettingData = getValues()
    updateDonation.share_code = donation?.share_code ?? ''
    // send record to the api
    const response = await updateDonationSetting(updateDonation)
    return response
  }
  , {
    onSuccess: () => {
      setProcessing(false)
    },
    onError: () => {
      setError('We\'re sorry there has been a problem updating your donation')
      setProcessing(false)
    }

  }

  )
  return <FundraiserLayout title="">
        {(donationLoading) && <Spinner className="m-auto" />}
        {(donationError) && <p className="px-4 mt-4 text-gm-red"> We were unable to fetch your donation details </p>}
        {!donationLoading && !donationError && donation &&
            <section>
                {
                    donation.fundraiser_id !== ''
                      ? <FundraiserImageDisplay donation={donation} />

                      : <DonationCharityImageDisplay logoExist={logoExist} charityID={donation.charity} setLogoExist={setLogoExist} name={donation.charity_name} coverImage={donation.charity_cover_image} />
                }
                <form id="EditDonationForm">
                    <div>
                        {error !== '' && <p
                            className="p-4 text-sm text-right text-gm-red">{error}</p>}
                    </div>

                    <div className='flex flex-col gap-y-2 py-4 border-b border-gm-green'>
                        <span className=' text-gm-green text-sm'> Thank you for your donation to:</span>

                        {donation.fundraiser_id === '' ? <h4 className='font-bold'> {donation.charity_name}</h4>
                          : <><h4 className='font-bold'> {donation.fundraiser_title}</h4>
                                <div className='mb-4'>
                                    <span> {`${donation.first_name} ${donation.last_name}`} is organizing this fundraiser on behalf of {donation.charity_name}</span>
                                </div></>}
                    </div>
                    <div className='border-b py-4 border-gm-green'>
                        <h6 className='font-semibold'> Display</h6>
                        <div className='w-full py-4 flex gap-x-2 items-center'>
                            <img src={profile} alt="donor" className='h-6 w-6' />
                            <span> {`${getValues().anonymous ? 'Anonymous' : donation.first_name + ' ' + donation.last_name}`}  </span>
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <h3 className="text-gm-green font-semibold"> Option  </h3>
                            <div className="flex flex-row gap-x-4 items-center">
                                <label className='switch'>
                                    <input onClick={() => updateDonation.mutate()} type="checkbox" {...register('anonymous')} checked={getValues().anonymous} disabled={processing} />
                                    <span className='slider round'></span>
                                </label>
                                <span> Make my donation anonymous </span>
                            </div>
                        </div>
                    </div>
                    {getValues().anonymous && <div className='border-b py-4 border-gm-green'>
                        <div className='w-full flex gap-x-2 items-center'>
                            <img src={info} alt="information" className='h-6 w-6' />
                            <span className=' text-sz-gray-400'> {`Your information is hidden from the donor list ${donation.fundraiser_id !== '' ? 'of this fundraiser' : ''}`}  </span>
                        </div>
                    </div>}
                    <div className='border-b py-4 border-gm-green'>
                        <h6 className='font-semibold'> Donation Details</h6>
                        <div className='flex flex-col gap-y-2 my-4 text-sm'>
                            <div className='flex justify-between'>
                                <h5>Email Address:</h5>
                                <h6 className='font-semibold'> {donation.email}</h6>
                            </div>
                            <div className='flex justify-between'>
                                <h5>Date:</h5>
                                <h6 className='font-semibold'>  {moment(donation.created_time).format('DD/MM/YYYY')}</h6>
                            </div>
                            <div className='flex justify-between'>
                                <h5>Amount Donated:</h5>
                                <h6 className='font-semibold'>  {displayTidyCurrency(donationAmount, GetCurrencyByString(donation.currency))}</h6>
                            </div>
                            <div className='flex justify-between'>
                                <h5>Tip for Givematch:</h5>
                                <h6 className='font-semibold'> {displayTidyCurrency(donation.tip, GetCurrencyByString(donation.currency))}</h6>
                            </div>
                            <div className='flex justify-between'>
                                <h5>GiftAid:</h5>
                                <h6 className='font-semibold'> {donation.gift_aid ? 'Opted for GiftAid' : 'Not opted for GiftAid'}</h6>
                            </div>
                        </div>
                    </div>
                    {donation.fundraiser_id !== '' && <div className='flex flex-col border-b py-4 gap-y-4 border-gm-green'>
                        <h6 className='font-semibold'> Notifications </h6>
                        <div className="flex flex-row gap-x-4 items-center">
                            <label className='switch'>
                                <input onClick={() => updateDonation.mutate()} type="checkbox" {...register('fundraiser_notification')} checked={getValues().fundraiser_notification} disabled={processing} />
                                <span className='slider round'></span>
                            </label>
                            <span>Receive fundraiser updates </span>
                        </div>
                        {getValues().fundraiser_notification && <span className=' text-sz-gray-300'> You will receive all updates from this fundraiser </span>}
                    </div>}
                </form>
                <div className='flex flex-col py-4 gap-y-4'>
                    {donation.fundraiser_id !== ''
                      ? <>
                            <Button onClick={() => navigate(Navigation.fundraiserLink(donation.fundraiser_id))} variant='gm-secondary-md' className='w-full bg-gm-green font-semibold text-lg text-white border-gm-green py-1'>
                                Donate Again
                            </Button>
                            <Button onClick={() => navigate(Navigation.shareReferralLink(donation.share_code, donation.charity))} variant='gm-secondary-md' className='w-full bg-gm-yellow font-semibold text-lg border-gm-yellow py-1'>
                                Share Referral Link
                            </Button>
                            <div onClick={() => navigate(Navigation.fundraiserLink(donation.fundraiser_id))} className='flex justify-center underline text-sm cursor-pointer'>
                                <span> View Fundraiser</span>
                            </div>
                        </>
                      : <>
                            <Button onClick={() => navigate(Navigation.shareReferralLink(donation.share_code, donation.charity))} variant='gm-secondary-md' className='w-full bg-gm-yellow font-semibold text-lg border-gm-yellow py-1'>
                                Share Referral Link
                            </Button>
                        </>}

                </div>
            </section>
        }

    </FundraiserLayout>
}
