import { type Dispatch, type FC, type SetStateAction } from 'react'
import { LoginForm } from '@lib/components'
import { Link } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { Tracking } from '~/service/tracking'

interface Props {
  setSignIn: Dispatch<SetStateAction<boolean>>
  email?: string
  emailUnEditable?: boolean
}
export const Login: FC<Props> = ({ setSignIn }) => {
  const trackLoginEmail = (email: string): void => {
    Tracking.loginEmail(email)
  }
  const trackLoginCode = (email: string): void => {
    Tracking.loginCode(email)
  }
  return <div className="min-h-screen w-full flex flex-col md:items-center">
    <Header />
    <div className='my-10 flex flex-col mx-5 max-w-lg md:w-1/2'>
      <span className='text-2xl font-bold mb-10'>Sign In</span>
      <LoginForm fontVariant="sz" buttonVariant="sz-primary" trackingSignInEmail={(e) => trackLoginEmail(e)} trackingLogInCode={(e) => trackLoginCode(e)}/>
      <div className='mt-10 flex flex-row'>
        <p>Don't have an account?</p>
        <Link className="px-2 text-gm-green underline" to={Navigation.fundraiserEnterTitle()} onClick={() => setSignIn(false)}>Sign Up</Link>
      </div>
    </div>
    <Footer />
  </div>
}

export default Login
