import { type FC, useState } from 'react'
import animal from '~/assets/fundraiser/animal.jpeg'
import heart from '~/assets/fundraiser/heart.jpeg'
import child from '~/assets/fundraiser/child.svg'
import helpElderly from '~/assets/fundraiser/help-elderly.webp'
import animalDog from '~/assets/fundraiser/animal-dog.webp'
import education from '~/assets/fundraiser/education.webp'
import food from '~/assets/fundraiser/food.jpeg'
import water1 from '~/assets/fundraiser/water-1.jpeg'
import women from '~/assets/fundraiser/women.jpeg'
import shelter from '~/assets/fundraiser/shelter.webp'
import { GivematchFundraiserTestIds } from '@lib/testing'

interface props {
  setImageSrc: (data: string) => void
}

export const FundraiserDefaultImages: FC<props> = ({ setImageSrc }) => {
  const [defaultImageSelected, setDefaultImageSelected] = useState(-1)
  const images = [child, animal, heart, helpElderly, animalDog, education, food, water1, women, shelter]

  const onImageSelect = (index: number): void => {
    if (index === defaultImageSelected) {
      setImageSrc(images[defaultImageSelected])
    } else {
      setDefaultImageSelected(index)
    }
  }

  const ImageCard: FC<{ selected: boolean, image: string, index: number }> = ({ selected, image, index }) => {
    return <div className='flex flex-col h-24 w-24 cursor-pointer' onClick={() => onImageSelect(index)}>
      <div className='flex justify-center border-2 border-gm-green rounded-lg'>
        <img key={index} className='w-24 rounded-lg' src={image} alt="Select cover pic"
        data-test-id={`${GivematchFundraiserTestIds.defaultImage_}${index}`}/>
      </div>
      <div className='h-1/4'>
        <span className={`${!selected ? 'hidden' : ''} flex justify-center  bg-gm-yellow text-gm-green-900 font-bold text-sm border-2 border-gm-green  rounded-lg`}>Use Image</span>
      </div>

    </div>
  }

  return <div className="flex flex-col rounded-lg h-72  mt-5 px-5 py-2 border-gm-green-200 border-2 overflow-auto">
    <span className="font-bold">Select from default images</span>
    <div className='flex flex-wrap gap-x-4 gap-y-5 mt-3'>
      {images.map((image, index) => {
        const selectedImage = defaultImageSelected === index
        return <ImageCard key={index} selected={selectedImage} image={image} index={index} />
      }
      )}
    </div>

  </div>
}
