export enum LandingPageType {
  Home = 'HOME',
  Charity = 'CHARITY',
  Fundraiser = 'FUNDRAISER',
  CharityRoot = 'CHARITY_ROOT',
  Ramadan = 'RAMADAN',
  CharityLandingPage = 'CHARITY_LANDING_PAGE'
}

export const isRamadan = (landingPageType: string): boolean => landingPageType === LandingPageType.Ramadan
