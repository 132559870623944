import { type FC, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Button } from '@lib/components'
import { type Currency, displayTidyCurrency } from '@lib/services'
import { Experiment, Variant } from '@lib/react-ab-test'
import { matchedDonationAmount } from '../service/maximumDonationAmounts'
import Header from '~/components/Header'
import { GivematchTestId } from '@givematch/common'
import { Tracking } from '../service/tracking'
import NumberScroller from 'react-number-scroller'
import Footer from '~/components/Footer'
import { Navigation } from '~/service/navigation'
import { ActiveExperiments, ShareButtonTextVariants } from '~/splitTesting/splittesting'

const SharePage: FC = () => {
  const navigate = useNavigate()
  const { shareId } = useParams() as { shareId: string }
  const location = useLocation()
  const state = location.state as { donation: number, charityID: string, currency: Currency, email: string }

  const matchedAmount = matchedDonationAmount(state.donation, state.currency)

  useEffect(() => Tracking.shareInviteLink())

  return <div
    data-test-id={GivematchTestId.thankYouPage}
    className="flex flex-col justify-items-stretch items-center min-h-screen min-h-screen-ios">
    <Header />
    <div className="py-4 px-4 sm:px-20 md:px-24 lg:px-40 flex flex-col">
      <div className='flex flex-col gap-2 items-center my-5'>
        <span className="text-4xl">
          &#127881;
        </span>
        <span className="text-lg font-semibold">
          Thank you for your donation!
        </span>
        <span className="text-xs">
          &#9989; You donated {displayTidyCurrency(state.donation, state.currency)}
        </span>
      </div>
      <div className="p-4 border-2 border-gm-green rounded-xl bg-gm-gray-50 md:border-0 md:bg-transparent md:grid md:grid-cols-3 md:grid-rows-6">
        <h1 className="md:mt-0 text-3xl font-semibold tracking-tighter leading-tight md:text-3xl text-gm-green-900 md:col-start-3 md:row-start-1">
          Your {displayTidyCurrency(state.donation, state.currency)} will turn into {displayTidyCurrency(matchedAmount, state.currency)}, <span
            className="text-gm-green">when you get 2 friends to donate</span>
        </h1>
        <div className="mt-3 lg:mt-0 py-10 border-4 border-gm-black text-black shadow-md rounded-lg bg-gm-green text-7xl xl:text-9xl font-bold flex items-center justify-center md:mt-0 md:col-start-1 md:col-end-3 md:w-11/12 md:order-first md:row-span-3">
          <NumberScroller from={state.donation / 100} to={matchedAmount / 100}
            renderFrequency={15}
            delay={1000}
            decimalPlaces={2}
            toLocaleStringProps={[
              'ja-JP',
              {
                style: 'currency',
                currency: state.currency
              }
            ]} />
        </div>
        <div className="mt-5 lg:mt-8 w-full md:col-start-3 ">
          <Button
            variant="gm-share"
            className="w-full rounded-lg shadow-md"
            onClick={() => {
              Tracking.shareInviteLinkButton()
              navigate(Navigation.shareReferralLink(shareId, state.charityID))
            }}>
              <Experiment name={ActiveExperiments.ShareButtonText}>
                <Variant name={ShareButtonTextVariants.shareLink}>
                  <span>
                    Share link
                  </span>
                </Variant>
                <Variant name={ShareButtonTextVariants.inviteFriends}>
                  <span>
                    Invite friends
                  </span>
                </Variant>
              </Experiment>
          </Button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
}

export default SharePage
