import { type FC } from 'react'
import { EmailXL, Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const Contact: FC = () => {
  return <div className="flex flex-col min-h-screen">
    <Header />
    <section className="bg-top bg-cover min-h-600 md:min-h-screen">
      <Page skinny className="px-10 pt-24 font-medium min-h-600 md:min-h-screen text-gm-green">
        <h1 className="mt-6 text-2xl font-bold md:text-4xl ">
          Need some help?
        </h1>
        <p className="mt-2 md:text-xl md:mt-4">
          We've made some updates to our contact channels. If you have any inquiries or need assistance, you can reach us via email.
          <br />
          Here's how you can get in touch.
        </p>

        <div className="bg-gm-green-500 my-8 h-0.5 md:my-12"></div>

        <a href="mailto:contact@givematch.com">
          <div className="md:flex md:items-start">
            <span className="inline-block rounded-full">
              <EmailXL className='bg-gm-gray-100 rounded-full' />
            </span>
            <div className="flex-1 mt-2 ml-4 md:ml-8">
              <p><strong>Email us</strong> for donor inquiries</p>
              <p className="mt-2 font-bold"><a href="mailto:contact@givematch.com">contact@givematch.com</a></p>
            </div>
          </div>
        </a>
        <a href="mailto:charity@givematch.com">
          <div className="md:flex md:items-start mt-5">
            <span className="inline-block rounded-full">
              <EmailXL className='bg-gm-gray-100 rounded-full' />
            </span>
            <div className="flex-1 mt-2 ml-4 md:ml-8">
              <p><strong>Email us</strong> for charity inquiries</p>
              <p className="mt-2 font-bold"><a href="mailto:charity@givematch.com">charity@givematch.com</a></p>
            </div>
          </div>
        </a>
        <a href="mailto:partners@givematch.com">
          <div className="md:flex md:items-start mt-5">
            <span className="inline-block rounded-full">
              <EmailXL className='bg-gm-gray-100 rounded-full' />
            </span>
            <div className="flex-1 mt-2 ml-4 md:ml-8">
              <p><strong>Email us</strong> for Partners/Philanthropist inquiries</p>
              <p className="mt-2 font-bold"><a href="mailto:partners@givematch.com">partners@givematch.com</a></p>
            </div>
          </div>
        </a>
        <div className="mb-5">
            <p className="mt-10 md:mt-10 text-sm md:text-lg">Our opening hours are Monday to Friday from 9:00 AM to 5:00 PM, every day.</p>
        </div>
      </Page>
    </section>
    <Footer />
  </div>
}

export default Contact
