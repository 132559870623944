import { type Currency } from '@lib/services'

const maximumDonationAmounts: Record<Currency, number> = {
  gbp: 20000,
  usd: 20000,
  cad: 20000,
  aud: 20000,
  zar: 200000,
  eur: 10000,
  chf: 10000,
  nok: 100000,
  idr: 100000,
  myr: 100000,
  sgd: 100000
}

export const maxDonationByCurrency = (currency: Currency): number => {
  return maximumDonationAmounts[currency]
}

export const matchedDonationAmount = (amount: number, currency: Currency): number => {
  const maxMatchableDonation = maxDonationByCurrency(currency)

  if (amount > maxMatchableDonation) {
    return amount + maxMatchableDonation
  }

  return amount * 2
}
