/* eslint-disable */
// @ts-nocheck
export const initializeDataLayer = (): void => {
  const newElement = document?.createElement('script')
  newElement.async = 1
  newElement.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11118568126'
  const element = document?.getElementsByTagName('script')[0]
  element.parentNode?.insertBefore(newElement, element)

  window.dataLayer = window.dataLayer || []

  window.gtag = function gtag () { dataLayer.push(arguments) }

  gtag('js', new Date())
  gtag('config', 'AW-11118568126')
}
/* eslint-enable */
