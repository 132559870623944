import { type FC, type ReactElement, useEffect } from 'react'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

import '~/css/home.css'

import StepsExplainer from '~/components/StepsExplainer'
import DonationHero from '~/components/DonationHero'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { SessionStorage } from '~/service/sessionStorage'
import { Navigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { LandingPageType } from '@givematch/common'

const landingParamKey = 'landing'
const fundraiserParamKey = 'fr'
const shareLinkKey = 'link'

const getRedirectForQuery = (query: string): ReactElement | undefined => {
  let redirectElement: ReactElement | undefined
  const searchParams = new URLSearchParams(query)
  const landing = searchParams.get(landingParamKey)
  const chainCode = searchParams.get(shareLinkKey)
  const fundraiserId = searchParams.get(fundraiserParamKey)
  if (landing === LandingPageType.Ramadan.toLowerCase()) {
    searchParams.delete(landingParamKey)
    redirectElement = <Navigate to={`${Navigation.ramadanHome()}?${searchParams.toString()}`} />
  } else if (chainCode) {
    searchParams.delete(shareLinkKey)
    redirectElement = <Navigate to={`${Navigation.linkChainCode(chainCode)}?${searchParams.toString()}`} />
  } else if (fundraiserId) {
    searchParams.delete(fundraiserParamKey)
    redirectElement = <Navigate to={`${Navigation.fundraiserLink(fundraiserId)}?${searchParams.toString()}`} />
  }
  return redirectElement
}

const Home: FC = () => {
  const { donation, setDonation } = useDonationPlan()
  useEffect(() => {
    const ClearExistingData = (): void => {
      setDonation({ ...donation, chainCode: '' })
      // clear any shared charity from storage
      sessionStorage.removeItem(SessionStorage.partnerCharityKey)
      sessionStorage.removeItem(SessionStorage.referrerCharityKey)
      sessionStorage.removeItem(SessionStorage.campaignName)
      sessionStorage.removeItem(SessionStorage.currentFundraiser)
    }
    ClearExistingData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const redirectComponent = getRedirectForQuery(window.location.search)
  if (redirectComponent) {
    return redirectComponent
  }

  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    <DonationHero landingPage={LandingPageType.Home}/>
    <StepsExplainer />
    <Footer />
  </div>
}

export default Home
