import { HttpRequestMethod } from 'http-core-constants'

import { callService } from '@lib/services'

export interface RootLeaderboardDetails {
  leaderboardId: string
  name: string
  slug: string
}

export interface ListRootLeaderboardsResponse {
  leaderboard_id: string
  name: string
  slug: string
}

export const listRootLeaderboards = async (): Promise<RootLeaderboardDetails[]> => {
  const boards: ListRootLeaderboardsResponse[] = await callService('/api/leaderboards', HttpRequestMethod.GET)
  return boards.map((b: ListRootLeaderboardsResponse) => {
    const details: RootLeaderboardDetails = {
      leaderboardId: b.leaderboard_id,
      name: b.name,
      slug: b.slug
    }
    return details
  })
}
