import { type PaymentRequest, type PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { type Country } from '@lib/services'
import { type PaymentCapabilities, usePaymentRequest } from './usePaymentRequest'

import { useMutation } from 'react-query'
import { createPaymentIntent, updatePaymentIntent } from '~/state/payment'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { type PaymentFormData } from '~/data/paymentFormData'
import { RecaptchaV3 } from '@lib/services'

interface FullName {
  firstName: string
  lastName: string
}

function getFullName (event: PaymentRequestPaymentMethodEvent): FullName {
  const separator = ' '
  const parts = event.payerName!.split(separator)

  const lastName = parts[0]
  const firstName = parts.length > 1 ? parts.slice(1).join(separator) : lastName

  return {
    firstName,
    lastName
  }
}

type successHandler = (form: PaymentFormData) => void

interface useSchedulePaymentResponse {
  paymentRequest: PaymentRequest | null
  completeCardPayment: (data: PaymentFormData) => Promise<any>
  isLoading: boolean
  error: any
  submit: (data: PaymentFormData) => void
  paymentCapabilities: PaymentCapabilities | null
}
const recaptcha = new RecaptchaV3('payment')

export const useDonationPayment = (onSuccess: successHandler, formData: PaymentFormData): useSchedulePaymentResponse => {
  const { donation } = useDonationPlan()
  const elements = useElements()
  const stripe = useStripe()

  const createUpdatePaymentIntent = async (data: PaymentFormData): Promise<string> => {
    let clientSecret = sessionStorage.getItem('clientSecret')
    let paymentIntentID = sessionStorage.getItem('paymentIntentID')
    let shareCode = sessionStorage.getItem('shareCode')
    const token = await recaptcha.getToken()
    data.token = token

    if (clientSecret && paymentIntentID && shareCode) {
      await updatePaymentIntent(shareCode, paymentIntentID, data, donation)
    } else {
      const response = await createPaymentIntent(donation, data)
      clientSecret = response.client_secret
      paymentIntentID = response.payment_intent_id
      shareCode = response.share_code

      sessionStorage.setItem('clientSecret', clientSecret!)
      sessionStorage.setItem('paymentIntentID', paymentIntentID!)
      sessionStorage.setItem('shareCode', shareCode!)
    }
    return clientSecret!
  }
  const completeCardPayment = async (data: PaymentFormData): Promise<PaymentFormData> => {
    if (stripe == null || elements == null) {
      // Disable form submission until Stripe.js has loaded.
      throw new Error('Stripe or stripe elements were not fully initialised')
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      throw new Error('error validating card or wallet')
    }

    const clientSecret = await createUpdatePaymentIntent(data)

    const shareCode = sessionStorage.getItem('shareCode') ?? ''

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: `https://${window.location.host}/complete/${shareCode}`,
        payment_method_data: {
          billing_details: {
            address: {
              postal_code: data.postcode,
              country: data.country
            }
          }
        }
      }
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment.
      throw new Error('error while processing payment')
    }
    return data
  }

  const completePhonePayment = async (event: PaymentRequestPaymentMethodEvent): Promise<void> => {
    if (stripe === null) {
      return
    }

    const fullName = getFullName(event)
    const shippingAddress = event.shippingAddress!
    const email = event.payerEmail!

    const data: PaymentFormData = {
      ...formData,
      ...fullName,
      email,
      firstAddress: `${shippingAddress.addressLine?.join(' ') ?? ''} ${shippingAddress.city ?? ''} ${shippingAddress.region ?? ''}`,
      postcode: shippingAddress.postalCode!,
      country: shippingAddress.country!.toLowerCase() as Country
    }

    const clientSecret = await createUpdatePaymentIntent(data)

    const result = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: event.paymentMethod.id },
      { handleActions: false })

    // For Google/Apple pay there is no 3DS step. If setup intent requires action then we fail and return
    if (result.error !== undefined || !result.paymentIntent || result.paymentIntent.status === 'requires_action') {
      event.complete('fail')
      return
    }

    event.complete('success')
    onSuccess(formData)
  }

  const { paymentRequest, paymentCapabilities } = usePaymentRequest(() => {}, completePhonePayment)
  const {
    mutate: submit,
    isLoading,
    error
  } = useMutation(completeCardPayment, {
    onSuccess
  })

  return { paymentRequest, completeCardPayment, isLoading, error, submit, paymentCapabilities }
}
