import animal from '../../assets/causes-icons/animal.svg'
import school_children from '../../assets/causes-icons/school-education.svg'
import disaster_relief from '../../assets/causes-icons/disaster-relief.svg'
import feed_the_needy from '../../assets/causes-icons/feed-the-needy.svg'
import medical_healthcare from '../../assets/causes-icons/medical-healthcare.svg'
import refugees_in_need from '../../assets/causes-icons/refugees-in-need.svg'
import sadaqah from '../../assets/causes-icons/sadaqah.svg'
import social_justice from '../../assets/causes-icons/social-justice.svg'
import water from '../../assets/causes-icons/water.svg'
import zakat from '../../assets/causes-icons/zakat.svg'
import { GivematchCauseCategory } from '@lib/services'

export const causeImages: Record<string, string> = {
  [GivematchCauseCategory.children]: school_children,
  [GivematchCauseCategory.disaster]: disaster_relief,
  [GivematchCauseCategory.education]: school_children,
  [GivematchCauseCategory.emergency]: sadaqah,
  [GivematchCauseCategory.emotional]: sadaqah,
  [GivematchCauseCategory.food]: feed_the_needy,
  [GivematchCauseCategory.health]: medical_healthcare,
  [GivematchCauseCategory.human]: sadaqah,
  [GivematchCauseCategory.infrastructure]: sadaqah,
  [GivematchCauseCategory.religion]: sadaqah,
  [GivematchCauseCategory.outreach]: sadaqah,
  [GivematchCauseCategory.research]: sadaqah,
  [GivematchCauseCategory.sadaqah]: sadaqah,
  [GivematchCauseCategory.scholarship]: school_children,
  [GivematchCauseCategory.shelter]: refugees_in_need,
  [GivematchCauseCategory.water]: water,
  [GivematchCauseCategory.animals]: animal,
  [GivematchCauseCategory.socialjustice]: social_justice,
  [GivematchCauseCategory.youth]: sadaqah,
  [GivematchCauseCategory.zakat]: zakat,
  [GivematchCauseCategory.other]: sadaqah
}
