import { type FC } from 'react'
import { Page } from '@lib/components'
import Header from '~/components/Header'
import Footer from '~/components/Footer'

export const GoodPractice: FC = () => {
  return <>
        <Header />
        <Page skinny className="px-10 my-16 font-medium terms-and-conditions">
            <section>
                <h1 className="my-8 text-4xl font-semibold text-mtn-blue-800">Good Practice Guidance for Fundraisers on Givematch</h1>
                <p className="mt-4">Thank you for choosing to support charities and causes through Givematch. We appreciate your commitment to making a positive impact. To ensure that your fundraising efforts are not only successful but also conducted ethically and responsibly, we encourage all fundraisers to follow these best practices, which include specific considerations when planning your appeal to prospective donors:</p>
            </section>

            <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

            <ol className="list-decimal list-inside">
                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Choose Your Charity or Cause Wisely:</h2>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            Select a registered charity or a well-defined cause that resonates with your values and mission.
                        </li>
                        <li className="mt-8 ml-8">
                            Verify the charity's authenticity and legitimacy by checking their registration with the Charity Commission for England and Wales, the Charity Commission for Northern Ireland, or the Scottish Charity Regulator, if applicable.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Identifying the Beneficiary:</h2>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            If you are raising money for a cause where no charitable institution is identified as the beneficiary, you must consider the following: The possibility that your personal crowdfunding appeal may itself need to be registered as a charity with the relevant regulatory body.
                        </li>
                        <li className="mt-8 ml-8">
                            If you are a charity, ensure that your appeal meets the legal requirements for public benefit.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Key Fundraising Page Requirements:</h2>
                    <p className="mt-4 text-lg">When publicizing your appeal to prospective donors through your fundraising page on our site, ensure that you include the following details:</p>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            Who is organizing the appeal.
                        </li>
                        <li className="mt-8 ml-8">
                            Clarify whether the money raised is for a specific purpose or for the recipient to use as they see fit.
                        </li>
                        <li className="mt-8 ml-8">
                            If raising funds for a charitable institution for a specific purpose, contact the charitable institution to ensure they are aware and willing to receive the funds for this purpose.
                        </li>
                        <li className="mt-8 ml-8">
                            Set a clear target for your appeal, whether it's a time target or a financial target. If you're raising money on behalf of or for a charitable institution, specify the name of the charitable institution.
                        </li>
                        <li className="mt-8 ml-8">
                            Explain how people can donate, including alternative donation methods and ways to maximize donations through Gift Aid.
                        </li>
                        <li className="mt-8 ml-8">
                            Clearly outline any deductions that will be made for expenses.
                        </li>
                        <li className="mt-8 ml-8">
                            Describe your plan if you don't raise enough to meet your target, if you raise more than your target, or if the original purpose for which you are seeking donations is no longer valid for any reason.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Confirmation of Understanding:</h2>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            Before your donation page is published, you must confirm that you have read and understood this guidance by taking a clear and positive action, such as ticking unticked boxes.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Preventing Fraud and Money Laundering:</h2>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            We take reasonable measures to avoid fraudulent activity and money laundering on our site. If the money raised is not going directly to a charitable institution's bank account, we will make it clear, before a donation is made, that donors give at their own risk.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Compliance with the Code:</h2>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            You must adhere to the sections of the code that apply to your fundraising as a condition of using our site. We reserve the right to end or suspend your use of the site if necessary.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <p className="mt-4">By following these guidelines and considering the specific requirements for your fundraising, you will create an effective and transparent fundraising page, building trust among your supporters and donors. Your dedication to responsible fundraising is greatly appreciated and will help make a positive difference in the lives of those in need.
                    Should you have any questions or require further assistance, please don't hesitate to contact us on <a className="underline text-mtn-blue"
                href="mailto:contact@givematch.com">contact@givematch.com</a>
                </p>
                <p className="mt-4">Please also refer to the <a className="underline text-mtn-blue"
                href="https://www.fundraisingregulator.org.uk/more-from-us/resources/setting-online-fundraising-appeal-advice-public">guidance</a> provided by the Fundraising Regulator for further information to support your fundraising efforts.
                </p>
                <p className="mt-4">Thank you for your commitment to charitable fundraising.
                </p>
            </ol>
        </Page>
        <Footer />
    </>
}
