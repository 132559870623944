import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction } from 'react'
import { Modal } from '@lib/components'

import arrowRight from '~/assets/arrow-right.svg'
import { type CharityList } from '~/state/charity'
import { defaultCharitySpelling } from '~/utils/charityAPI'

interface ModalBtnProps {
  primary: boolean
  hasBottomMargin?: boolean
  onClick: () => void | Promise<void>
  children: React.ReactNode
}

export const ModalBtn: FC<PropsWithChildren<ModalBtnProps>> = ({
  primary,
  hasBottomMargin = false,
  onClick,
  children
}) => {
  return primary ? (
    <div
      className={`${hasBottomMargin && 'mb-8 '} bg-gm-ebony px-7 py-3.5 gap-x-2 text-white rounded-3xl cursor-pointer flex items-center`}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={onClick}
    >
      <p>{children}</p>

      {/* Right arrow */}
      <div className="text-white right-auto">
        <img alt="open" src={arrowRight} className=" w-5"/>
      </div>
    </div>
  ) : (
    <div
      className="cursor-pointer font-semibold"
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={onClick}
    >
      {children}
    </div>
  )
}

interface ModalNonApprovedAPICharityProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  charityName: string
  defaultCharity?: CharityList
  cta1: React.ReactNode
  cta2?: React.ReactNode
}

export const ModalNonApprovedAPICharity: FC<ModalNonApprovedAPICharityProps> = ({
  showModal,
  setShowModal,
  charityName,
  defaultCharity,
  cta1,
  cta2
}) => {
  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <div className="p-6 md:p-16 flex flex-col justify-center items-center text-center text-gm-black">
        {/* Text content */}
        <p className="mb-6 md:mb-10">
          Sorry, we are currently unable to accept donations for
          <strong className="capitalize"> {charityName.toLowerCase()}</strong>,
          but we will be working with them to support future donations.

          {defaultCharity && (
            <>
              <br/><br/>
              Why not donate to the
              <strong>"{defaultCharitySpelling(defaultCharity.name)}"</strong>
              option to support a wide range of causes?
            </>
          )}
        </p>

        {cta1}
        {cta2 !== undefined && cta2}
      </div>
    </Modal>
  )
}
