import { Button, InputWithRef, Variant } from '@lib/components'
import { GivematchFundraiserTestIds } from '@lib/testing'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { type FC, useState } from 'react'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { FundraiserLayout } from '~/components/FundraiserLayout'
import { Tracking } from '~/service/tracking'
import { Editor } from '~/components/FundRaiser/Editor'
import { getNextStep } from '~/utils/fundraiserSteps'

export const EnterTitle: FC = () => {
  const { fundraiser, setFundraiser } = useFundraiserPlan()
  const defaultStory = `Want to join me in making a difference? I'm raising money to benefit ${fundraiser.charity.name}, and any donation will help make an impact. Thanks in advance for your contribution to this cause that means so much to me.`
  const defaultTitle = `Help me in raising funds for ${fundraiser.charity.name}`
  const [title, setTitle] = useState(fundraiser.title ?? defaultTitle)
  const [story, setStory] = useState(fundraiser.story ?? defaultStory)

  const saveProgress = (): void => {
    setFundraiser({ ...fundraiser, title, story })
  }
  const proceedToNextPage = (): void => {
    saveProgress()
    Tracking.addFundraiserDetails(fundraiser, title, story)
    navigate(getNextStep(Navigation.fundraiserEnterTitle()))
  }
  const onPreviewClick = (): void => {
    saveProgress()
    Tracking.fundraiserPreview(fundraiser, title, story)
    navigate(Navigation.fundraiserPreview())
  }

  const navigate = useNavigate()
  return <FundraiserLayout title="Tell donors why you're fundraising">
    <span className="text-gm-green-500 font-semibold mt-5 mb-5 text-xl">Give your fundraiser a title</span>
    <div className="relative">
      <InputWithRef
        id="title"
        data-test-id={GivematchFundraiserTestIds.titleText}
        type="text"
        variant={Variant.gm}
        size={1}
        defaultValue={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder="Donate to help..."
        maxLength={50}
        className='pr-5 w-full' />
      <span className='absolute top-4 right-2 font-light text-sm'>{50 - title.length}</span>
    </div>

    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Some ideas to help you start writing:</span>
    <ul className="list-disc pl-5 py-4 list-outside space-y-4">
      <li>
        <span>Introduce yourself and what you're raising funds for.</span>
      </li>
      <li>
        <span>Describe why it's important to you.</span>
      </li>
      <li>
        <span>Explain how the funds will be used.</span>
      </li>
    </ul>

    <span className="text-gm-green-500 font-semibold mt-5 text-xl">Tell your story:</span>
    <p className="my-5">Introduce yourself and what you're raising funds for. Describe why it's important to you. Explain how the funds will be used.</p>
    <div data-color-mode="light mx-4 h-64">
      <Editor story={story} setStory={setStory} />
      <p className="text-xs">Tell donors why support is needed and how funds will be used.</p>
    </div>

    <Button
      data-test-id={GivematchFundraiserTestIds.continueButton}
      className="w-full mt-5"
      variant="gm-primary"
      disabled={title === '' || story === ''}
      onClick={() => proceedToNextPage()}>
      <span>Continue</span>
    </Button>

    <Button variant="gm-secondary-md" className="w-full py-2 my-4 px-3 font-semibold text-xl" onClick={() => onPreviewClick()} >
      Preview Fundraiser
    </Button>
  </FundraiserLayout>
}
