import { type ReactNode, type FC } from 'react'
import { Link } from 'react-router-dom'
import { CircleChecked, Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const BoldItalics: FC<{ children: ReactNode }> = ({ children }) => {
  return <span className='font-bold italic'>{children}</span>
}

const SectionTitle: FC<{ children: ReactNode, sub?: boolean }> = ({ sub, children }) => {
  const className = 'font-bold text-gm-green'
  return sub ? <h3 className={className + ' text-lg'}>{children}</h3> : <h2 className={className + ' text-2xl'}>{children}</h2>
}

const SectionParagraph: FC<{ children: ReactNode }> = ({ children }) => <p>{children}</p>

const Section: FC<{ children: ReactNode }> = ({ children }) => {
  return <section className="flex flex-col gap-4">{children}</section>
}

const SectionList: FC<{ list: ReactNode[] }> = ({ list }) => {
  return <ul className="flex flex-col gap-3">{list.map((item) => {
    return <li className="flex items-start"><CircleChecked className="mt-1 mr-4 text-gm-green" /><span className="flex-1">{item}</span></li>
  })}</ul>
}

const PrivacyPolicy: FC = () => {
  return <>
    <Header />

    <Page skinny className="px-4 my-8">
      <h1 className="text-4xl font-semibold tracking-tighter text-center text-gm-green">Privacy &amp; Policy</h1>
      <div className='flex flex-col gap-7 mt-4'>
        <Section>
          <SectionTitle>About this notice</SectionTitle>
          <SectionParagraph>
            <BoldItalics>GiveMatch</BoldItalics> is the trading name of Givetree Limited (<BoldItalics>we</BoldItalics>
            , <BoldItalics>us</BoldItalics> or <BoldItalics>GiveTree</BoldItalics>). We take the privacy of your
            information very seriously. This Privacy Notice (<BoldItalics>Notice</BoldItalics>) is designed to tell you
            about our practices regarding the collection, use and disclosure of personal information which may be
            collected in person from you, obtained via our websites or apps, or collected through other means such as
            by an online form, email, or telephone communication.
          </SectionParagraph>
          <SectionParagraph>
            This notice applies to the charitable donation and related services we provide (the{' '}
            <BoldItalics>Service</BoldItalics>) and relates to any personal information provided by our users,
            customers and contacts (including donors and other users or guests on our sites or apps) whose data we
            process.
          </SectionParagraph>
          <SectionParagraph>
            In this notice <BoldItalics>you</BoldItalics> refers to any individual whose personal data we hold or
            process. This notice is governed by the EU General Data Protection Regulation and UK GDPR (collectively
            referred to as the <BoldItalics>GDPR</BoldItalics>).
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>
            Personal data we collect and how we process this data, including when contacting you
          </SectionTitle>
          <SectionParagraph>
            When you make a donation on GiveMatch, you will be asked to provide personal information. Below we have
            set out the categories of data we collect, the legal basis we rely on to process the data and how we
            process the data, including when contacting you:
          </SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                Contact information for donors who donate through the Service, as well as any individuals who sign up to
                our mailing list (or ask or agree to receive information relating to our products and services) and our
                users generally who may contact us. This information may include names, email addresses, phone numbers
                , postal addresses, and product or service preferences (<BoldItalics>Contact Information</BoldItalics>).
                We process this information when we communicate with you or provide services to you on the basis of the
                performance of our contract with you, or if we have no ongoing contract with you on the basis of our
                legitimate interest in providing and operating the Service.
              </SectionParagraph>,

              <SectionParagraph>
                Information relating to any donations made through the Service (<BoldItalics>Donation Information</BoldItalics>
                ) which may include value of donations, dates and times of donations, the charities or organisations
                in receipt of donations and records of total donations. We process this information when we process
                and manage donations on the basis of the performance of our contract with you, or if we have no
                ongoing contract with you on the basis of our legitimate interest in providing and operating the Service.
              </SectionParagraph>,

              <SectionParagraph>
                Information provided in relation to comments or reviews posted to the Service (
                <BoldItalics>Comment Information</BoldItalics>). We process this information on the basis of our
                legitimate interest in providing the Service and displaying such comments or reviews.
              </SectionParagraph>,

              <SectionParagraph>
                Information provided relating to payment for your donation such as credit or debit card information
                (<BoldItalics>Payment Information</BoldItalics>). Please note that Payment Information may be supplied
                directly to a third party payment processor who will process the data in accordance with their own
                privacy notices or policies (see below). We process this information when we manage donations or
                collect or process payments on the basis of the performance of our contract with you in providing the
                Service. GiveMatch does not store or have access to your credit or debit card details or passwords.
                This information is held by our third party payment processors.
              </SectionParagraph>,

              <SectionParagraph>
                A record of any correspondence or communication between you and us (
                <BoldItalics>Communication Information</BoldItalics>). We process this information when we monitor
                our relationship with you and provide services to you on the basis of the performance of our contract
                with you, or if we have no ongoing contract with you on the basis of our legitimate interest in providing
                the Service and communicating with our users. When you use our Service you have the opportunity to opt in
                to email updates from us. If you select “yes” and opt in to receiving email updates from GiveMatch, we
                will send you updates on GiveMatch and our work from time to time. You can unsubscribe from these emails
                at any time by clicking the “unsubscribe” link at the bottom of our emails.
              </SectionParagraph>,

              <SectionParagraph>
                Marketing information we may hold about you in order to provide information about the Service which may
                include names, email addresses, phone numbers, addresses, and other information regarding your preferences
                and otherwise (<BoldItalics>Marketing Information</BoldItalics>). We process this information in order to
                communicate with you about our products and services, including related offers. We collect this information
                on the basis of our legitimate interests in communicating with you about our services or on the basis that
                you have consented to receive such information by using the Service.
              </SectionParagraph>,

              <SectionParagraph>
                Information you have shared to manage your entry into any prize draws. This may include your name, address,
                email address and telephone number (<BoldItalics>Prize Draw Information</BoldItalics>). We will use Prize
                Draw information to notify you if you are the winner. We will only retain your data for the period of the
                prize draw. We will not add your data to any marketing lists unless you explicitly consent to this. We
                will only share Prize Draw Information with third parties where those third parties are named at the
                point of data capture and you explicitly consent to the sharing of Prize Draw Information, or where we
                have appointed a third party to manage the delivery of your prize.
              </SectionParagraph>,

              <SectionParagraph>
                We may use the information you provide when using the Service to invite you to participate in surveys
                about our Service (<BoldItalics>Survey Information</BoldItalics>). Providing Survey Information and
                participating in surveys is always voluntary and the answers you provide are aggregated and anonymous
                to help us improve our Service. We will not add Survey Information to any marketing lists unless you
                explicitly consent to this. We will only share Survey Information with third parties where we have
                instructed them to run surveys on our behalf.
              </SectionParagraph>,

              <SectionParagraph>
                We will collect the above information from you directly but there may be occasions when we collect
                information from a third party. If we do obtain your personal data from a third party your privacy
                rights under this notice are not affected and you are still able to exercise the rights contained
                within this notice.
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            This Privacy Notice also applies to personal data provided by our partner charities (which may include
            the organisation, firm or entity for whom you work) and their staff, and any third party suppliers whose
            data we process. When we process personal data of this nature we do so on the basis of the performance of
            our contract with your employer or engaging organisation, or if we have no such ongoing contract on the
            basis of our legitimate interest in providing and operating the Service.
          </SectionParagraph>

          <SectionParagraph>
            You have the right to ask at any time that we stop or do not begin to process your information for any
            of the purposes listed above. This is known as your “right to withdraw consent” under GDPR.
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Data retention</SectionTitle>
          <SectionParagraph>
            Our current data retention policy is to delete or destroy (to the extent we are able to) personal data
            after the following periods:
          </SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                <b>Records relating to a contract with us</b> - 7 years from either the end of the contract or the
                date you last used the Service, being the length of time following a breach of contract in which a
                contract party is entitled to make a legal claim.
              </SectionParagraph>,

              <SectionParagraph>
                <b>Marketing records</b> - 3 years from the last date on which you have interacted with us.
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            For any category of personal data not specifically defined in this notice, and unless otherwise specified
            by applicable law, the required retention period for any personal data will be deemed to be 7 years from
            the date of receipt by us of that data. The retention periods stated in this notice can be prolonged or
            shortened as may be required (for example, in the event that legal proceedings apply to the data or if
            there is an on-going investigation into the data).
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Cookies Policy</SectionTitle>
          <SectionParagraph>
            Like most digital services, we use cookies to help provide you with the best experience whilst using our
            service. The cookies we use are split between the following categories:
          </SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                <b>Strictly Necessary cookies</b> - which are an essential part of our service and affect the way you
                can use our site (e.g. security & authentication).
              </SectionParagraph>,

              <SectionParagraph>
                <b>Performance cookies</b> - which are used for analytics (e.g. understanding usage on our website).
              </SectionParagraph>,

              <SectionParagraph>
                <b>Functionality cookies</b> - which collect information about your device to help you customize our
                service (e.g. remembering your timezone settings or accessing inline help).
              </SectionParagraph>,

              <SectionParagraph>
                <b>Targeting cookies</b> - these cookies record your visit to our website, the pages you have visited
                and the links you have followed. We will use this information to make our website and the advertising
                displayed on it more relevant to your interests. We may also share this information with third parties
                for this purpose.
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            On your first visit to our site from your browser we will display a notice to notify you that we are using
            cookies. We will only load the Strictly Necessary cookies and Functionality cookies until you have clicked
            the “Accept” button on our cookies notice. If you click the “Accept” button our Performance cookies and
            Targeting cookies will be loaded.
          </SectionParagraph>

          <SectionParagraph>Below is a list of the cookies currently set by us:</SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                <b>Authentication cookies</b> - <i>Strictly Necessary</i> - Used for your security and convenience
              </SectionParagraph>
            ]}
          />

          <Section>
            <SectionTitle sub>Third-party cookies</SectionTitle>

            <SectionParagraph>
              As well as cookies that are set by domains we control (first-party cookies), you may also see cookies set
              by a third party (third-party cookies). These are set when you interact with certain parts of our service
              and are used by these third-party services to understand your preferences and sometimes tailor content
              they show you.
            </SectionParagraph>

            <SectionParagraph>
              These third party cookies are likely to be analytical cookies or performance cookies or targeting cookies:
            </SectionParagraph>

            <SectionList
              list={[
                'Facebook',
                'Google',
                'Stripe'
              ]}
            />
            <SectionParagraph>
              To deactivate the use of third party advertising cookies, please choose your preferences by clicking{' '}
              <Link to="/cookie-preferences" className="underline text-gm-green">here.</Link>
            </SectionParagraph>
          </Section>

          <Section>
            <SectionTitle sub>Refusing cookies</SectionTitle>

            <SectionParagraph>You can opt out of cookies on our site at any time by contacting us directly.</SectionParagraph>
            <SectionParagraph>
              If you would like to learn more about interest-based advertising and see what choices you have about how
              companies use your information please go to{' '}
              <a className="underline text-gm-green" href="https://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">youronlinechoices.com</a>
              {' '}or <a className="underline text-gm-green" href="https://aboutads.info" target="_blank" rel="noopener noreferrer">aboutads.info</a>.
            </SectionParagraph>

            <SectionParagraph>
              The majority of browsers are set up to accept cookies by default but you can change the way your
              browser handles cookies if you wish. You could reject them by default or be notified when a website is
              trying to set or update cookie preferences. Exactly how you disable cookies depends on the browser or
              device you are using. The help feature on most browsers will tell you how you can manage and or disable
              cookies. If you disable cookies on your browser, certain features or parts of the Service may not
              function correctly or will have a degraded experience.
            </SectionParagraph>
          </Section>

          <Section>
            <SectionTitle>Targeting and advertising</SectionTitle>
            <SectionParagraph>
              We use Facebook Pixel to deliver targeted advertisements to individuals who visit our website. Using
              cookies, we track your activity on our site and share it with Facebook to analyse our website's
              conversion flows and optimise our ad campaigns. The collected data is anonymous meaning we can't see
              your personal data, but it is processed and stored by Facebook. Facebook is able to connect the data
              with your Facebook account and use the data for their own advertising purposes. You can view Facebook's
              privacy policy{' '}
              <a
                className="underline text-gm-green"
                href="https://www.facebook.com/about/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              {' '}and manage your Facebook ad preferences{' '}
              <a
                className="underline text-gm-green"
                href="https://www.facebook.com/ads/website_custom_audiences/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>.
            </SectionParagraph>

            <SectionParagraph>
              We also use the Remarketing feature from Google Ads to reach out to people who have previously visited
              our site. We and Google use cookies to inform, optimise and serve ads based on your past use of our
              service. These cookies allow Google to identify what you find popular and offer a more personalised
              selection of advertisements. You can opt out of targeted Google ads by adjusting your settings
              <a
                className="underline text-gm-green"
                href="https://www.google.com/settings/ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>.
            </SectionParagraph>
          </Section>

          <Section>
            <SectionTitle sub>Tracking and analytics cookies</SectionTitle>

            <SectionParagraph>
              We use analytic tools, such as Google analytics, to understand how our site is used and where we can
              make improvements to provide you with the best experience. You can opt out of this analytics tracking
              by not clicking the “Accept” button or by using Google&#39;s opt out tool.
            </SectionParagraph>
            <SectionParagraph>
              If you choose not to accept the cookies, this will not affect your access to the majority of information
              available on the Service. However, you will not be able to make full use of our online services.
            </SectionParagraph>
          </Section>
        </Section>

        <Section>
          <SectionTitle>Duration</SectionTitle>
          <SectionParagraph>Except for essential cookies, all cookies will expire after 2 years.</SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>IP Addresses</SectionTitle>

          <SectionParagraph>
            An Internet Protocol (IP) address is a number assigned to your computer by your Internet Service
            Provider (ISP), so you can access the Internet. We may use your IP address to diagnose problems with
            our server, report aggregate information, and determine the fastest route for your computer to use in
            connecting to our service, and to administer and improve our service.
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Sharing your information</SectionTitle>
          <SectionParagraph>
            We may disclose information to third parties in the following circumstances:
          </SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                In operating the Service we may provide information to our third party service providers, including
                payment processors and suppliers who assist with email distribution, IT services and customer services.
              </SectionParagraph>,

              <SectionParagraph>
                Your information may be shared with the charities or organisations to whom you have donated or
                expressed an interest in through the Service. Subject to satisfactory due diligence, we may agree
                that charity partners are controllers of your data in common with us, as opposed to processors
                subject to a separate data sharing agreement.
              </SectionParagraph>,

              <SectionParagraph>Your name will not be provided unless you provide appropriate permissions.</SectionParagraph>,

              <SectionParagraph>
                When you make a donation with GiveMatch, you have the option to sign up to receive further
                communications from the charity to which you are donating. Selecting “yes” means the charity will
                be able to contact you to thank you, provide more details about how your donation will support
                their cause and update you on their news. Selecting “no” means that the charity will not be able
                to contact you in the future. Please do check the charity&#39;s own privacy policy before
                selecting “yes”. If you do select “yes”, you will need to contact that charity directly if you
                wish to raise any queries or concerns regarding your personal data or to exercise your privacy
                rights in relation to the data they hold and process about you, including if you wish to revoke
                your consent to their contacting you and processing your data.
              </SectionParagraph>,

              <SectionParagraph>
                We may target adverts to donors and measure how they perform, both through our own services and on
                our own websites and apps, and through other platforms such as social media sites and other
                websites, and we may share your personal data with third parties for this purpose. We may also
                share your personal data with third parties, such as social media platforms, to allow that third
                party to understand the type of person who uses our services and create lookalike audiences for
                advertising purposes.
              </SectionParagraph>,

              <SectionParagraph>
                We may disclose information to our group companies for internal operational reasons.
              </SectionParagraph>,

              <SectionParagraph>
                If we are under a duty to disclose or share your personal data in order to comply with any legal
                obligation (for example, if required to do so by a court order or for the purposes of prevention of fraud or other crime).
              </SectionParagraph>,

              <SectionParagraph>
                If we are under a duty to disclose or share your personal data in order to comply with any legal
                obligation (for example, if required to do so by a court order or for the purposes of prevention
                of fraud or other crime).
              </SectionParagraph>,

              <SectionParagraph>
                In order to enforce any terms and conditions or agreements for our services that may apply.
              </SectionParagraph>,

              <SectionParagraph>
                We may transfer your personal information to a third party as part of a sale of some or all of our
                business and assets to any third party or as part of any business restructuring or reorganisation,
                or during the course of third party investment into our business, but we will take steps with the
                aim of ensuring that your privacy rights continue to be protected.
              </SectionParagraph>,

              <SectionParagraph>
                To protect our rights, property and safety, or the rights, property and safety of our users or any
                other third parties. This includes exchanging information with other companies and organisations
                for the purposes of fraud protection and credit risk reduction.
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            If we do supply your personal information to a third party we will take steps to ensure that your
            privacy rights are protected and that third party complies with the terms of this notice.
          </SectionParagraph>

          <SectionParagraph>
            As a default we rely on our legitimate interests as the legal basis for sharing personal data in these
            ways but this is always subject to the terms of your consents or your expressions of interest or
            preference (if any).
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Security</SectionTitle>
          <SectionParagraph>
            We will take all reasonable steps to ensure that appropriate technical and organisational measures are
            carried out in order to safeguard the information we collect from you and protect against unlawful access
            and accidental loss or damage.
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Your privacy rights</SectionTitle>
          <SectionParagraph>With respect to your personal data, you have the right to:</SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>request that your personal data will not be processed.</SectionParagraph>,
              <SectionParagraph>ask for a copy of any personal data that we have about you.</SectionParagraph>,
              <SectionParagraph>
                request a correction of any errors in or update of the personal data that we have about you.
              </SectionParagraph>,
              <SectionParagraph>
                request that your personal data will not be used to contact you for direct marketing purposes.
              </SectionParagraph>,
              <SectionParagraph>
                request that your personal data will not be used for profiling purposes.
              </SectionParagraph>,
              <SectionParagraph>
                request that your personal data will not be used to contact you at all.
              </SectionParagraph>,
              <SectionParagraph>
                request that your personal data be transferred or exported to another organisation, or deleted
                from our records.
              </SectionParagraph>,
              <SectionParagraph>
                at any time, withdraw any permission you have given us to process your personal data.
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            All requests or notifications in respect of your above rights may be sent to us in writing at the contact
            details listed below. We will endeavour to comply with such requests as soon as possible but in any event
            we will comply within one month of receipt (unless a longer period of time to respond is reasonable by
            virtue of the complexity or number of your requests).
          </SectionParagraph>

          <SectionParagraph>
            If personal data we hold about you is subject to a breach or unauthorised disclosure or access, we will
            report this to the Information Commissioner&#39;s Office (<b>ICO</b>) and/or our data protection manager.
          </SectionParagraph>

          <SectionParagraph>
            If a breach is likely to result in a risk to your data rights and freedoms, we will notify you as soon as
            possible.
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Transferring your information outside Europe</SectionTitle>
          <SectionParagraph>
            We will not transfer your personal data in a systematic way outside of the European Economic Area or UK
            (<b>EEA</b>) but there may be circumstances in which certain personal information is transferred outside of
            the EEA, in particular:
          </SectionParagraph>

          <SectionList
            list={[
              <SectionParagraph>
                If you are based outside of the EEA or use our services while you are outside the EEA, your information
                may be transferred outside the EEA in order to provide you with our services.
              </SectionParagraph>,

              <SectionParagraph>
                We may communicate with individuals or organisations outside of the EEA in providing our services and
                those communications may include personal information (such as contact information), for example you
                may be outside of the EEA when we communicate with you.
              </SectionParagraph>,

              <SectionParagraph>
                From time to time your information may be stored in devices which are used by our staff outside of the
                EEA (but staff will be subject to our cyber-security policies).
              </SectionParagraph>
            ]}
          />

          <SectionParagraph>
            If we transfer your information outside of the EEA, and the third country or international organisation in
            question has not been deemed by the EU Commission to have adequate data protection laws, we will provide
            appropriate safeguards and we will be responsible for ensuring your privacy rights continue to be protected
            as outlined in this notice.
          </SectionParagraph>
        </Section>

        <Section>
          <SectionTitle>Contact us</SectionTitle>
          <SectionParagraph>
            If at any time you would like to contact us with your views about our privacy practices, or with any
            enquiry or complaint relating to your personal information or how it is handled, you can do so by us using
            the details below.
          </SectionParagraph>

          <div className="sm:flex">
            <div className="flex flex-col sm:flex-1">
              <span className="mt-4 underline text-gm-green ">Address</span>
              <span>6-8 Great Eastern Street,<br />
                London,<br />
                EC2A 3NT,<br />
                United Kingdom
              </span>
            </div>
            <div className="flex flex-col sm:flex-1">
              <span className="mt-4 underline text-gm-green ">Email</span>
              <a className="hover:underline" href="mailto:contact@givematch.com">contact@givematch.com</a>
            </div>
          </div>
        </Section>

        <Section>
          <SectionTitle>Complaints</SectionTitle>
          <SectionParagraph>
            If we are unable to resolve any issues you may have or you would like to make a further complaint, you can
            contact the Information Commissioner&#39;s Office by visiting{' '}
            <a className="underline text-gm-green" href="https://www.ico.org.uk/">www.ico.org.uk</a> for further
            assistance.
          </SectionParagraph>
        </Section>
      </div>
    </Page>
    <Footer />
  </>
}

export default PrivacyPolicy
