import { type FC } from 'react'
import { Page } from '@lib/components'
import Header from '~/components/Header'
import Footer from '~/components/Footer'

export const ComplaintsPolicy: FC = () => {
  return <>
        <Header />
        <Page skinny className="px-10 my-16 font-medium terms-and-conditions">
            <section>
                <h1 className="my-8 text-4xl font-semibold text-mtn-blue-800">Complaints Policy</h1>
            </section>

            <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

            <ol className="list-decimal list-inside">
                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Introduction</h2>
                    <p className="mt-4 text-base">At Givematch, we are committed to providing an excellent user experience for all our stakeholders, including donors, fundraisers, charitable organizations and corporations. However, we understand that there may be times when individuals have concerns or complaints. We value feedback and take all complaints seriously, as they provide us with an opportunity to improve our services and resolve issues promptly.</p>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Purpose of the Policy</h2>
                    <p className="mt-4 text-base">This policy outlines the procedures for submitting and resolving complaints related to Givematch services, and it is designed to ensure that:</p>
                    <ol className="text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            Complaints procedures are simple, clear, and easily accessible to members of the public and individuals.
                        </li>
                        <li className="mt-8 ml-8">
                            Stakeholders can understand the scope of complaints that we can consider and what they can and cannot expect from our organization.
                        </li>
                        <li className="mt-8 ml-8">
                            Timescales and possible remedies are clearly defined, including the possibility of receiving an apology.
                        </li>
                        <li className="mt-8 ml-8">
                            We aim to deal with issues raised promptly and effectively.
                        </li>
                        <li className="mt-8 ml-8">
                            In cases where complaints cannot be resolved internally, clear signposting to relevant external bodies is provided, such as the Fundraising Regulator or the Scottish Fundraising Adjudication Panel.
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">How to Submit a Complaint</h2>
                    <p className='mt-4 text-base'>If you have a concern or complaint related to our services, please follow these steps:</p>
                    <ol className="list-decimal list-inside text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Contact Us:</h2>
                            <p className='mt-4 text-base'>In the first instance, contact our support team via the "Contact Us" section on our website.</p>
                        </li>
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Provide Details:</h2>
                            <p className='mt-4 text-base'>When submitting a complaint, please provide your contact information, a detailed description of the issue, and any supporting documents or evidence.</p>
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Acknowledgement and Response</h2>
                    <ol className="list-decimal list-inside text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Acknowledgment:</h2>
                            <p className='mt-4 text-base'>We will acknowledge your complaint within 72 hours, confirming that we have received it and providing you with a reference number for tracking purposes.</p>
                        </li>
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Resolution:</h2>
                            <p className='mt-4 text-base'>We aim to resolve complaints as quickly as possible. Depending on the nature of the complaint, we will strive to provide a resolution within 14 days.</p>
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Complaint Outcomes</h2>
                    <ol className="list-decimal list-inside text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Successful Resolution:</h2>
                            <p className='mt-4 text-base'>If your complaint is successfully resolved, we will communicate the resolution to you. This may include an apology, an explanation, or other actions taken to address the issue.</p>
                        </li>
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Unsuccessful Resolution:</h2>
                            <p className='mt-4 text-base'>In cases where we are unable to resolve the complaint to your satisfaction, we will clearly inform you of this and provide reasons for our decision.</p>
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">External Resolution</h2>
                    <ol className="list-decimal list-inside text-base text-gm-gray">
                        <li className="mt-8 ml-8">
                            <h2 className="inline my-4">Fundraising Regulator or Scottish Fundraising Adjudication Panel:</h2>
                            <p className='mt-4 text-base'>If you remain dissatisfied with the outcome of your complaint, you have the option to escalate the matter to the Fundraising Regulator or the Scottish Fundraising Adjudication Panel. We will provide clear guidance and contact information for these external bodies, as required.</p>
                        </li>
                    </ol>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Policy Review</h2>
                    <p className='mt-4 text-base'>This complaints policy is periodically reviewed and updated to ensure its effectiveness. We welcome feedback and suggestions for improvement to better serve our stakeholders.</p>
                </li>

                <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

                <li className="text-2xl text-mtn-blue-800">
                    <h2 className="inline my-4">Contact Information</h2>
                    <p className='mt-4 text-base'>If you have any questions or wish to submit a complaint, please contact us via our Contact Us page.</p>
                </li>
            </ol>
        </Page>
        <Footer />
    </>
}
