export const unsubscribe = async (shareCode: string): Promise<string> => {
  const res = await fetch(`/api/donor/unsubscribe/${shareCode}`, {
    method: 'POST'
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
}
