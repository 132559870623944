import { type FC } from 'react'
import { Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const CharityPartner: FC = () => {
  return <div className="flex flex-col min-h-screen">
        <Header />

        <Page skinny className="flex-1 px-4 mb-8 text-justify">
            <h1 className="mt-12 text-4xl font-semibold tracking-tighter text-center text-gm-green">Why your charity should fundraise on Givematch</h1>
            <p className="mt-8">
                Charities should be interested in crowdfunding on Givematch because it offers a unique opportunity to increase their fundraising ROI through a network effect. Givematch's platform incentivizes donors to not only make a donation themselves, but also to share and convince their friends to donate as well. This creates a ripple effect that can result in a much larger pool of donations than what would have been possible through traditional crowdfunding platforms. By participating in crowdfunding on Givematch, charities not only amplify the impact of their fundraising efforts, but also engage their supporters and create a sense of community around the cause. Moreover, the word of mouth viral engine can attract new supporters and donors, which expands the donor base and further increases the impact of the charity.
            </p>
            <p className='mt-4'>
                In addition, Givematch is uniquely powerful for charities because it connects and exposes them to a network of new match funders that may be interested in supporting their work in the future. Match funders are donors who pledge to match donations made to a specific charity up to a certain amount. By participating in crowdfunding on Givematch, charities build a profile and a track record that is viewable to all registered match funding institutions. This exposure can often attract new match funders who are passionate about their cause, which not only provides additional revenue but also raises the visibility, credibility and profile of the charity. This exposure to new match funders can help charities expand their network, reach new audiences, and build long-lasting partnerships, which can be crucial for their future success.
            </p>
            <p className='mt-8 font-semibold italic'> If your charity is interested in learning more about how your charity could benefit from Givematch’s services, please email us at <a className="underline text-gm-green" href="mailto:charity@givematch.com">charity@givematch.com</a>.  </p>
        </Page>
        <Footer />
    </div>
}

export default CharityPartner
