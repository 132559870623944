import { type FC } from 'react'
import { PaymentFormTestId } from '@lib/testing'
import Slider from 'react-input-slider'
import { type Currency, displayTidyCurrency } from '@lib/services'
import love from '~/assets/love.svg'

interface props {
  onChange: (tip: number) => void
  tipPercentage: number
  platformTip: number
  currency: Currency
}

const PlatformTip: FC<props> = ({ onChange, tipPercentage, platformTip, currency }) => {
  return <div className="flex flex-col justify-around p-4 gap-y-4 rounded-lg bg-gm-green bg-opacity-5 border-gm-green border">
    <div className='flex justify-between'>
      <h1 className="font-bold ">Your tip keeps us going!</h1>
      <img src={love} alt={'love'} className='h-4' />
    </div>
    <p >Thanks to donors who leave an optional amount here, we can continue to offer an amazing service.</p>
    <div className="flex flex-col items-center gap-y-3">
      <h3 className="text-gm-green font-bold">{displayTidyCurrency(platformTip, currency)} <span className="font-extralight">({tipPercentage}%)</span></h3>
      <div className="flex w-full justify-between items-center font-bold text-sm">
        <p data-test-id={PaymentFormTestId.tipFloor}>0%</p>
        <Slider
          data-test-id={PaymentFormTestId.tipSlider} // to be changed JONO
          axis="x"
          x={tipPercentage}
          onChange={({ x }) => onChange(x)}
          xstep={5}
          xmax={30}
          styles={{
            track: {
              backgroundColor: 'lightgray',
              width: '70%'
            },
            active: {
              backgroundColor: '#02A47B'
            },
            thumb: {
              backgroundColor: 'orange',
              width: 25,
              height: 25,
              border: '1px solid white'
            }
          }}
        />
        <p data-test-id={PaymentFormTestId.tipCeiling}>30%</p>
      </div>
    </div>
  </div>
}

export default PlatformTip
