import { Button, Modal } from '@lib/components'
import { type Currency, GetCurrencyByString, displayTidyCurrency } from '@lib/services'
import { type FC } from 'react'
import { matchedDonationAmount } from '~/service/maximumDonationAmounts'
import { type ReusePopupProps } from './Fundraiser'

interface popupProps extends ReusePopupProps {
  modalShow: boolean
  setModalShow: (status: boolean) => void
}

export const GreetPopup: FC<popupProps> = ({ donorFirstName, donorLastName, currency, causeDonation, modalShow, setModalShow }) => {
  const donationAmount = causeDonation ? Object.values(causeDonation).reduce((acc, value) => acc + value, 0) : 0
  const currencyInput = currency ? GetCurrencyByString(currency) : ''
  const amountMatched = currency ? matchedDonationAmount(donationAmount, currency as Currency) : 0
  return <Modal isOpen={modalShow} onClose={() => setModalShow(false)}>
        <div className='p-6 w-72'>
            <div className='text-gm-green-500 text-xl font-bold'>{`${donorFirstName + ' ' + donorLastName} needs your help!`}</div>
            <div className='text-xs mt-3'>{`If you and one other friend`} <b>donate just £1</b> {`using ${donorFirstName}'s link,`} <b>Givematch will double {`${donorFirstName}'s donation`}</b> {`of ${displayTidyCurrency(donationAmount, GetCurrencyByString(currencyInput))} to ${displayTidyCurrency(amountMatched, GetCurrencyByString(currencyInput))}`}</div>
            <div className='text-center'>
                <Button variant='gm-custom-secondary-md'
                    className="mt-4"
                    onClick={() => setModalShow(false)}>
                    Let's go
                </Button>
            </div>
        </div></Modal>
}
