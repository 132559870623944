import { type FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import LeaderboardLanding from '~/pages/leaderboards/LeaderboardLanding'
import { listRootLeaderboards, type RootLeaderboardDetails } from '~/state/leaderboards'
import Header from '~/components/Header'
import Footer from '~/components/Footer'

const ErrorMessage: FC = ({ children }) => (
  <div className="lg:min-h-screen w-full flex flex-col">
    <Header className="bg-gm-gray-50 sm:bg-white"/>
    <div className="pt-20 pb-20 lg:h-96 flex justify-center items-center text-2xl font-inter">
      {children}
    </div>
    <Footer className="text-gm-newgreen"/>
  </div>
)

const RootLeaderboardsLoader: FC = () => {
  const pathParams = useParams() as { slug: string }
  const { slug: providedSlug } = pathParams

  const [rootLeaderboards, setRootLeaderboards] = useState<RootLeaderboardDetails[]>()
  const {
    isLoading,
    isError
  } = useQuery<RootLeaderboardDetails[]>(['rootLeaderboards'], async () => listRootLeaderboards(), {
    onSuccess: (boards: RootLeaderboardDetails[]) => {
      setRootLeaderboards(boards)
    }
  })

  const noLeaderboardsAvailable = rootLeaderboards === undefined || rootLeaderboards.length < 1
  const leaderboardNotFound = rootLeaderboards !== undefined && rootLeaderboards.length > 0 &&
    rootLeaderboards.filter((b) => b.slug === providedSlug).length !== 1
  const board = rootLeaderboards?.filter((b) => b.slug === providedSlug)[0]

  return (
    <>
      {!isLoading && !isError && noLeaderboardsAvailable &&
        <ErrorMessage>No leaderboards available</ErrorMessage>}
      {!isLoading && !isError && leaderboardNotFound &&
        <ErrorMessage>This leaderboard is not available</ErrorMessage>}

      {!isLoading && !isError && !(noLeaderboardsAvailable || leaderboardNotFound) && (
        <LeaderboardLanding leaderboardID={board?.leaderboardId}/>
      )}
    </>
  )
}

export default RootLeaderboardsLoader
