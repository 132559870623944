export enum Account {
  main = '529934240530',
  beta = '200391938238',
  prod = '779089705958'
}

export type AccountName = 'main' | StageName.beta | StageName.prod

export enum StageName {
  beta = 'beta',
  prod = 'prod'
}

export function getStageId (id: string, stage: StageName, separator = '-'): string {
  return `${id}${separator}${stage}`
}
