import { StageName } from './account'

export enum GMSubDomains {
  base = 'www',
  admin = 'admin',
  charity = 'charity',
  support = 'support',
  mailAssets = 'mail-assets',
  stripeWebhook = 'stripe-webhook',
}

interface SubDomainInfo {
  localPort: number
  deployedURL: (stage: StageName) => string
}

export class Domains {
  static readonly beta = 'givematch.link'

  static readonly prod = 'givematch.com'

  static readonly subdomains = GMSubDomains

  static givematchDomain (stage: StageName): string {
    return stage === StageName.prod ? this.prod : this.beta
  }

  static adminDomain (stage: StageName): string {
    return `${this.subdomains.admin}.${this.givematchDomain(stage)}`
  }

  static charityDomain (stage: StageName): string {
    return `${this.subdomains.charity}.${this.givematchDomain(stage)}`
  }

  static customerServiceDomain (stage: StageName): string {
    return `${this.subdomains.support}.${this.givematchDomain(stage)}`
  }

  static mailAssetsDomain (stage: StageName): string {
    return `${this.subdomains.mailAssets}.${this.givematchDomain(stage)}`
  }

  static stripeWebhookDomain (stage: StageName): string {
    return `${this.subdomains.stripeWebhook}.${this.givematchDomain(stage)}`
  }
}
export const subdomainInfo: Record<GMSubDomains, SubDomainInfo> = {
  [GMSubDomains.base]: {
    localPort: 3000,
    deployedURL: Domains.givematchDomain.bind(Domains)
  },
  [GMSubDomains.admin]: {
    localPort: 8110,
    deployedURL: Domains.adminDomain.bind(Domains)
  },
  [GMSubDomains.charity]: {
    localPort: 8100,
    deployedURL: Domains.charityDomain.bind(Domains)
  },
  [GMSubDomains.support]: {
    localPort: 3001,
    deployedURL: Domains.customerServiceDomain.bind(Domains)
  },
  [GMSubDomains.mailAssets]: {
    localPort: 3000,
    deployedURL: Domains.mailAssetsDomain.bind(Domains)
  },
  [GMSubDomains.stripeWebhook]: {
    localPort: 3000,
    deployedURL: Domains.stripeWebhookDomain.bind(Domains)
  }
}

const getEnvironmentFromURL = (): StageName | 'local' | undefined => {
  // Server-side
  if (typeof window === 'undefined') {
    return undefined
  }

  // Client-side
  const locationHost = window.location.host
  return locationHost.includes(Domains.prod)
    ? StageName.prod : locationHost.includes(Domains.beta)
      ? StageName.beta : 'local'
}

export const getFullDomainURL = (sub: GMSubDomains, includeProtocol = false): string => {
  let protocol = ''
  if (includeProtocol) {
    if (window) protocol = window.location.protocol
    else protocol = 'http:'
    protocol += '//'
  }

  const environment = getEnvironmentFromURL()

  if (!environment || environment === 'local') {
    return `${protocol}localhost:${subdomainInfo[sub].localPort}`
  }

  return protocol + subdomainInfo[sub].deployedURL(environment)
}
