import { type FC } from 'react'
import { CircleChecked, Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'
import emailIcon from '~/assets/envelope.svg'
import globeIcon from '~/assets/globe.svg'

const GenerateContactLink: FC<{ url: string, email: string }> = ({ url, email }) => {
  return <div className='my-4'>
        <a className='flex items-center text-gm-green' href={`https://${url}`} target='_blank' rel='noreferrer'>
            <img alt="website" className='w-4 h-4' src={globeIcon} /> <span className='mx-2'> {url}</span>
        </a>
        <a className='flex items-center text-gm-green' href={`mailto:${email}`} target='_blank' rel='noreferrer'>
            <img alt="email" className='w-4 h-4' src={emailIcon} /> <span className='mx-2'> {email}</span>
        </a>
    </div>
}
const MatchFundPartner: FC = () => {
  return <div className="flex flex-col min-h-screen">
        <Header />

        <Page skinny className="flex-1 px-4 mb-8">
            <h1 className="mt-12 text-4xl font-semibold tracking-tighter text-center text-gm-green">Givematch’s Generous Match Fund Partners</h1>
            <p className="mt-8">
                Givematch works with generous corporations, brands, philanthropic foundations, governmental institutions and other tech for good companies to connect you with match funding. This means we are able to make your donations go twice as far! Our partnering organisations contribute generous funding into The Givematch Fund, which is a restricted account under the auspices of a Donor Advised Fund* or DAF in the UK, the US and Canada.
            </p>
            <h3 className="mt-12 text-xl font-semibold tracking-tighter text-left text-gm-green">Our Match Fund partners are:</h3>

            <h3 className="mt-12 text-2xl font-semibold tracking-tighter text-left text-gm-green">Q2Q Capital</h3>

            <p className="mt-4">
                Q2Q Capital is a leading investment firm whose founders have over 25 years of combined experience in hedge funds, private equity and equity capital markets advisory. The firm is known for its deep roots into multiple local governments in the EMEA region.
            </p>
            <p className="mt-4">
                Q2Q Capital is based in Dubai and London with a network of partners active in developed as well as selected emerging markets.
            </p>
            <GenerateContactLink url='www.q2qcapital.com' email='info@q2qcapital.com' />

            <h3 className="mt-12 text-2xl font-semibold tracking-tighter text-left text-gm-green">The Shamaazi Foundation</h3>

            <p className="mt-4">
                The Shamaazi Foundation works to enhance access to technology as a force for good for underserved communities. Other objectives include the promotion of educational projects, increasing the flow of funding to charitable causes through match funding and championing young social entrepreneurs. The Shamaazi Foundation is a restricted fund operating at Mercy Mission UK, registered charitable organisation number 1122922.
            </p>
            <GenerateContactLink url='www.shamaazi.com/shamaazi-foundation' email='contact@shamaazi.com' />

            <h3 className="mt-12 text-2xl font-semibold tracking-tighter text-left text-gm-green">The MyTenNights Fund</h3>

            <p className="mt-4">
                MyTenNights is a tech for good platform that enables donors to automate their donations over a selected period of days. Thousands of donors have used MyTenNights and many of them choose to have MyTenNights spread their donation across a wide range of charities in order to maximise the impact of the donation. MyTenNights spreads their donations across the world’s most trusted charities. Some donors choose for MyTenNights to contribute a portion of their donation to match funding, in order to help donations go even further.
            </p>
            <GenerateContactLink url='www.mytennights.com' email='contact@mytennights.com' />

            <h3 className="mt-12 text-2xl font-semibold tracking-tighter text-left text-gm-green">Our Donor Advised Fund partners are:</h3>
            <ul>
                <li className="flex items-start mt-4">
                    <CircleChecked className="mt-1 mr-4 text-gm-green" />
                    <div className="flex-1">
                        KBF CANADA, registered charitable organization number 76978 4893 RR0001
                    </div>
                </li>
                <li className="flex items-start mt-4">
                    <CircleChecked className="mt-1 mr-4 text-gm-green" />
                    <div className="flex-1">
                        The King Baudouin Foundation, registered charitable organization number 58-2277856
                    </div>
                </li>
                <li className="flex items-start mt-4">
                    <CircleChecked className="mt-1 mr-4 text-gm-green" />
                    <div className="flex-1">
                        Mercy Mission UK, registered charitable organization number 1122922
                    </div>
                </li>
            </ul>

        </Page>
        <Footer />
    </div>
}

export default MatchFundPartner
