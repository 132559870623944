import { Button } from '@lib/components'
import { type FC } from 'react'
import { Navigation } from '~/service/navigation'
import { useNavigate } from 'react-router-dom'
import { generateURL } from '@givematch/common'
interface CharityDetails {
  charityID: string
  landingPageData: any
}
interface CharityProps {
  charityDetails: CharityDetails
}
const CharityProfile: FC<CharityProps> = ({ charityDetails }) => {
  const { charityID, landingPageData } = charityDetails
  const navigate = useNavigate()
  const handleDonateClick = (charityID: string): void => {
    navigate(Navigation.charityHome(charityID))
  }

  return (<section className={`md:px-10 sm:px-10 bg-gray lg:px-40 text-left md:my-10 my-7`}>
        <div className='flex flex-col md:flex md:flex-col lg:flex-row lg:justify-between lg:gap-y-24'>
            <div className=" lg:mt-10 text-center px-12 lg:px-0 lg:text-left">
                <img className='relative sm:h-12 sm:mx-auto sm:object-contain sm:justify-center h-12 mx-auto object-contain justify-center md:justify-end inline-block' src={generateURL(landingPageData.logo_url)} alt="logo" />
                <h1 className="md:text-5xl text-gm-black md:leading-snug text-xl md:max-w-2xl font-bold md:mt-10 my-5 md:mb-0">{landingPageData.description}</h1>
            </div>
            <div className='h-64 md:h-auto md:px-4 md:py-4 relative md:z-0 overflow-hidden md:my-10'>
                <div className='banner-color absolute h-full w-64 top-0 right-0 z-0 hidden md:hidden lg:block'></div>
                <img className='lg:w-80 lg:h-80 w-full object-contain lg:object-fill h-auto mx-auto justify-center inline-block relative lg:z-10' src={generateURL(landingPageData.banner_url)} alt="banner" />
            </div>
        </div>
        <div className="flex justify-center md:justify-center lg:justify-start mt-7 md:mt-0">
            <Button className="flex items-center justify-center md:justify-center rounded-lg w-4/6 md:w-36" variant='gm-share' onClick={() => handleDonateClick(charityID)}>
                    <span className='md:text-3xl text-xl'>
                        Donate
                    </span>
            </Button>
        </div>
    </section>)
}

export default CharityProfile
