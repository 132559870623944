import { useEffect, type FC, useState } from 'react'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

import '~/css/home.css'
import StepsExplainer from '~/components/StepsExplainer'
import CharityDonationImpact from '~/components/CharityDonationImpact'
import CharityCards from '~/components/CharityLandingFundraiser'
import { LandingPageType } from '@givematch/common'
import CharityProfile from '~/components/CharityProfile'
import { useNavigate, useParams } from 'react-router-dom'
import { type LandingPageTO, getCharityLandingPages } from '~/state/charity'
import { SessionStorage } from '~/service/sessionStorage'
import { Navigation } from '~/service/navigation'
import { Spinner } from '@lib/components'

const CharityHomePage: FC = (): JSX.Element => {
  const { charityID, aliasName } = useParams() as { charityID: string, aliasName: string }
  const [landingPageData, setLandingPageData] = useState<LandingPageTO | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      try {
        const charity = await getCharityLandingPages(charityID)
        if (charity.landing_pages) {
          const landingPage = charity.landing_pages.find((landingPage: LandingPageTO) => landingPage.alias === aliasName)
          if (landingPage && landingPage.active) {
            setLandingPageData(landingPage)
          } else {
            navigate(Navigation.charityHome(charityID))
          }
        } else {
          setLandingPageData(null)
          navigate(Navigation.charityHome(charityID))
        }
      } catch (error) {
        setError(true)
        console.log(error, 'api error')
      } finally {
        setLoading(false)
      }
    }
    void fetchData()
  }, [charityID, aliasName, navigate])

  const CharityDetails = {
    charityID,
    landingPageData
  }
  useEffect(() => {
    sessionStorage.setItem(SessionStorage.backLink, Navigation.charityLandingPage(charityID, aliasName))
  })
  return <div className="lg:min-h-screen w-full flex flex-col">
    <Header />
    {loading && <Spinner className="m-auto" />}
    {error && <p className="px-4 mt-4 text-center justify-center text-gm-red"> We were unable to find this charity </p>}
    {landingPageData && !loading && !error ? (
      <>
        <CharityProfile charityDetails={CharityDetails} />
        <CharityCards charityDetails={CharityDetails} />
        <CharityDonationImpact charityID={charityID} />
      </>
    ) : null}
    <StepsExplainer landingPage={LandingPageType.CharityLandingPage} />
    <Footer />
  </div>
}

export default CharityHomePage
