import { type FC } from 'react'

import ReactQuill, { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import 'react-quill/dist/quill.snow.css'
import { uploadImageBase64 } from '@lib/components'
import { generateFundraiserImageUrl } from '~/state/fundraiser'
import { convertFileToBase64 } from '@lib/services'
import { generateURL, isFileSizeInMbValid } from '@givematch/common'

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    [{ align: [] }],
    ['clean']
  ],
  imageUploader: {
    upload: async (file: File) => {
      // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-misused-promises
      return new Promise(async (resolve, reject) => {
        // upload image to s3
        if (!isFileSizeInMbValid(file, 5)) {
          alert('image needs to be less than 5mb')
          reject(new Error('file too large'))
        }
        const response = await generateFundraiserImageUrl()
        const base64Img = await convertFileToBase64(file)
        uploadImageBase64(base64Img, response.uploadUrl, 'fundraiserSubImage')
        // then resolve the publich path
          .then(() => resolve(generateURL(response.path)), e => {
            // reject the error
            reject(e)
          })
      })
    }
  }
}
export const Editor: FC<{ story: string, className?: string, setStory?: (str: string) => void }> = ({ story, className = '', setStory }) => {
  Quill.register('modules/imageUploader', ImageUploader)

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  return <div>
      <ReactQuill readOnly={!setStory} theme="snow" modules={modules} formats={formats} value={story} onChange={setStory} className={className} />
    </div>
}
